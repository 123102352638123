module.exports = {
  hero: {
    title: "WeBoro helps students study, earn and succeed",
    description:
      "Our design projects are fresh and simple and will benefit your business greatly. Learn more about our work!",
    bgImage: "img/app-hero-bg.jpg"
  },
  promo: {
    title: "Why students love WeBoro ? ",
    description:
      "Following reasons of adding WeBoro to your life",
    items: [
      {
        title: "24/7 Tutoring",
        description:
          "Our experts can help you finish your homework on demand",
        icon: "ti-headphone-alt text-white"
      },
      {
        title: "Access to Study Resources",
        description:
          "Learn from your classmates",
        icon: "ti-comments-smiley text-white"
      },
      {
        title: "Earn Passive Income",
        description:
          "Apply to be a tutor or list your class notes for sale on the app.",
        icon: "ti-money text-white"
      }
    ]
  },
  aboutUs: {
    title: {
      bold: "Use your android or ios device",
      normal: ""
    },
    description:
      "Following reasons show advantages of adding AgencyCo to your lead pages, demos and checkouts",
    items: [
      {
        title: "Clean Design",
        description:
          "Increase sales by showing true dynamics of your website",
        icon: "ti-vector text-white"
      },
      {
        title: "Secure Data",
        description:
          "Build your online store’s trust using Social Proof & Urgency",
        icon: "ti-lock text-white"
      },
      {
        title: "Retina Ready",
        description:
          "Realize importance of social proof in customer’s purchase decision",
        icon: "ti-eye text-white"
      }
    ]
  },
  price: {
    title: "Pricing Packages",
    description:
      "Uniquely maintain efficient expertise whereas cross functional deliverables. Continually leverage other's competitive mind share whereas enabled channels. ",
    packages: [
      {
        price: "$4.99/month",
        license: "Average Student",
        isFeatured: false,
        features: [
          "5 downloads per month",
          "15% off 24/7 Homework Helper orders",
          "10% increase in cash offer for study resources",
          "24/7 customer support",
          
        ]
      },
      {
        price: "$9.99/month",
        license: "All Star Student",
        isFeatured: true,
        features: [
          "Unlimited downloads",
          "20% off 24/7 Homework Helper orders",
          "15% increase in cash offer for study resources",
          "24/7 customer support",
          
        ]
      }
    ]
  },
  testimonial: {
    title: "How our Cutomers review us ????",
    description:
      'Rapidiously morph transparent internal or "organic" sources whereas resource sucking e-business. Conveniently innovate compelling internal.',
    comments: [
      {
        name: "John bade",
        company: "Student",
        image: "img/student2.png",
        comment:
          "I am a new user to WeBoro and have seen fantastic results. WeBoro has saved me from failing my statistics class miserably. WeBoros Homework Helper helped me score a 97% on every assignment"
      },
      {
        name: "Ritesh",
        company: "Student",
        image: "img/student1.png",
        comment:
          "WeBoros help is on-demand which is great since professors never answer their emails. The database of notes is phenomenal, nothing quite like it. $4.99 is worth it"
      },
      {
        name: "Ella",
        company: "Student",
        image: "img/student3.png",
        comment:
          "As a business major one of my prereqs was a computer science class. With the switch to online, my professor has been unresponsive and thanks to WeBoro I was able to get someone to help me construct the code for $34"
      },
      {
        name: "Samantha",
        company: "Student",
        image: "img/student4.png",
        comment:
          "WeBoros Homework Helper guided me through every step of the way and didn’t just provide the answer. It really helped me with my learning"
      },
      {
        name: "Jacob",
        company: "Student",
        image: "img/student5.png",
        comment:
          "I placed a homework order through their mobile app and within few hours my order was complete"
      }
    ]
  },
  contact: {
    title: "Contact With WeBoro",
    description:
      "It's very easy to get in touch with us. Just use the contact form or pay us a visit for a coffee at the office. Dynamically innovate competitive technology after an expanded array of leadership.",

    office: "WeBoro",
    address: "",
    phone: "+617-315-8646",
    email: "info@weboro.co"
  },
  trustedCompany: [
    "img/client-1-color.png",
    "img/client-6-color.png",
    "img/client-3-color.png",
    "img/client-4-color.png",
    "img/client-5-color.png"
  ],
  trustedCompanyGray: [
    "img/client-1-gray.png",
    "img/client-2-gray.png",
    "img/client-3-gray.png",
    "img/client-4-gray.png",
    "img/client-5-gray.png"
  ],
  trustedCompanyAboutUs: {
    title: "Trusted by companies",
    description: "Rapidiously morph transparent internal or \"organic\" sources whereas resource sucking e-business. Conveniently innovate compelling internal.",
    companies: [
      "img/client-5-color.png",
      "img/client-1-color.png",
      "img/client-6-color.png",
      "img/client-3-color.png",
      "img/client-4-color.png",
      "img/client-5-color.png",
      "img/client-7-color.png",
      "img/client-2-color.png",
      "img/client-1-color.png",
      "img/client-3-color.png"
    ]
  },
  teamMember: {
    members: [
      {
        name: "Edna Mason",
        designation: "Senior Designer",
        image: "img/team-4.jpg"
      },
      {
        name: "Arabella Ora",
        designation: "Senior Designer",
        image: "img/team-1.jpg"
      },
      {
        name: "John Charles",
        designation: "Senior Designer",
        image: "img/team-2.jpg"
      },
      {
        name: "Jeremy Jere",
        designation: "Senior Designer",
        image: "img/team-3.jpg"
      },
    ]
  },
  video: {
    link: "https://www.youtube.com/watch?v=9No-FiEInLA"
  }
};
