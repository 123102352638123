import React from "react";
import _data from "../../data";

export default class Screenshots extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      price: {}
    };
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */

    this.setState({
      price: _data.price
    });
  }

  render() {
    return (
      <React.Fragment>
        <section
          id="screenshots"
          className="screenshots-section ptb-100 gray-light-bg"
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <div className="section-heading text-center">
                  <h2>
                    WeBoro Screenshots <br /> <span>Here’s a Sneak Peak On Our Next User Interface Update</span>
                  </h2>
                  
                </div>
              </div>
            </div>
            <div className="screen-slider-content mt-5">
              {/*<div className="screenshot-frame"></div> */}
              <div className="screen-carousel owl-carousel owl-theme dot-indicator">
                <img src="img/s1.jpg" className="img-fluid" alt="screenshots" />
                <img src="img/s2.jpg" className="img-fluid" alt="screenshots" />
                <img src="img/s3.jpg" className="img-fluid" alt="screenshots" />
                <img src="img/s4.jpg" className="img-fluid" alt="screenshots" />
                
               
                
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
