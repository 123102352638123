import * as firebase from 'firebase/app';
import React from "react";
import firebaseConfig from '../../HeroSection/firebaseConfig';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/firebase-database'
import 'firebase/firebase-storage'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import DataTable from 'react-data-table-component';
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import Button from 'react-bootstrap/Button'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import ReactSnackBar from "react-js-snackbar";
import RiseLoader from "react-spinners/RiseLoader";
import Badge from 'react-bootstrap/Badge'
import Card from 'react-bootstrap/Card'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
class Dashboard extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            title:'Dashboard',
            orderstoday:0,
            ordersmonth:0,
            totalusers:0,
            totaldocuments:0
        }
    }

    loadData = () =>{
        this.setState({finalTable:<div className="sweet-loading" style={{marginTop:'100px',width:'80%',marginLeft:'auto',marginRight:'auto'}}>
        <center><RiseLoader
          
          size={15}
          color={"#8d2ad7"}
          margin={2}
          loading={true}
        /></center>
      </div>})
      
        const ExpanableComponent = ({ data }) => <div className="row col-lg-12" style={{paddingTop:15}}>
    
    <table class="table table-bordered table-sm" style={{marginLeft:'10px'}}>
    
    <tbody>
      <tr>
        <td scope="row"><b>Name :  </b></td>
        <td><b>{data.username}</b></td>
        <td scope="row"><b>Homework Type :  </b></td>
        <td><b>{data.homeworktype}</b></td>
        
      </tr>
      <tr>
          <td><b>Phone : </b></td>
        <td><b>{data.phone}</b></td>
        <td scope="row"><b>Education Level :  </b></td>
        <td><b>{data.educationlevel}</b></td>
        
      </tr>
      <tr>
      <td><b>Email : </b></td>
        <td><b>{data.email}</b></td>
        <td scope="row"><b>Order Time :  </b></td>
        <td><b>{data.time}</b></td>
        
      </tr>
      <tr>
      <td><b>Date & Time  : </b></td>
        <td><b>{data.date}</b></td>
        <td scope="row"><b>Order Status :  </b></td>
        <td><b><Badge variant={data.status === "Completed" ? "success" : data.status === "Processing" ?  "warning" : "danger"}>{data.status}</Badge></b></td>
        
      </tr>
      <tr>
      <td><b>Order Price : </b></td>
        <td><b>{data.price}</b></td>
        <td><b>Action : </b></td>
        <td>
        
        <ButtonGroup className="mr-2" size="sm" aria-label="Second group">
        <Button onClick={() => this.markStatus(data.docid,'Pending')} className="btn btn-default" style={{backgroundColor:'#8D2AD7',color:'white',borderColor:'white'}}> <span className="ti-flag-alt"></span> Mark as Pending</Button>
           <Button onClick={() => this.markStatus(data.docid,'Processing')} className="btn btn-default" style={{backgroundColor:'#8D2AD7',color:'white',borderColor:'white'}}><span className="ti-alarm-clock"></span> Mark as Processing</Button>
            <Button onClick={() => this.markStatus(data.docid,'Completed')} className="btn btn-default" style={{backgroundColor:'#8D2AD7',color:'white',borderColor:'white'}}><span className="ti-check-box"></span> Mark as Completed</Button>
        
        </ButtonGroup>
        <Button onClick={() => this.deleteOrder(data.docid)}  className="btn btn-danger btn-sm " style={{borderColor:'white'}}><span class="ti-trash"></span> Delete Order</Button>
        </td>
        
      </tr>
    </tbody>
  </table>
          
          
        
        
          
          
          </div>;
        var that = this;
        var user = firebase.auth().currentUser;
        firebase.database().ref('CustomOrders').once('value').then(function(snapshot) {
        
          if(snapshot && snapshot.exists())
          {
            var response = snapshot.val()
            
            
            that.setState({ordersmonth:Object.keys(response).length})
            
          }
         
          
        })
        firebase.database().ref('Users').once('value').then(function(snapshot) {
            
             if(snapshot && snapshot.exists())
             {
               var response = snapshot.val()
               
               
               that.setState({totalusers:Object.keys(response).length})
               
             }
            
             
           })


           firebase.database().ref('Users').once('value').then(function(snapshot) {
            // console.log(snapshot.val())
             that.setState({userDocuments:snapshot.val()})
            // console.log(that.state.userDocuments)
            var data = snapshot.val()
            var totald = 0;
            if(snapshot && snapshot.exists())
            {
                 that.state.data = [];
                    for(var key in data){
                        for(var innerKey in data[key].Documents)
                        {
                          totald++;
                        }
                    }
              
                 
                   that.setState({totaldocuments:totald})
             
            }
            
             
           })
        
      }
      componentDidMount() {
          this.loadData()
      }
    render()
    {
        return(<div>
            <Container>
                <br/><br/>
  <Row md={4}>
    
    <Col  xs={4}><Card style={{borderLeft:'6px solid #8d2ad7',borderColor:'#8d2ad7'}} >
    <Card.Header>{this.state.ordersmonth}</Card.Header>
    <Card.Body>
      <Card.Title>Total Orders</Card.Title>
     
    </Card.Body>
  </Card></Col>
    <Col  xs={4}><Card style={{borderLeft:'6px solid #8d2ad7',borderColor:'#8d2ad7'}} >
    <Card.Header>{this.state.totaldocuments}</Card.Header>
    <Card.Body>
      <Card.Title>Total Documents</Card.Title>
     
    </Card.Body>
  </Card></Col>
    <Col  xs={4}><Card style={{borderLeft:'6px solid #8d2ad7',borderColor:'#8d2ad7'}}>
    <Card.Header>{this.state.totalusers}</Card.Header>
    <Card.Body>
      <Card.Title>Total Users</Card.Title>
      
    </Card.Body>
  </Card></Col>
  
  </Row><br/>
  <center><button style={{fontWeight:'bold',color:'white',backgroundColor:'#8D2AD7'}} className="btn btn-block" onClick={() => this.loadData()}>
            <span class="ti-reload" ></span> Refresh</button></center>
</Container>
        </div>)
    }
}

export default Dashboard;