import * as firebase from 'firebase/app';
import React from "react";
import firebaseConfig from '../HeroSection/firebaseConfig';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/firebase-database'
import 'firebase/firebase-storage'
import { connect } from "react-redux";
import Modal from 'react-bootstrap/Modal'
import PAYMENT_SERVER_URL from '../../constants/server';
import axios from 'axios';
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
class HeroSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
     open: false,
     deleteOpen:false,
     openPurchase:false,
     subscription:'....',
     subscriptionId:'',
     downloadCount:0
    };
  }
  loadData = () =>{
    var that = this;
    var user = firebase.auth().currentUser;
    firebase.database().ref('Users/' + user.uid).once('value').then(function(snapshot) {
      console.log(user.uid)
      if(snapshot.val())
      {
        console.log(snapshot.val().subscriptionType)
       that.setState({subscription:snapshot.val().subscriptionType,subscriptionId:snapshot.val().subId,downloadCount:snapshot.val().count})
         console.log(that.state.subscription)
      }
     
      
    });
  }
  componentDidMount() {
    this.loadData()
    /**
     * Your ajax will goes here to get data then call setState
     */
  }
openPurchaseModal = (e) =>{

  console.log("Opening Purchase Model")
  this.setState(
    {
      openPurchase:true,
      open:false
  }
  )
}
cancelSubscription= () =>{
  console.log("Cancel")

  if(window.confirm("Are you Sure you want to cancel Monthly Subscription ? "))
  {
    console.log("Initiating Cancel . . . . ")


    axios.post(PAYMENT_SERVER_URL+'/cancel',
        {
         
        subId: this.state.subscriptionId,
        
        })
        .then((data) => {
          console.log(data)
          var user = firebase.auth().currentUser;
          var x = firebase.database().ref("Users/"+user.uid);
          x.update({
            subscriptionType:'Free'
          })
      
      
      
      
          this.loadData()
          alert("Subscription Canceled . . . . . ")
        })
        .catch((errorPayment) => {
          alert("ailed Due to an Error . . . . . ")
        })




    
  }
}
  render() {

   
    return (
      <React.Fragment>
        <section className="hero-section pt-100 background-img"
        style={{
          backgroundImage: "url('img/image(2).png')",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "cover"
        }}>
        <div className="container">
           
           <div className="row">
           {/* Top Search Bar For Searching Documents */}

           <div className="card" style={{width:'100%'}}>
           <div className="form-group card-body">
             <div class="row">
                      <div className="col-lg-7 col-sm-12">
                        <div className="input-group">
                          <div className="input-icon">
                            
                          </div>
                          <input
                           
                            type="text"
                            name="search"
                            className="form-control"
                            placeholder="Search For Study Material, Guides and Notes .  .  .  .  .  .  ."
                            required
                          />
                         
                        </div>
                        </div>
                        <div className="col-lg-3 col-sm-12">
                        <div className="input-group">
                          <div className="input-icon">
                            
                          </div>
                          <select
                           disabled
                            type="text"
                            name="search"
                            className="form-control"
                          
                            required
                          >

                            <option> - - - - - - All Schools - - -  - - </option>
                            <option>School A</option>
                            <option>School B</option>
                          </select>
                         
                        </div>
                        </div>
                        <div className="col-lg-2 col-sm-12">
                        <a href="/search" style={{borderRadius:'5px',padding:'16px'}}  class="btn solid-btn btn-block "> <b class="ti-search" style={{fontWeight:'bolder'}}></b> Search </a>
                     
                        </div>
                        </div>
                        
                      </div>

           </div>
           
           </div>
           <br/>
            <div className="row">
                <ul className="list-inline counter-wrap">
                    <li className="list-inline-item">
                        <div className="single-counter text-center">
                            <span>0</span>
                            <h6>Your Documents </h6>
                        </div>
                    </li>
                    <li className="list-inline-item">
                        <div className="single-counter text-center">
                            <span>{this.state.downloadCount} / 5</span>
                            <h6>Your Downloads</h6>
                        </div>
                    </li>
                    <li className="list-inline-item">
                    <div className="single-counter text-center">
                      <br/>
                            <span> 
                              
                            {this.state.subscription === "Free" ? "" : <Button onClick={() => this.cancelSubscription()}  variant="primary" className="btn-not-rounded " style={{backgroundColor:'#6730e3',borderColor:'#6730e3'}}> Cancel Subscription</Button>
             }  
                              
                              
                                </span>
                            <h6></h6>
                        </div>
                    </li>
                    <li className="list-inline-item">
                        <div className="single-counter text-center">
                            <span>{this.state.subscription}</span>
                            <h6>Subscription Status</h6>
                        </div>
                    </li>
                    
                </ul>
            </div>
            <Modal
           
        show={this.state.open}
        onHide={() => this.setState({open:false})}
        dialogClassName="modal-xl"
        
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title" classnames="color-secondary">
          <b class="ti-search" style={{fontWeight:'bolder'}}></b>  Search Results
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div class="row">

          <div class="col-lg-3">
            <Card style={{borderColor:'#6730e3'}} >
              <Card.Header style={{fontWeight:'bold'}}>Stydy Guide for Class 6th Updated Version</Card.Header>
              <Card.Img variant="top" style={{width:'180px',marginLeft:'auto',marginRight:'auto'}} src="https://support.joinhandshake.com/hc/article_attachments/115026121948/mceclip4.png" />
              <Card.Body>
              
                
                <Button onClick={(e) => this.openPurchaseModal(e)} variant="primary" className="btn-block" style={{backgroundColor:'#6730e3'}}><span className="ti-import"></span> Get Now </Button>
              </Card.Body>
            </Card>


            
          </div>

          <div class="col-lg-3">
            <Card style={{borderColor:'#6730e3'}} >
              <Card.Header style={{fontWeight:'bold'}}>Stydy Guide for Class 6th Updated Version</Card.Header>
              <Card.Img variant="top" style={{width:'180px',marginLeft:'auto',marginRight:'auto'}} src="https://support.joinhandshake.com/hc/article_attachments/115026121948/mceclip4.png" />
              <Card.Body>
              
                
                <Button variant="primary" className="btn-block" style={{backgroundColor:'#6730e3'}}><span className="ti-import"></span> Get Now </Button>
              </Card.Body>
            </Card>


            
          </div>

          <div class="col-lg-3">
            <Card style={{borderColor:'#6730e3'}} >
              <Card.Header style={{fontWeight:'bold'}}>Stydy Guide for Class 6th Updated Version</Card.Header>
              <Card.Img variant="top" style={{width:'180px',marginLeft:'auto',marginRight:'auto'}} src="https://support.joinhandshake.com/hc/article_attachments/115026121948/mceclip4.png" />
              <Card.Body>
              
                
                <Button variant="primary" className="btn-block" style={{backgroundColor:'#6730e3'}}><span className="ti-import"></span> Get Now </Button>
              </Card.Body>
            </Card>


            
          </div>

          <div class="col-lg-3">
            <Card style={{borderColor:'#6730e3'}} >
              <Card.Header style={{fontWeight:'bold'}}>Stydy Guide for Class 6th Updated Version</Card.Header>
              <Card.Img variant="top" style={{width:'180px',marginLeft:'auto',marginRight:'auto'}} src="https://support.joinhandshake.com/hc/article_attachments/115026121948/mceclip4.png" />
              <Card.Body>
              
                
                <Button variant="primary" className="btn-block" style={{backgroundColor:'#6730e3'}}><span className="ti-import"></span> Get Now </Button>
              </Card.Body>
            </Card>


            
          </div>
        </div>
        <hr/>

        <div class="row">

          <div class="col-lg-3">
            <Card style={{borderColor:'#6730e3'}} >
              <Card.Header style={{fontWeight:'bold'}}>Stydy Guide for Class 6th Updated Version</Card.Header>
              <Card.Img variant="top" style={{width:'180px',marginLeft:'auto',marginRight:'auto'}} src="https://support.joinhandshake.com/hc/article_attachments/115026121948/mceclip4.png" />
              <Card.Body>
              
                
                <Button variant="primary" className="btn-block" style={{backgroundColor:'#6730e3'}}><span className="ti-import"></span> Get Now </Button>
              </Card.Body>
            </Card>


            
          </div>

          <div class="col-lg-3">
            <Card style={{borderColor:'#6730e3'}} >
              <Card.Header style={{fontWeight:'bold'}}>Stydy Guide for Class 6th Updated Version</Card.Header>
              <Card.Img variant="top" style={{width:'180px',marginLeft:'auto',marginRight:'auto'}} src="https://support.joinhandshake.com/hc/article_attachments/115026121948/mceclip4.png" />
              <Card.Body>
              
                
                <Button variant="primary" className="btn-block" style={{backgroundColor:'#6730e3'}}><span className="ti-import"></span> Get Now </Button>
              </Card.Body>
            </Card>


            
          </div>

          <div class="col-lg-3">
            <Card style={{borderColor:'#6730e3'}} >
              <Card.Header style={{fontWeight:'bold'}}>Stydy Guide for Class 6th Updated Version</Card.Header>
              <Card.Img variant="top" style={{width:'180px',marginLeft:'auto',marginRight:'auto'}} src="https://support.joinhandshake.com/hc/article_attachments/115026121948/mceclip4.png" />
              <Card.Body>
              
                
                <Button variant="primary" className="btn-block" style={{backgroundColor:'#6730e3'}}><span className="ti-import"></span> Get Now </Button>
              </Card.Body>
            </Card>


            
          </div>

          <div class="col-lg-3">
            <Card style={{borderColor:'#6730e3'}} >
              <Card.Header style={{fontWeight:'bold'}}>Stydy Guide for Class 6th Updated Version</Card.Header>
              <Card.Img variant="top" style={{width:'180px',marginLeft:'auto',marginRight:'auto'}} src="https://support.joinhandshake.com/hc/article_attachments/115026121948/mceclip4.png" />
              <Card.Body>
              
                
                <Button variant="primary" className="btn-block" style={{backgroundColor:'#6730e3'}}><span className="ti-import"></span> Get Now </Button>
              </Card.Body>
            </Card>


            
          </div>
        </div>
        </Modal.Body>
      </Modal>
{/* Purchase Notes Dialog */}
      <Modal
           
           show={this.state.openPurchase}
           onHide={() => this.setState({openPurchase:false})}
           dialogClassName="modal-xl"
           
           aria-labelledby="example-custom-modal-styling-title"
         >
           <Modal.Header closeButton>
             <Modal.Title id="example-custom-modal-styling-title" style={{fontWeight:'bolder'}}>
             <b class="ti-import" style={{fontWeight:'bolder'}}></b>  Get Study Resources
             </Modal.Title>
           </Modal.Header>
           <Modal.Body>

           <Card style={{borderColor:'#6730e3'}}>
  <Card.Header as="h5" style={{color:'#6730e3'}}>Stydy Guide for Class 6th Updated Version</Card.Header>
  <Card.Body>
    <div class="row">

        <div class="col-lg-6">
        <Card.Img variant="top" style={{width:'450px',marginLeft:'auto',marginRight:'auto'}} src="https://support.joinhandshake.com/hc/article_attachments/115026121948/mceclip4.png" />
     
        </div>
        <div class="col-lg-6">
        <Card.Title>Document Type : Study Guide <br/><br/> Number of Pages : 08 <br/><br/> Format : PDF <br/><hr/><br/><b style={{color:'#6730e3'}}>Total Amount to be Paid : <u>$6.00</u></b></Card.Title>
    <Card.Text>
      By CLicking the Below Button you agree to our terms and conditions and agree tp pay for this document.
    </Card.Text>
    <Button style={{backgroundColor:'#6730e3'}} className="btn-lg btn-block" variant="primary"> <span className="ti-money" ></span> Pay Now </Button>
 
        </div>

    </div>
           
    </Card.Body>
</Card>

      </Modal.Body>
      </Modal>
        </div>
       
    </section>
        </React.Fragment>
    );
  }
}

export default connect(state => ({
  state
}))(HeroSection);
