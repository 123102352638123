import React from "react"
import './util/style.css'

import Dashboard from './pages/dashboard'
import Homework from './pages/homework'
import Users from './pages/users.js'
import Row from 'react-bootstrap/Row'
import Documents from './pages/documents'
import Blog from './pages/blogAdmin'
import Password from './pages/password'
class Admin extends React.Component {
  constructor(props){
    super(props);
    this.state = {
        currentPage:'Dashboard', currentDisplay:<Dashboard />
    }
  }
  componentDidMount() {
      if(localStorage.getItem('adminloggedin') !== 'on')
      {
        window.location.assign("/");
      }
  }
handleNavigation = (id) =>{
    console.log("Navigation")

    if(id===1)
    {
        //Dashboard
        this.setState({currentPage:'Dashboard', currentDisplay:<Dashboard />})
    }
    else if (id===2)
    {
        //HomeWork Orders
        this.setState({currentPage:'Homework Orders', currentDisplay:<Homework />})
    }
    else if(id===3)
    {
        //Blog
        this.setState({currentPage:'Blog',currentDisplay:<Blog />})
    }
    else if(id===4)
    {
        //Documents 
        this.setState({currentPage:'Manage Documents',currentDisplay:<Documents/>})
    }
    else if(id===5)
    {
        //Analytics 
        this.setState({currentPage:'Analytics Settings'})
    }
    else if(id===6)
    {
        //seo settings 
        this.setState({currentPage:'SEO Settings'})
    }
    else if(id===7)
    {
        //change password 
        this.setState({currentPage:'Change Password',currentDisplay:<Password/>})
    }
    else if(id===8)
    {
        //logout 
        localStorage.setItem('adminloggedin', 'off');
        window.location.assign("/admin");
    }
    else if(id===9)
    {
        //users 
        this.setState({currentPage:'Manage Users',currentDisplay:<Users />})
    }

}

  render(){
    return(<div className="body">
            <div class="container-fluid fixed-top  " style={{padding:'0px', backgroundColor: '#8d2ad7',height:'65px',borderBottom:'2px solid white'}}>
    <div class="row collapse show no-gutters d-flex h-100 position-relative" style={{}}>
        <div class="col-3 px-0 w-sidebar navbar-collapse collapse d-none d-md-flex" style={{borderRight:'2px solid white'}}>
            <center>
            <span style={{color:'white',fontWeight:'bold',textAlign:'center',marginLeft:'53px',fontSize:'23px'}}>WeBoro</span>
            </center>
        </div>
        <div class="col px-3 px-md-0" style={{padding:'15px',width:'80%',float:'left'}}>
           
            <div style={{float:'left'}}><a style={{color:'white',marginLeft:'15px',fontWeight:'bold',fontSize:'17px'}} data-toggle="collapse" href="#" data-target=".collapse" role="button" >
                
                <i class="ti-menu" ></i>
            </a> <span style={{color:'white',fontSize:'18px',fontWeight:'bold',marginLeft:'15px'}}>{this.state.currentPage}</span></div>
            <div style={{float:'right'}}>
            <a class="nav-link right" href="#"> <span style={{fontWeight:'bold',marginRight:'15px',float:'right'}} class="ti-power-off"></span> </a>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid px-0 h-100">
    <div class="row vh-100 collapse show no-gutters d-flex h-100 position-relative">
        <div class="col-lg-3 col-md-2 col-sm-2 p-0 vh-100 h-100 text-white w-sidebar navbar-collapse collapse d-none d-md-flex sidebar" >
            
            <div class="navbar-dark  position-fixed h-100 w-sidebar" style={{ backgroundColor: '#8d2ad7',borderRight:'2px solid white'}}>
                <br/>
                <ul class="nav flex-column flex-nowrap text-truncate">
                    <li class="nav-item" onClick={() => this.handleNavigation(1)}>
                       <a class={this.state.currentPage === 'Dashboard' ? "nav-link active" : "nav-link"} href="#">  <span style={{fontWeight:'bold',marginRight:'15px'}} class="ti-dashboard"></span> Dashboard</a>
                    </li>
                    
                    <li class="nav-item" onClick={() => this.handleNavigation(2)}>
                        <a class={this.state.currentPage === 'Homework Orders' ? "nav-link active" : "nav-link"} href="#"><span style={{fontWeight:'bold',marginRight:'15px'}} class="ti-write"></span> Homework Orders</a>
                    </li>
                    <li class="nav-item" onClick={() => this.handleNavigation(9)}>
                     <a class={this.state.currentPage === 'Manage Users' ? "nav-link active" : "nav-link"} href="#"> <span style={{fontWeight:'bold',marginRight:'15px'}} class="ti-user"></span> Users</a>
                    </li>
                    <li class="nav-item" onClick={() => this.handleNavigation(3)}>
                        <a class={this.state.currentPage === 'Blog' ? "nav-link active" : "nav-link"} href="#"> <span style={{fontWeight:'bold',marginRight:'15px'}} class="ti-layout"></span> Blog</a>
  </li>
                    <li class="nav-item" onClick={() => this.handleNavigation(4)}>
                        <a class={this.state.currentPage === 'Manage Documents' ? "nav-link active" : "nav-link"} href="#"> <span style={{fontWeight:'bold',marginRight:'15px'}} class="ti-layers"></span> Documents</a>
                    </li>
                    
                    <li class="nav-item" onClick={() => this.handleNavigation(7)}>
                        <a class={this.state.currentPage === 'Change Password' ? "nav-link active" : "nav-link"} href="#"> <span style={{fontWeight:'bold',marginRight:'15px'}} class="ti-lock"></span> Change Password</a>
                    </li>
                    <li class="nav-item" onClick={() => this.handleNavigation(8)}>
                        <a class="nav-link" href="#"> <span style={{fontWeight:'bold',marginRight:'15px'}} class="ti-power-off"></span> Logout</a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-lg-10 col-md-10 col-sm-10 " style={{padding:10}}>
            {this.state.currentDisplay}
        </div>
    </div>
</div>

    </div>)
  }
}

export default Admin;