import React from "react";
//import _data from "../../data";

export default class Pricing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      price: 0.00,
      name:''
    };
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */

    this.setState({
      price: null
    });
    
  }
  handleFormValueChange(inputName, event) {
    let stateValue = {};
    stateValue[inputName] =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    this.setState(stateValue);
    
    const min = 13;
    const max = 67;
    
    this.setState({price: this.min + (Math.random() * (max - min))});
    
    
  }
  render() {
    return (
      <React.Fragment>
        <div className="overflow-hidden">
          <section
            
            className={"package-section ptb-100 " + (this.props.bgColor && this.props.bgColor === 'gray' ? 'gray-light-bg' : 'background-shape-right')} >
            <div className="container">
              <h3 style={{textAlign:'center'}}>Frequently Asked Questions</h3>
             
              <div className="mt-5 text-center">
                <p className="mb-2">
                  Still have questions to ask? No Worries, {" "}
                  <a href="/#contact" className="color-secondary">
                    Contact us Now
                  </a>
                </p>
              </div>
              {!(this.props.hideFaq && this.props.hideFaq === true) && this.props.theme !== "four" && (
                <div className="row mt-5">
                  <div className="col-lg-6">
                    <div id="accordion-1" className="accordion accordion-faq">
                      <div className="card">
                        <div
                          className="card-header py-4"
                          id="heading-1-1"
                          data-toggle="collapse"
                          role="button"
                          data-target="#collapse-1-1"
                          aria-expanded="false"
                          aria-controls="collapse-1-1"
                        >
                          <h6 className="mb-0">
                            <span className="ti-receipt mr-3"></span> How do I cancel my subscription?
                          </h6>
                        </div>
                        <div
                          id="collapse-1-1"
                          className="collapse"
                          aria-labelledby="heading-1-1"
                          data-parent="#accordion-1"
                        >
                          <div className="card-body">
                            <p>
                            Log into your account and click the dashboard, and there is a button you can press that says “Cancel Subscription”. Or you can email us at <a href="mailto:support@weboro.co">support@weboro.co</a></p>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div
                          className="card-header py-4"
                          id="heading-1-2"
                          data-toggle="collapse"
                          role="button"
                          data-target="#collapse-1-2"
                          aria-expanded="false"
                          aria-controls="collapse-1-2"
                        >
                          <h6 className="mb-0">
                            <span className="ti-gallery mr-3"></span> Can I submit homework help orders via the mobile app?

                          </h6>
                        </div>
                        <div
                          id="collapse-1-2"
                          className="collapse"
                          aria-labelledby="heading-1-2"
                          data-parent="#accordion-1"
                        >
                          <div className="card-body">
                            <p>
                            Yes, you can. 
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div
                          className="card-header py-4"
                          id="heading-1-3"
                          data-toggle="collapse"
                          role="button"
                          data-target="#collapse-1-3"
                          aria-expanded="false"
                          aria-controls="collapse-1-3"
                        >
                          <h6 className="mb-0">
                            <span className="ti-wallet mr-3"></span> How can I become a vetted contributor? 

                          </h6>
                        </div>
                        <div
                          id="collapse-1-3"
                          className="collapse"
                          aria-labelledby="heading-1-3"
                          data-parent="#accordion-1"
                        >
                          <div className="card-body">
                          <p>
                          We interview select students and pay them anywhere from $150-$250/semester to take notes and contribute to our database. If you would like to apply email us at
                              <b><a href="mailto:info@weboro.co" >info@weboro.co</a></b> 
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div id="accordion-1" className="accordion accordion-faq">
                      <div className="card">
                        <div
                          className="card-header py-4"
                          id="heading-1-1"
                          data-toggle="collapse"
                          role="button"
                          data-target="#collapse-1-8"
                          aria-expanded="false"
                          aria-controls="collapse-1-1"
                        >
                          <h6 className="mb-0">
                            <span className="ti-receipt mr-3"></span> How Safe is WeBoro ?

                          </h6>
                        </div>
                        <div
                          id="collapse-1-8"
                          className="collapse"
                          aria-labelledby="heading-1-1"
                          data-parent="#accordion-1"
                        >
                          <div className="card-body">
                            <p>
                            WeBoro uses Stripe which has end to end encryption, SSL protected and is a PCI level 1 service provider. </p>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div
                          className="card-header py-4"
                          id="heading-1-78"
                          data-toggle="collapse"
                          role="button"
                          data-target="#collapse-1-78"
                          aria-expanded="false"
                          aria-controls="collapse-1-2"
                        >
                          <h6 className="mb-0">
                            <span className="ti-gallery mr-3"></span> I have a question, who do I call/email ?


                          </h6>
                        </div>
                        <div
                          id="collapse-1-78"
                          className="collapse"
                          aria-labelledby="heading-1-2"
                          data-parent="#accordion-1"
                        >
                          <div className="card-body">
                            <p>
                            You can email us at <b><a href="mailto:info@weboro.co" >info@weboro.co</a></b>  or call our corporate line at 617-315-8646
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="card">
                        <div
                          className="card-header py-4"
                          id="heading-1-5"
                          data-toggle="collapse"
                          role="button"
                          data-target="#collapse-1-6"
                          aria-expanded="false"
                          aria-controls="collapse-1-2"
                        >
                          <h6 className="mb-0">
                            <span className="ti-gallery mr-3"></span> What if I’am not satisfied with WeBoro ? 


                          </h6>
                        </div>
                        <div
                          id="collapse-1-6"
                          className="collapse"
                          aria-labelledby="heading-1-2"
                          data-parent="#accordion-1"
                        >
                          <div className="card-body">
                            <p>
                            We have a 100% money back guarantee, you can email us at  <b><a href="mailto:info@weboro.co" >info@weboro.co</a></b> for any questions. 
 </p>
                          </div>
                        </div>
                      </div>
                    
                    </div>
                  </div>
                  
                </div>
              )}

              {!(this.props.hideFaq && this.props.hideFaq === true) && this.props.theme === "four" && (
                <div className="row pt-5">
                  <div className="col-md-6">
                    <div className="single-faq">
                      <h5>How can I pay for this?</h5>
                      <p>
                        Intrinsicly implement high standards in strategic theme
                        areas via inexpensive solutions. Assertively
                        conceptualize prospective bandwidth whereas client-based
                        imperatives.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="single-faq">
                      <h5>Is it possible to pay yearly?</h5>
                      <p>
                        Assertively iterate user friendly innovation without
                        open-source markets. Monotonectally extend resource
                        sucking manufactured products without high-payoff
                        paradigms. Objectively customize ubiquitous information
                        before economically sound relationships.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="single-faq">
                      <h5>Do you offer discounts on multiple items?</h5>
                      <p>
                        Dramatically target focused testing procedures after
                        holistic ideas. Collaboratively maximize high-payoff ROI
                        and value-added products. Distinctively deliver
                        cooperative collaboration and idea-sharing whereas
                        customized
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="single-faq">
                      <h5>Is VAT included in plan prices?</h5>
                      <p>
                        Distinctively simplify high-quality initiatives for
                        highly efficient applications. Monotonectally repurpose
                        integrated customer service after magnetic e-services.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="single-faq">
                      <h5>Will I pay more for some features?</h5>
                      <p>
                        Enthusiastically pontificate resource-leveling supply
                        chains whereas scalable markets. Authoritatively
                        streamline resource maximizing methods of empowerment
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="single-faq">
                      <h5>
                        Why are there no limits on the number of messages?
                      </h5>
                      <p>
                        Assertively target turnkey ideas for market-driven
                        portals. Appropriately e-enable world-class intellectual
                        capital whereas 2.0 mindshare.
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </section>
        </div>
      </React.Fragment>
    );
  }
}
