import React, { Component } from "react";
import * as firebase from 'firebase/app';
import firebaseConfig from '../HeroSection/firebaseConfig';
import 'firebase/auth';
import Spinner from 'react-bootstrap/Spinner'
import 'firebase/firestore';
import Pagination from 'react-bootstrap/Pagination'
import 'firebase/firebase-database'
class Blog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status:false,
      userDocuments:[],
      documentCount:0,
      currentPage:1,
      pagination:'',
      totalDisplayCount:100
    };
  }


  loadBlogData = () => {
    var that = this;
      console.log("Fetching Blog Data")
      firebase.database().ref('Blog').once('value').then(function(snapshot) {
        var data = snapshot.val()
        var count = 0;
      //console.log(data)
        if(snapshot && snapshot.exists())
        {
           
               count = Object.keys(data).length
                {Object.keys(data).map((current, i) => (
                 // console.log(data[current])
                  that.state.userDocuments.push(data[current])
                  
              ))}
                that.setState({status:true,totalDisplayCount:count, documentCount:count,dataCopy:that.state.userDocuments})
        }
      
    })
  }
  componentDidMount() {
    this.loadBlogData()
    
  }

  render() {
   
    const numPerPage = this.state.status ? 100 : 100;
    const pageNumbers = [];
      for (let i = 1; i <= Math.ceil(this.state.userDocuments.length / numPerPage); i++) {
        pageNumbers.push(i);
      }

      const renderPageNumbers = pageNumbers.map(number => {
        return (
          <Pagination.Item key={number}  onClick={this.handleClick} id={number} active={number === this.state.currentPage}>
      {number}
    </Pagination.Item>
         
        );
      });

    const maxLength = 75;
    var show = <div><Spinner animation="border" /> Loading Documents . . .</div>;
    const indexOfLastTodo = this.state.currentPage * numPerPage;
    const indexOfFirstTodo = indexOfLastTodo - numPerPage;
    const currentTodos = this.state.userDocuments.slice(indexOfFirstTodo, indexOfLastTodo);

    if(this.state.status)
    {
      show = currentTodos.map( (current,index) =>( <div className="col-md-4">
                <div className="single-blog-card card border-0 shadow-sm">
                  <span className="category position-absolute badge badge-pill badge-primary">
                    {current.blogCategory}
                  </span>
                  <img
                    src={current.blogImage}
                    className="card-img-top position-relative"
                    alt="blog"
                  />
                  <div className="card-body">
                    <div className="post-meta mb-2">
                      <ul className="list-inline meta-list">
                        <li className="list-inline-item">Published on {current.date}</li>
                        <li className="list-inline-item">
                          <span>By</span> {current.blogAuthor}
                        </li>
                        <li className="list-inline-item">
                          {/*<span>10</span> Share */}
                        </li>
                      </ul>
                    </div>
                    <h3 className="h5 card-title">
                 <a  href={"/article?post=" + current.id}>{current.blogTitle}</a>
                    </h3>
                    <p className="card-text">
                    {current.blogDescription.length > maxLength ? current.blogDescription.substring(0,maxLength)+' ....' : current.blogDescription}
                    </p>
                    <a href={"/article?post=" + current.id} className="detail-link">
                      Read more <span className="ti-arrow-right"></span>
                    </a>
                  </div>
                </div>
              </div>))
    }

    else{
      show = <div><Spinner animation="border" /> Loading Documents . . .</div>;
   }
    return (
      <React.Fragment>
        <section className="our-blog-section ptb-100 gray-light-bg">
          <div className="container">
            <div className="row">
              <div className="col-md-10">
                <div className="section-heading mb-5">
                  <h2>Latest Posts 

                  </h2>
                 
                </div>
              </div>
              <div className="col-md-2" ><a href="https://medium.com/@weboro" target="_blank" className="btn btn-default" style={{backgroundColor:'#8C47D9',color:'white',borderRadius:20}}>Visit WeBoro's Blog</a></div>
            </div>
            <div className="row">
              {show}
             
              
            </div>

           
          </div>
         
        </section>
      </React.Fragment>
    );
  }
}

export default Blog;
