const config  = {
    apiKey: "AIzaSyCO8F-hFcmVt8FmGQFCamklLx1xSuaQmq0",
    authDomain: "womake-bcbaa.firebaseapp.com",
    databaseURL: "https://womake-bcbaa.firebaseio.com",
    projectId: "womake-bcbaa",
    storageBucket: "womake-bcbaa.appspot.com",
    messagingSenderId: "912715341954",
    appId: "1:912715341954:web:392c40da067f444ecdd49b",
    measurementId: "G-R7FZ2SE5F2"
  };


  export default config;