import * as firebase from 'firebase/app';
import React from "react";
import firebaseConfig from '../HeroSection/firebaseConfig';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/firebase-database'
import queryString from 'query-string';
import 'firebase/firebase-storage'
import { connect } from "react-redux";
import {PDFtoIMG} from 'react-pdf-to-image';
import Modal from 'react-bootstrap/Modal'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import OPTIONS_SCHOOLS from '../PromoSection/options'
import Select from 'react-select';
import Row from 'react-bootstrap/Row';
import Pagination from 'react-bootstrap/Pagination'
import { Document, Page ,pdfjs} from "react-pdf"
import Accordion from 'react-bootstrap/Accordion'
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class HeroSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
     open: false,
     searchTerm:'',
     deleteOpen:false,
     openPurchase:false,
     display:'',
     selectedCat:'',
     status:false,
     userDocuments:[],
     documentCount:0,
     userType:'Free',
     selectedOption: null,
     dataCopy:null,
     currentPage:1,
     pagination:'',
     totalDisplayCount:100
    };
    this.handleClick = this.handleClick.bind(this);
  }

  getPagesCount = (total, denominator) => {
    const divisible = total % denominator === 0;
    const valueToBeAdded = divisible ? 0 : 1;
    return Math.floor(total / denominator) + valueToBeAdded;
  };
  downloadDocument=(type,docUrl)=>{
    console.log(type)
    console.log(docUrl)
     if(type === "Free")
    {
      if(this.state.userType === "Free")
      {
        if (window.confirm("This is Premium Document, Do you to subscribe to unlock this Document ?"))
        {
          window.location.href = "/#subscription"
        }
        
      }
      if(this.state.userType === "All Star Student Monthly")
      {
          // Unlimited Downlods 
          window.location.href = docUrl
      }
      if(this.state.userType === "Average Student Monthly")
      {
        if(this.state.downloadCount < 5)
        {
          if(window.confirm("Are you sure you want to unlock this document ? You have "+(5-this.state.downloadCount) +" Downloads Remaining. "))
          {
              var newCount = this.state.downloadCount + 1;
              var user = firebase.auth().currentUser;
              var x = firebase.database().ref("Users/"+user.uid);
              x.update({
                count:newCount
              })
              window.location.href = docUrl
          }
        }
        else{
          alert("You have already unlocked 5 documents, please upgrade subscription to unlock more !");
        }
      }
    }
  }
    loadUser(){
      var that = this;
      var user = firebase.auth().currentUser;
      firebase.database().ref('Users/' + user.uid).once('value').then(function(snapshot) {
        console.log(user.uid)
        if(snapshot.val())
        {
          console.log(snapshot.val().subscriptionType)

          that.setState({userType:snapshot.val().subscriptionType,downloadCount:snapshot.val().count})
       
        }
        
       
        
      });
    }
  componentDidMount() {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    this.loadUser();
    var that = this;
    var user = firebase.auth().currentUser;
   
    firebase.database().ref('Users').orderByChild('Documents').once('value').then(function(snapshot) {
        var data = snapshot.val()
        var count = 0;
      //console.log(data)
        if(snapshot && snapshot.exists())
        {
           
                for(var key in data){
                    for(var innerKey in data[key].Documents)
                    {
                        //console.log(innerKey)
                        //console.log(data[key].Documents[innerKey].docTitle)
                        //console.log(data[key].Documents[innerKey].downloadUrl)
                        that.state.userDocuments.push({
                            title:data[key].Documents[innerKey].docTitle,
                            school:data[key].Documents[innerKey].schoolName,
                            download:data[key].Documents[innerKey].downloadUrl,
                          course:data[key].Documents[innerKey].courseName,
                          category:data[key].Documents[innerKey].category,
                        type:data[key].Documents[innerKey].type,
                          image: <PDFtoIMG file={data[key].Documents[innerKey].downloadUrl} >
                          {({pages}) => {
                              if (!pages.length) return 'Loading...';
                              return <img src={pages[0]} className="card-img-top position-relative"  width={130} />;
                             
                          }}
                      </PDFtoIMG>
                      })
                            that.setState({status:true})
                            count++;
                        }
                }
                that.setState({totalDisplayCount:count, documentCount:count,dataCopy:that.state.userDocuments})
           //Object.keys(data).map((cur,index) =>{
            //  console.log(data[cur].Documents)
           //  console.log(data[cur].Documents[0])
             
          //     //that.setState({status:true})
               
          // })
        }
     
     //console.log(that.state.userDocuments)
      
    }).then(() => {
      console.log("Url : " + window.location.href)
      const keyword =  window.location.href;
  
      if(keyword.includes('='))
      {
        //alert(keyword.split("=").pop());
        this.setState({searchTerm:keyword.split("=").pop()})
        this.searchBySchool()
      }
    })

    
    
   

  }
  handleChange = selectedOption => {
    this.setState({ selectedOption });
    this.setState({schoolName:selectedOption.value})
    console.log(`Option selected:`, selectedOption.value);
  };
  handleClick(event) {
    this.setState({
      currentPage: Number(event.target.id)
    });
  }
  searchBySchool= () =>{
   


    var dataSearch = [];
    if(this.state.selectedOption === null || this.state.selectedOption.value === "ALL")
    {
      //all schools
      dataSearch = this.state.dataCopy
      this.setState({userDocuments:this.state.dataCopy, documentCount:this.state.dataCopy.length})
      
    }
    else
    {
      //selected school
      this.setState({userDocuments:[]})
     const school = this.state.selectedOption.value;
     dataSearch = [];
     this.state.dataCopy.map( (current,index) =>(
      current.school === school ? dataSearch.push(current) :''
     ))
     this.setState({userDocuments:dataSearch, documentCount:dataSearch.length})
    }




    //////////////////////////////////////////////Perform Search 
      
      if(this.state.searchTerm !== '')
      {
        ///found search Term 
        console.log("insearch")
       
        let newList = [];
         newList = dataSearch.filter(item => {
           const lc = item.title.toLowerCase();
           console.log(lc);
           const filerWord = this.state.searchTerm;

           return lc.includes(filerWord)
         })
        
         console.log(newList)
         this.setState({userDocuments:newList, documentCount:newList.length})
         dataSearch = newList
      }

      

      this.setState({
        currentPage: 1
      });

      

     
    ////////////////////////////////////////Perform Search 
  }

  searchCat = (cat) =>{
    if(cat !== '')
      {
        var actual = this.state.dataCopy
        ///found search Term 
        console.log("catger")
       
        let newList1 = [];
         newList1 = actual.filter(item => {
           //const lc = item.category.toLowerCase();
           console.log(item.category);
          

           return item.category === cat
         })
        
         console.log(newList1)
         this.setState({userDocuments:newList1, documentCount:newList1.length,currentPage:1})
         
      }
  }
  render() {
    const numPerPage = this.state.status ? 28 : 100;
    const pageNumbers = [];
      for (let i = 1; i <= Math.ceil(this.state.userDocuments.length / numPerPage); i++) {
        pageNumbers.push(i);
      }

      const renderPageNumbers = pageNumbers.map(number => {
        return (
          <Pagination.Item key={number}  onClick={this.handleClick} id={number} active={number === this.state.currentPage}>
      {number}
    </Pagination.Item>
         
        );
      });

    const maxLength = 61;
    var show = <div><Spinner animation="border" /> Loading Documents . . .</div>;
    const indexOfLastTodo = this.state.currentPage * numPerPage;
    const indexOfFirstTodo = indexOfLastTodo - numPerPage;
    const currentTodos = this.state.userDocuments.slice(indexOfFirstTodo, indexOfLastTodo);

    if(this.state.status){
      
       show = currentTodos.map( (current,index) =>(
       
        
        <div className="col-md-3" key={current.download}>
        <div className="single-blog-card card  shadow-sm" >
          <span className="category position-absolute badge badge-pill" style={{color:'white',backgroundColor:current.type === "Premium" ? "#8d2ad7" : "#00b7ff"}}>
            {current.type}
          </span>
          
         {current.image}
          <div className="card-body">
            {/*<div className="post-meta mb-2">
              <ul className="list-inline meta-list">
                <li className="list-inline-item">{current.course}</li>
                
              </ul>
       </div>*/}
            <h6 className="h6 card-title">
              <a href="/#">{current.title.length > maxLength ? current.title.substring(0,maxLength)+'. .' : current.title}</a>
            </h6>
            {current.title.length < 45? <br/>  : ""}
            <a onClick={()=> this.downloadDocument(current.type,current.download)}   className="btn btn-primary btn-block" style={{borderColor:'#8d2ad7',backgroundColor:'#8d2ad7',borderRadius:20,color:'white'}}>
              
              
              {(this.state.userType === "Free" && current.type === "Premium") ? "Subscribe To Unlock" : "Open Document"}
              
              <span className="ti-arrow-right"></span>
            </a>
          </div>
        </div>
      </div>

                
          







    ))
    }
    else{
       show = <div><Spinner animation="border" /> Loading Documents . . .</div>;
    }
   
    return (
      <React.Fragment>
        <section className="hero-section pt-100 background-img"
        style={{
          backgroundImage: "url('img/image(2).png')",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "cover"
        }}>
        <div className="container">
           <div className="row">
           {/* Top Search Bar For Searching Documents */}

           <div className="card" style={{width:'100%'}}>
           <div className="form-group card-body">
             <div class="row">
                      <div className="col-lg-7 col-sm-12">
                        <div className="input-group">
                          <div className="input-icon">
                            
                          </div>
                          <input
                           value={this.state.serchTerm}
                           onChange={(e) => this.setState({searchTerm:e.target.value})}
                            type="text"
                            name="search"
                            className="form-control"
                            placeholder="Search For Study Material, Guides and Notes .  .  .  .  .  .  ."
                            required
                          />
                         
                        </div>
                        </div>
                        <div className="col-lg-3 col-sm-12">
                        
                          
                          <Select
                            value={this.state.selectedOption}
                            onChange={this.handleChange}
                            options={OPTIONS_SCHOOLS}
                            
                          />
                         
                       
                        </div>
                        <div className="col-lg-2 col-sm-12">
                        <button onClick={() => this.searchBySchool()} style={{borderRadius:'5px',padding:'16px'}}  class="btn solid-btn btn-block "> <b class="ti-search" style={{fontWeight:'bolder'}}></b> Search </button>
                     
                        </div>
                        </div>
                        
                      </div>

           </div>
           
           </div>
           <br/>
            
            <Modal
           
        show={this.state.open}
        onHide={() => this.setState({open:false})}
        dialogClassName="modal-xl"
        
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title" classnames="color-secondary">
      <b class="ti-search" style={{fontWeight:'bolder'}}></b>  Search Results for 
          </Modal.Title>
        </Modal.Header>

        

        <Modal.Body>
        <div class="row">

        
        </div>
        <hr/>

        
        </Modal.Body>
      </Modal>
{/* Purchase Notes Dialog */}
      <Modal
           
           show={this.state.openPurchase}
           onHide={() => this.setState({openPurchase:false})}
           dialogClassName="modal-xl"
           
           aria-labelledby="example-custom-modal-styling-title"
         >
           <Modal.Header closeButton>
             <Modal.Title id="example-custom-modal-styling-title" style={{fontWeight:'bolder'}}>
             <b class="ti-import" style={{fontWeight:'bolder'}}></b>  Get Study Resources
             </Modal.Title>
           </Modal.Header>
           <Modal.Body>

           <Card style={{borderColor:'#6730e3'}}>
  <Card.Header as="h5" style={{color:'#6730e3'}}>Stydy Guide for Class 6th Updated Version</Card.Header>
  <Card.Body>
    <div class="row">

        <div class="col-lg-6">
        <Card.Img variant="top" style={{width:'450px',marginLeft:'auto',marginRight:'auto'}} src="https://support.joinhandshake.com/hc/article_attachments/115026121948/mceclip4.png" />
     
        </div>
        <div class="col-lg-6">
        <Card.Title>Document Type : Study Guide <br/><br/> Number of Pages : 08 <br/><br/> Format : PDF <br/><hr/><br/><b style={{color:'#6730e3'}}>Total Amount to be Paid : <u>$6.00</u></b></Card.Title>
    <Card.Text>
      By CLicking the Below Button you agree to our terms and conditions and agree tp pay for this document.
    </Card.Text>
    <Button style={{backgroundColor:'#6730e3'}} className="btn-lg btn-block" variant="primary"> <span className="ti-money" ></span> Pay Now </Button>
 
        </div>

    </div>
           
    </Card.Body>
</Card>

      </Modal.Body>
      </Modal>
        </div>
       
    </section>

    <section className="our-blog-section  gray-light-bg">
      <br/>
        <div className="container">
            {/*----------------------------------Categories  ------------------------------------ */}
            <Accordion defaultActiveKey="1">
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="0">
      <button className="btn btn-primary"><span class="ti-angle-double-down"></span> Categories</button> | 
       <a className="btn btn-primary" target="_blank" href="/chemistry"><span class="ti-angle-double-down"></span> Chemistry Notes</a>
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="0">
      <Card.Body>

      <Row>
       <a style={{cursor:'pointer'}} onClick={()=> this.searchCat('Agricultural Microbiology')} className="btn btn-default" >Agricultural Microbiology</a>
       <a style={{cursor:'pointer'}} onClick={()=> this.searchCat('Bacteriology')}  className="btn btn-default">Bacteriology</a>
       <a style={{cursor:'pointer'}} onClick={()=> this.searchCat('Basic Microbiology')}  className="btn btn-default" >Basic Microbiology</a>
       <a style={{cursor:'pointer'}} onClick={()=> this.searchCat('Biochemical Test')}  className="btn btn-default" >Biochemical Test</a>
       <a style={{cursor:'pointer'}} onClick={()=> this.searchCat('Biochemical Test of Bacteria')}  className="btn btn-default" >Biochemical Test of Bacteria</a>
       <a style={{cursor:'pointer'}} onClick={()=> this.searchCat('Biochemistry')}  className="btn btn-default" >Biochemistry</a>
       <a style={{cursor:'pointer'}} onClick={()=> this.searchCat('Bioinformatics')}  className="btn btn-default" >Bioinformatics</a>
       <a style={{cursor:'pointer'}} onClick={()=> this.searchCat('Biotechnology')}  className="btn btn-default" >Biotechnology</a>
       <a style={{cursor:'pointer'}} onClick={()=> this.searchCat('Cancer Biology')}  className="btn btn-default" >Cancer Biology</a>
       <a style={{cursor:'pointer'}} onClick={()=> this.searchCat('Cell Biology')}  className="btn btn-default" >Cell Biology</a>
       <a style={{cursor:'pointer'}} onClick={()=> this.searchCat('Culture Media')}  className="btn btn-default" >Culture Media</a>
       <a style={{cursor:'pointer'}} onClick={()=> this.searchCat('Developmental Biology')}   className="btn btn-default" >Developmental Biology</a>
       <a style={{cursor:'pointer'}}  onClick={()=> this.searchCat('Difference Between')}  className="btn btn-default" >Difference Between</a>
       <a style={{cursor:'pointer'}}  onClick={()=> this.searchCat('Diseases')}  className="btn btn-default" >Diseases</a>
       <a  style={{cursor:'pointer'}} onClick={()=> this.searchCat('Biology')}  className="btn btn-default" >Biology</a>
       <a  style={{cursor:'pointer'}} onClick={()=> this.searchCat('Environmental Microbiology')}   className="btn btn-default" >Environmental Microbiology</a>
       <a style={{cursor:'pointer'}} onClick={()=> this.searchCat('Epidemiology')}   className="btn btn-default" >Epidemiology</a>
       <a  style={{cursor:'pointer'}} onClick={()=> this.searchCat('Food Microbiology')}   className="btn btn-default" >Food Microbiology</a>
       <a  style={{cursor:'pointer'}}  onClick={()=> this.searchCat('Human Anatomy')}  className="btn btn-default" >Human Anatomy</a>
       <a  style={{cursor:'pointer'}} onClick={()=> this.searchCat('Human Physiology')}   className="btn btn-default" >Human Physiology</a>

       <a  style={{cursor:'pointer'}} onClick={()=> this.searchCat('Immunology')}   className="btn btn-default" >Immunology</a>
       <a  style={{cursor:'pointer'}} onClick={()=> this.searchCat('Infection')}  className="btn btn-default" >Infection</a>
       <a  style={{cursor:'pointer'}} onClick={()=> this.searchCat('Instrumentation')}  className="btn btn-default" >Instrumentation</a>
       <a  style={{cursor:'pointer'}} onClick={()=> this.searchCat('Laboratory Tests')}   className="btn btn-default" >Laboratory Tests</a>
       <a  style={{cursor:'pointer'}} onClick={()=> this.searchCat('Microbiology Pictures')}  className="btn btn-default" >Microbiology Pictures</a>
       <a  style={{cursor:'pointer'}} onClick={()=> this.searchCat('Microscopy')}   className="btn btn-default" >Microscopy</a>       

       <a  style={{cursor:'pointer'}} onClick={()=> this.searchCat('Molecular Biology / Genetics')}   className="btn btn-default" >Molecular Biology / Genetics</a>
       <a  style={{cursor:'pointer'}} onClick={()=> this.searchCat('Mycology')}   className="btn btn-default" >Mycology</a>
       <a  style={{cursor:'pointer'}}  onClick={()=> this.searchCat('Parasitology')}  className="btn btn-default" >Parasitology</a>
       <a  style={{cursor:'pointer'}}  onClick={()=> this.searchCat('Pharmacology')}  className="btn btn-default" >Pharmacology</a>
       <a  style={{cursor:'pointer'}} onClick={()=> this.searchCat('Protocols')}   className="btn btn-default" >Protocols</a>
       <a  style={{cursor:'pointer'}} onClick={()=> this.searchCat('Report and Guidelines')}   className="btn btn-default" >Report and Guidelines</a>  

       <a  style={{cursor:'pointer'}}  onClick={()=> this.searchCat('Research Methodology')}  className="btn btn-default" >Research Methodology</a>
       <a  style={{cursor:'pointer'}} onClick={()=> this.searchCat('Short Answer Questions')}  className="btn btn-default" >Short Answer Questions</a>
       <a  style={{cursor:'pointer'}} onClick={()=> this.searchCat('Staining')}   className="btn btn-default" >Staining</a>
       <a  style={{cursor:'pointer'}} onClick={()=> this.searchCat('Virology')}   className="btn btn-default" >Virology</a>
        


      </Row>
      </Card.Body>
    </Accordion.Collapse>
  </Card>
  
</Accordion>
      
        <hr/>
      {/*---------------------------------Categories------------------------------------- */}
            <div className="row">
              <div className="col-md-8">
                <div className="section-heading mb-5">
                  <h3>Search Results </h3>
                  

       
                  <p>
                   {this.state.documentCount} Results
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              {/* Place Here Show  */}
              {show}
              
             
             
             
            </div>
            <hr/>
              <Pagination> {renderPageNumbers}</Pagination>
           {/*<div className="row">
              <div className="col-md-12">
                <nav className="custom-pagination-nav mt-4">
                  <ul className="pagination justify-content-center">
                    <li className="page-item">
                      <a className="page-link" href="/#">
                        <span className="ti-angle-left"></span>
                      </a>
                    </li>
                    <li className="page-item active">
                      <a className="page-link" href="/#">
                        1
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="/#">
                        2
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="/#">
                        3
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="/#">
                        4
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="/#">
                        <span className="ti-angle-right"></span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div> */}
          </div>
        </section>
        </React.Fragment>
    );
  }
}

export default connect(state => ({
  state
}))(HeroSection);
