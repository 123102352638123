import React, { Component } from "react";

import Header from "../components/Header/header";
import HeroSection from "../components/HeroSection/HeroSectionBlog";
import BlogSection from "../components/Blog";

import FooterSection from "../components/Footer";

class Theme extends Component {
  render() {
    return (
      <React.Fragment>
        <Header />
        <div className="main">
        <section
          className="hero-section ptb-100 background-img"
         
        >
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-md-9 col-lg-7">
                <div className="page-header-content text-white text-center pt-sm-5 pt-md-5 pt-lg-0">
                  <h1 className="text-white mb-0">Terms and Use</h1>
                 
                </div>
              </div>
            </div>
          </div>
        </section>
         {/* privacy contact  */}
          <div className="container">
              <br/>
          <p><span style={{fontWeight: 400}}>PLEASE NOTE THAT YOUR USE OF AND ACCESS TO OUR SERVICES (DEFINED BELOW) ARE SUBJECT TO THE FOLLOWING TERMS. BY AGREEING TO THESE TERMS, YOU EXPRESSLY ACKNOWLEDGE THAT YOU UNDERSTAND THE TERMS (INCLUDING THE DISPUTE RESOLUTION AND ARBITRATION PROVISIONS CONTAINED HEREIN) AND ACCEPT ALL OF THEM. IF YOU DO NOT AGREE TO ALL OF THE FOLLOWING, YOU MAY NOT USE OR ACCESS THE SERVICES IN ANY MANNER.</span></p>
<h6><span style={{fontWeight: 'bold',fontSize:15,color:'black'}}>1. INTRODUCTION AND GENERAL TERMS</span></h6>
<p><span style={{fontWeight: 400}}>Welcome to </span><a href="https://www.weboro.co/"><span style={{fontWeight: 400}}>www.weboro.co</span></a><span style={{fontWeight: 400}}>. Please read on to learn the rules and restrictions that govern your use of our website(s), products, services and applications (the &ldquo;Services&rdquo;). If you have any questions, comments, or concerns regarding these terms or the Services, please contact us at </span><span style={{fontWeight: 400}}>support@weboro.co</span><span style={{fontWeight: 400}}>&nbsp;&nbsp;</span></p>
<p><span style={{fontWeight: 400}}>These Terms of Use (the &ldquo;Terms&rdquo;) are a binding contract between you and WeBoro, Inc. (&ldquo;WeBoro,&rdquo; &ldquo;we&rdquo; and &ldquo;us&rdquo;). You must agree to and accept all of the Terms, or you don&rsquo;t have the right to use the Services. Your using the Services in any way means that you agree to all of these Terms, and these Terms will remain in effect while you use the Services. These Terms include the provisions in this document, as well as those in the WeBoro </span><a href="/privacy"><span style={{fontWeight: 400}}>Privacy Policy</span></a><span style={{fontWeight: 400}}>, </span><a href="/honorcode"><span style={{fontWeight: 400}}>Honor Code</span></a><span style={{fontWeight: 400}}></span><span style={{fontWeight: 400}}>.</span></p>
<p><span style={{fontWeight: 400}}>For the purposes of these Terms, &ldquo;User&rdquo; means anyone who accesses and/or registers for our Services, including without limitation students, Instructors, or other users. &ldquo;Instructor&rdquo; means Experts (as defined below), tutors, and anyone who provides answers, online courses, instruction, tutoring and learning services through our Services.</span></p>
<p><span style={{fontWeight: 400}}>Please be advised: these Terms contain provisions that govern how claims that you and WeBoro have against each other can be brought (see below). These provisions will, with limited exception, require that you submit claims you have against WeBoro to binding and final arbitration on an individual basis, not as a plaintiff or class member in any class, group or representative action or proceeding.</span></p>
<p><span style={{fontWeight: 400}}>Will these Terms ever change?</span></p>
<p><span style={{fontWeight: 400}}>We are constantly trying to improve our Services, so these Terms may need to change along with the Services. We reserve the right to change the Terms at any time, but if we do, we will bring it to your attention by placing a notice on the </span><a href="https://www.weboro.co/"><span style={{fontWeight: 400}}>www.weboro.co</span></a><span style={{fontWeight: 400}}> website, by sending you an email and/or by some other means.</span></p>
<p><span style={{fontWeight: 400}}>If you don&rsquo;t agree with the new Terms, you are free to reject them; unfortunately, that means you will no longer be able to use the Services. If you use the Services in any way after a change to the Terms is effective, that means you agree to all of the changes.</span></p>
<p><span style={{fontWeight: 400}}>Except for changes by us as described here, no other amendment or modification of these Terms will be effective unless in writing and signed by both you and us.</span></p>
<p><span style={{fontWeight: 400}}>What about my privacy?</span></p>
<p><span style={{fontWeight: 400}}>WeBoro takes the privacy of its users very seriously. For the current WeBoro Privacy Policy, please see link below.&nbsp;</span></p>
<p><span style={{fontWeight: 400}}>The Children&rsquo;s Online Privacy Protection Act (&ldquo;COPPA&rdquo;) requires that online service providers obtain parental consent before they knowingly collect personally identifiable information online from children who are under 13. We do not knowingly collect or solicit personally identifiable information from children under 13; if you are a child under 13, please do not attempt to register for the Services or send any personal information about yourself to us. If we learn we have collected personal information from a child under 13, we will delete that information as quickly as possible. If you believe that a child under 13 may have provided us personal information, please contact us at </span><span style={{fontWeight: 400}}>support@weboro.co</span><span style={{fontWeight: 400}}>.</span></p>
<h6><span style={{fontWeight: 'bold',fontSize:15,color:'black'}}>2. WHAT ARE THE BASICS OF USING WEBORO.CO (APPLICABLE TO ALL USERS)?</span></h6>
<p><span style={{fontWeight: 400}}>Registration and Security</span></p>
<p><span style={{fontWeight: 400}}>To use some aspects of the Services, you may be required to sign up for an account, and select a password and user name (&ldquo;WeBoro User ID&rdquo;). If you are accessing the Services through a third party site or service (such as &ldquo;Facebook&rdquo; or &ldquo;Google&rdquo;), we may require that your WeBoro User ID be the same as your user name for such third party site or service. You promise to provide us with accurate, complete, and updated registration information about yourself. You may not select as your WeBoro User ID a name that you don&rsquo;t have the right to use, or another person&rsquo;s name with the intent to impersonate that person. You may not transfer your account to anyone else without our prior written permission. You may only create one account with us. If your account has been suspended or terminated, you may not open another account with us. If you access the Service through a third party site or service, you will provide your third party account credentials to us, and you are consenting to have the information in those accounts transmitted into your WeBoro account, and you agree that you shall only use accounts owned by you, and not by any other person or entity.</span></p>
<p><span style={{fontWeight: 400}}>You represent and warrant that you are of legal age to form a binding contract (or if not, you&rsquo;ve received your parent&rsquo;s or guardian&rsquo;s permission to use the Services and gotten your parent or guardian to agree to these Terms on your behalf). If you&rsquo;re agreeing to these Terms on behalf of an organization or entity, you represent and warrant that you are authorized to agree to these Terms on that organization or entity&rsquo;s behalf and bind them to these Terms, in which case the references to &ldquo;you&rdquo; or &ldquo;your&rdquo; or the like other than in this sentence, refer to such organization or entity. If you are a parent, guardian, or other person who enables a child under the age of 13 to access the Services, you agree to stand in the shoes of such child for the purposes of making us whole in case of damages or indemnification that could properly lie against a child, if not for his or her age. The Services are intended for use by you only if you are 13 years of age or older.</span></p>
<p><span style={{fontWeight: 400}}>Permitted Use</span></p>
<p><span style={{fontWeight: 400}}>You will only use the Services for your own personal use (and not on behalf of or for the benefit of any third party), and only in a manner that complies with all laws, rules (government, school, or otherwise), and regulations (collectively &ldquo;Applicable Laws&rdquo;) that apply to you. You may not resell or make any commercial use of the Services or any Content (defined below) therein (other than your own Submissions (defined below), subject to the non-exclusive license you grant to us). Any Content you access through the Services is licensed, not sold to you, regardless of the use of the term &ldquo;purchase&rdquo; herein. You are prohibited from soliciting other users of the Services (hereafter &ldquo;Users&rdquo;) to contact you outside the Services, including Tutors, for any purpose. &ldquo;Content&rdquo; means the materials displayed or performed or available on or through the Services, including, but not limited to, text, graphics, data, articles, photos, images, illustrations, course materials, class notes, flash cards, Expert Answers, and so forth. &ldquo;Submissions&rdquo; means any Content you post, upload, share, store, or otherwise provide through the Services, whether as a Tutor or User.</span></p>
<p><span style={{fontWeight: 400}}>If your use of the Services is prohibited by Applicable Laws, then you aren&rsquo;t authorized to use the Services. We can&rsquo;t and won&rsquo;t be responsible for your using the Services in a way that breaks any Applicable Laws.</span></p>
<p><span style={{fontWeight: 400}}>We are not responsible for Content</span></p>
<p><span style={{fontWeight: 400}}>We are not responsible for editing, modifying, filtering, screening, monitoring, endorsing or guaranteeing any Content. We are not liable for any acts or omissions of Users, or the accuracy or completeness of any answers or another User&rsquo;s ability to provide answers. We cannot ensure that any Tutor or User will complete a transaction.</span></p>
<p><span style={{fontWeight: 400}}>Content is to be used for general information purposes only, not as a substitute for in-person evaluation or specific professional advice.  Communications facilitated by the Services do not create client-professional relationships and are not the subject of any associated privileges or confidentiality protections. Communications on this Services are limited and do not include safeguards and procedures typical of in-person evaluations and visits.</span></p>
<p><span style={{fontWeight: 400}}>Protection of Content</span></p>
<p><span style={{fontWeight: 400}}>The Content is protected by copyright and/or other intellectual property laws. You promise to abide by all copyright notices, trademark rules, information, and restrictions contained in any Content you access through the Services, and you won&rsquo;t use, copy, reproduce, modify, translate, publish, broadcast, transmit, distribute, perform, upload, display, license, sell or otherwise exploit for any purpose any Content not owned by you, (i) without the prior consent of the owner of that Content or (ii) in a way that violates someone else&rsquo;s (including WeBoro&rsquo;s) rights.</span></p>
<p><span style={{fontWeight: 400}}>You understand that WeBoro owns the Services. You won&rsquo;t modify, publish, transmit, participate in the transfer or sale of, reproduce (except as expressly provided in this Section), create derivative works based on, or otherwise exploit any of the Services.</span></p>
<p><span style={{fontWeight: 400}}>The Services may allow you to copy or download certain Content; please remember that just because this functionality exists, doesn&rsquo;t mean that all the restrictions above don&rsquo;t apply &ndash; they do!</span></p>
<p><span style={{fontWeight: 400}}>Connectivity Costs and Equipment</span></p>
<p><span style={{fontWeight: 400}}>You are responsible for all service, internet and/or other fees and costs associated with your access to and use of the Services, including, but not limited to, any data charges imposed by a wireless carrier or Internet service provider, and for obtaining and maintaining all equipment required for such access and use.</span></p>
<p><span style={{fontWeight: 400}}>Access to Tutors</span></p>
<p><span style={{fontWeight: 400}}>Access to Tutors: The Services may include, at our discretion, access to Tutors who teach online courses or provide instructional materials and/or tutoring. Tutors are not employees or agents of WeBoro. We solely facilitate the transaction between you and the Tutor. We are not involved in the conversation between you and Tutors and we do not refer you to or endorse or recommend particular Tutors. Emergency questions and crisis situations (particularly regarding medical or mental health issues) should be directed immediately by telephone or in-person to qualified professionals (e.g., in the U.S., call 911). The Services are not the appropriate venue to deal with such questions or situations. The laws, regulations, other governing authorities, standards, practices and procedures that apply to your particular question may differ depending on your location and information typically discovered through in-person evaluations or visits. For example, Tutors in some categories may be licensed, certified, educated, employed by or have experience in only particular jurisdictions.</span></p>
<p><span style={{fontWeight: 400}}>Tutor Answers: Your access to Tutors may include access to individuals or entities who prepare specific answers (&ldquo;Tutor Answers&rdquo;) to your submitted questions. Tutors choose whether to answer your question at their own discretion.</span></p>
<p><span style={{fontWeight: 400}}>What are the rights and obligations specific to my Submissions?</span></p>
<p><span style={{fontWeight: 400}}>We want you to know the following regarding your Submissions:</span></p>
<ol>
<li style={{fontWeight: 400}}><span style={{fontWeight: 400,marginleft:35}}> 	◘ Your Submissions are not private. Submissions are not private or confidential and may be read, collected, and used publically by others. To better protect your privacy, do not include information regarding your identity or contact information in Submissions that you post or upload to the Services.</span></li>
<li style={{fontWeight: 400}}><span style={{fontWeight: 400,marginleft:35}}> 	◘ You are responsible for your Submissions. You are fully responsible for all Submissions you contribute, in any manner, to the Services, and for the accuracy of the same, and you represent and warrant you have all rights necessary to do so, in the manner in which you contribute it, and that WeBoro shall not need to obtain any licenses, rights, consents, or permissions from, or make any payments to, any third party for any use or exploitation of your Submissions in any and all media, whether now known or hereafter created, or have any liability to you or any third party as a result of any use of your Submissions as authorized in these Terms. For more information, please see our Frequently Asked Questions and our Honor Code.</span></li>
<li style={{fontWeight: 400}}><span style={{fontWeight: 400,marginleft:35}}> 	◘ As between us and you, you are the owner of your Submissions. WeBoro does not claim any ownership rights in your Submissions. After submitting your Submissions to the Service, you continue to retain all ownership rights in such Submissions, subject to the licenses granted below, and you continue to have the right to use your Submissions in any way you choose.</span></li>
<li style={{fontWeight: 400}}><span style={{fontWeight: 400,marginleft:35}}> 	◘ Waiver of Personality Rights. You hereby waive any and all rights of privacy, publicity, or any other rights of a similar nature in connection with the use of the Submissions, or any portion thereof, as authorized by these Terms in connection with the Services, or of your name, personality, likeness, image or voice in connection with offering, delivering, marketing, promoting, demonstrating and selling your Submissions, or any advertising or publicity relating thereto.</span></li>
</ol>
<p><span style={{fontWeight: 400}}>Your use of the Services is subject to the following additional restrictions:</span></p>
<p><em><span style={{fontWeight: 400}}>You are prohibited from uploading or submitting copyright infringing materials</span></em><span style={{fontWeight: 400}}> (including without limitation copies made without the consent of the copyright owner of testing materials, textbooks, instructor&rsquo;s solution manuals, test banks, lecture notes, slide presentations, or related copyrighted works, in whole or in part).</span></p>
<p><span style={{fontWeight: 400}}>You represent, warrant, and agree that you will not contribute any Submission that, or otherwise use the Services or interact with the Services in a manner that:</span></p>
<ol>
<li style={{fontWeight: 400}}><span style={{fontWeight: 400,marginleft:35}}> 	◘ Infringes or violates the intellectual property rights or any other rights of anyone else (including WeBoro);</span></li>
<li style={{fontWeight: 400}}><span style={{fontWeight: 400,marginleft:35}}> 	◘ Violates any Applicable Law;</span></li>
<li style={{fontWeight: 400}}><span style={{fontWeight: 400,marginleft:35}}> 	◘ Is harmful, fraudulent, deceptive, threatening, harassing, defamatory, obscene, or otherwise objectionable;</span></li>
<li style={{fontWeight: 400}}><span style={{fontWeight: 400,marginleft:35}}> 	◘ Jeopardizes the security of your WeBoro account or anyone else&rsquo;s (such as allowing someone else to log on as you on the Services);</span></li>
<li style={{fontWeight: 400}}><span style={{fontWeight: 400,marginleft:35}}> 	◘ Attempts, in any manner, to obtain the password, account, or other security information from any other User;</span></li>
<li style={{fontWeight: 400}}><span style={{fontWeight: 400,marginleft:35}}> 	◘ Violates the security of any computer network, or cracks any passwords or security encryption codes;</span></li>
<li style={{fontWeight: 400}}><span style={{fontWeight: 400,marginleft:35}}> 	◘ Runs Maillist, Listserv, any form of auto-responder or &ldquo;spam&rdquo; on the Services, or any processes that run or are activated while you are not logged into the Services, or that otherwise interfere with the proper working of the Services (including by placing an unreasonable load on the Services&rsquo; infrastructure);</span></li>
<li style={{fontWeight: 400}}><span style={{fontWeight: 400,marginleft:35}}> 	◘ &ldquo;Crawls,&rdquo; &ldquo;scrapes,&rdquo; or &ldquo;spiders&rdquo; any page, data or portion of or relating to the Services or Content (through use of manual or automated means);</span></li>
<li style={{fontWeight: 400}}><span style={{fontWeight: 400,marginleft:35}}> 	◘ Introduces any viruses, Trojan horses, or other computer programming routines that may damage, interfere with, surreptitiously intercept or expropriate any part of the Services;</span></li>
<li style={{fontWeight: 400}}><span style={{fontWeight: 400,marginleft:35}}> 	◘ Copies or stores any significant portion of the Content; or</span></li>
<li style={{fontWeight: 400}}><span style={{fontWeight: 400,marginleft:35}}> 	◘ Decompiles, reverse engineers, or otherwise attempts to obtain the source code or underlying ideas or information of or relating to the Services.</span></li>
</ol>
<p><span style={{fontWeight: 400}}>A violation of any of the foregoing is grounds for termination of your right to use or access the Services.</span></p>
<p><span style={{fontWeight: 400}}>Do I have to grant any licenses to WeBoro or to other Users?</span></p>
<p><span style={{fontWeight: 400}}>In order to display, store, and provide Users with access to your Submissions on the Services, you grant us certain rights in those Submissions.</span></p>
<p><span style={{fontWeight: 400}}>You hereby grant WeBoro a non-exclusive license to translate, modify, store, edit, display, perform, reproduce, distribute, and otherwise act with respect to such Submissions, in each case to enable us to operate the Services, as described in more detail below. This license enables us to provide the Services and is not intended to otherwise limit your ownership rights in your Submissions.</span></p>
<p><span style={{fontWeight: 400}}>You also grant WeBoro a non-exclusive license to publicly translate, modify, store, edit, display, perform, reproduce, and distribute your Submissions for the purpose of making such Submissions accessible to all WeBoro Users and providing the Services necessary to do so, as well as all other rights necessary to use and exercise all rights in that Submission in connection with the Services and/or otherwise in connection with the WeBoro business for any purpose. The licenses granted to WeBoro also include the right to distribute your Submissions through multiple tiers of sublicensees and for such sublicensees to have all rights necessary for them to offer, deliver, market, promote, demonstrate and sell your Submissions to other Users.</span></p>
<p><span style={{fontWeight: 400}}>Also, you grant all other Users of the Services a license to access your Submissions, and to use and exercise all rights in it, as permitted by the functionality of the Service, including reproducing such Submissions on personal devices.</span></p>
<p><span style={{fontWeight: 400}}>You agree that the licenses you grant are royalty-free, perpetual, transferable, sublicensable, irrevocable, and worldwide. You understand and agree that if you delete your account, it may not be possible to completely delete that content from WeBoro&rsquo;s records, and that your Submissions will remain viewable elsewhere as part of the Services and to the extent that they were downloaded or stored by other Users.</span></p>
<p><span style={{fontWeight: 400}}>Finally, you understand and agree that WeBoro, in performing the required technical steps to make your Submission accessible through the Services, may need to modify and make changes to your Submissions and the foregoing licenses include the rights to do so.</span></p>
<p><span style={{fontWeight: 400}}>What if I see something on the Services that infringes my copyright?</span></p>
<p><span style={{fontWeight: 400}}>You may have heard of the Digital Millennium Copyright Act (the &ldquo;DMCA&rdquo;), as it relates to online service providers, like WeBoro, being asked to remove material that allegedly violates someone&rsquo;s copyright. We respect others&rsquo; intellectual property rights, and we reserve the right to delete or disable Content alleged to be infringing, and to terminate the accounts of repeat alleged infringers; to review our complete Copyright Dispute Policy and learn how to report potentially infringing content, click here. To learn more about the DMCA, click </span><a href="http://www.copyright.gov/legislation/dmca.pdf"><span style={{fontWeight: 400}}>here</span></a><span style={{fontWeight: 400}}>.</span></p>
<p><span style={{fontWeight: 400}}>Who is responsible for what I see and do on the Services?</span></p>
<p><span style={{fontWeight: 400}}>Any Content publicly posted or privately transmitted through the Services is the sole responsibility of the person from whom such content originated, and you access all such information and content at your own risk, and we aren&rsquo;t liable for any errors or omissions in that information or content or for any damages or loss you might suffer in connection with it. We cannot control and have no duty to take any action regarding how you may interpret and use the Content or what actions you may take as a result of having been exposed to the Content, and you hereby release us from all liability for you having acquired or not acquired Content through the Services. We can&rsquo;t guarantee the identity of any Users with whom you interact in using the Services and are not responsible for which Users gain access to the Services.</span></p>
<p><span style={{fontWeight: 400}}>The Services may contain links or connections to third party websites or services that are not owned or controlled by WeBoro. When you access third party websites or use third party services, you accept that there are risks in doing so, and that WeBoro is not responsible for such risks. We encourage you to be aware when you leave the Services and to read the terms and conditions and privacy policy of each third party website or service that you visit or utilize.</span></p>
<p><span style={{fontWeight: 400}}>WeBoro has no control over, and assumes no responsibility for, the content, accuracy, privacy policies, or practices of or opinions expressed in any third party websites or by any third party that you interact with through the Services. In addition, WeBoro will not and cannot monitor, verify, censor or edit the content of any third party site or service. By using the Services, you release and hold us harmless from any and all liability arising from your use of any third party website or service.</span></p>
<p><span style={{fontWeight: 400}}>Your interactions with organizations and/or individuals found on or through the Services, including payment and delivery of goods or services, and any other terms, conditions, warranties or representations associated with such dealings, are solely between you and such organizations and/or individuals. You should make whatever investigation you feel necessary or appropriate before proceeding with any online or offline transaction with any of these third parties. You agree that WeBoro shall not be responsible or liable for any loss or damage of any sort incurred as the result of any such dealings.</span></p>
<p><span style={{fontWeight: 400}}>If there is a dispute between Users on this site, or between Users and any third party, you agree that WeBoro is under no obligation to become involved. In the event that you have a dispute with one or more other Users, you release WeBoro, its officers, employees, agents, and successors from claims, demands, and damages of every kind or nature, known or unknown, suspected or unsuspected, disclosed or undisclosed, arising out of or in any way related to such disputes and/or our Services. If you are a California resident, you shall and hereby do waive California Civil Code Section 1542, which says: "A general release does not extend to claims which the creditor does not know or suspect to exist in his or her favor at the time of executing the release, which if known by him or her must have materially affected his or her settlement with the debtor."</span></p>
<h6><span style={{fontWeight: 'bold',fontSize:15,color:'black'}}>3. WHAT ARE THE RIGHTS AND OBLIGATIONS SPECIFIC TO INSTRUCTORS?</span></h6>
<p><span style={{fontWeight: 400}}>In addition to the above obligations applicable to all Users, you agree to the below terms if you sign up as a Tutor through the Services.</span></p>
<ul>
<li style={{fontWeight: 400}}><span style={{fontWeight: 400,marginleft:35}}> 	◘ You have read and agree to our pricing information and policy, which may be updated from time to time upon prior written notice to you.</span></li>
<li style={{fontWeight: 400}}><span style={{fontWeight: 400,marginleft:35}}> 	◘ You have the required qualifications, credentials and expertise to teach and offer the courses, instruction and materials you sign up to provide, and that any credentials you post are accurate, truthful, complete, and not misleading;</span></li>
<li style={{fontWeight: 400}}><span style={{fontWeight: 400,marginleft:35}}> 	◘ You will not use the Services for any business other than for providing tutoring, teaching and instructional services to registered Users;</span></li>
<li style={{fontWeight: 400}}><span style={{fontWeight: 400,marginleft:35}}> 	◘ You will not engage in any activity that will require WeBoro to obtain any licenses from or pay any royalties to any third party, including, by way of example and not limitation, the payment of royalties for the public performance of any musical works or sound recordings;</span></li>
<li style={{fontWeight: 400}}><span style={{fontWeight: 400,marginleft:35}}> 	◘ You will not interfere with or otherwise prevent other Tutors from providing their services or courses;</span></li>
<li style={{fontWeight: 400}}><span style={{fontWeight: 400,marginleft:35}}> 	◘ You will keep your Submissions up-to-date, and respond to Users seeking your services in a timely fashion, so as to ensure quality of service to Users;</span></li>
<li style={{fontWeight: 400}}><span style={{fontWeight: 400,marginleft:35}}> 	◘ If you are under the age of 18, but are qualified to be a Tutor, you will obtain parental or legal guardian permission as and when needed, and before registering with the Services; and</span></li>
<li style={{fontWeight: 400}}><span style={{fontWeight: 400,marginleft:35}}> 	◘ If you are in a jurisdiction where any form of instructing requires a license, credential, certification or other form of governmental or third party license, consent, authorization or permission, you shall not use the Services as a Tutor until such license, consent, authorization or permission has been obtained.</span></li>
</ul>
<p><span style={{fontWeight: 400}}>Please also see our Frequently Asked Questions for more information on how to sign up as a Tutor and for guidelines on submitting Expert Answers and other Tutor materials. We recommend that you read those.</span></p>
<h6><span style={{fontWeight: 'bold',fontSize:15,color:'black'}}>4. OTHER IMPORTANT TERMS</span></h6>
<p><span style={{fontWeight: 400}}>Will WeBoro ever change the Services?</span></p>
<p><span style={{fontWeight: 400}}>We&rsquo;re always trying to improve the Services, so they may change over time. We may suspend or discontinue any part of the Services, or we may introduce new features or impose limits on certain features or restrict access to parts or all of the Services. We&rsquo;ll try to give you notice when we make a material change to the Services that would adversely affect you, but this isn&rsquo;t always practical. Similarly, we reserve the right to remove any Content from the Services at any time, for any reason (including, but not limited to, if someone alleges you contributed that Content in violation of these Terms), in our sole discretion, and without notice.</span></p>
<p><span style={{fontWeight: 400}}>Does the WeBoro Service cost anything?</span></p>
<p><span style={{fontWeight: 400}}>WeBoro offers a free access as well as a paid access to the Services. Each and every paid subscription package requires an upfront and immediate charge to your primary payment method depending on the subscription term that you elect to purchase. For more details on pricing, payment, refunds and what is included in the different levels of access, please see our Frequently Asked Questions.</span></p>
<p><span style={{fontWeight: 400}}>What if I want to stop using WeBoro?</span></p>
<p><span style={{fontWeight: 400}}>You&rsquo;re free to do that at any time, by contacting us at </span><span style={{fontWeight: 400}}>support@weboro.co</span><span style={{fontWeight: 400}}>; please refer to our Privacy Policy, as well as the licenses above, to understand how we treat information you provide to us after you have stopped using our Services.</span></p>
<p><span style={{fontWeight: 400}}>You can cancel any ongoing billing through your Account Settings section of the User Dashboard of the Service as further detailed in the FAQ. For any questions, please email us at </span><span style={{fontWeight: 400}}>billing@weboro.co</span><span style={{fontWeight: 400}}>. Cancellations will be effective within 7 business days. All debts and fees must be paid before cancellation can take effect. Termination of your account does not affect your liability or obligations under the Terms.</span></p>
<p><span style={{fontWeight: 400}}>WeBoro is also free to terminate (or suspend access to) your use of the Services or your account, for any reason in our discretion, including your breach of these Terms. WeBoro has the sole right to decide whether you are in violation of any of the restrictions set forth in these Terms.</span></p>
<p><span style={{fontWeight: 400}}>Account termination may result in destruction of any Content associated with your account, so keep that in mind before you decide to terminate your account.</span></p>
<p><span style={{fontWeight: 400}}>If you have deleted your account by mistake, contact us immediately at </span><span style={{fontWeight: 400}}>support@weboro.co</span><span style={{fontWeight: 400}}>&ndash;we will try to help, but unfortunately, we can&rsquo;t promise that we can recover or restore anything.</span></p>
<p><span style={{fontWeight: 400}}>No Refunds</span></p>
<p><span style={{fontWeight: 400}}>All payments are final and no refunds will be issued except for those refunds issued under WeBoro&rsquo;s Better Grades Guarantee. Additionally, unused Tutor Questions expire upon the expiration or cancellation of the applicable subscription package and cannot be redeemed for cash. For any billing related questions in regards to payment, please contact Customer Service at </span><span style={{fontWeight: 400}}>billing@weboro.co</span><span style={{fontWeight: 400}}>.</span></p>
<p><span style={{fontWeight: 400}}>What else do I need to know?</span></p>
<p><span style={{fontWeight: 400}}>Survival of Terms. Provisions that, by their nature, should survive termination of these Terms shall survive termination. By way of example, all of the following will survive termination: any obligation you have to pay us or indemnify us, any limitations on our liability, any terms regarding ownership of intellectual property rights, and terms regarding disputes between us.</span></p>
<p><span style={{fontWeight: 400}}>General Disclaimer. The Services provide a marketplace of Users and Tutors. We don&rsquo;t hire or background check Tutors, we aren&rsquo;t responsible for reviewing Content for accuracy, reliability or appropriateness, nor are we responsible or liable for any interactions between Users and Tutors. We are not responsible for disputes, claims, losses, injuries, or damage of any kind that might arise out of or relate to conduct of Users or Tutors, including, but not limited to, any User&rsquo;s reliance upon any information provided by a Tutor. You understand that by using the Services you may be exposed to Content that is offensive, indecent, or objectionable. You hereby agree to hold WeBoro harmless from and against any and all claims that you may have arising out of your access or use of any Content.</span></p>
<p><span style={{fontWeight: 400}}>I use the WeBoro App available via the Apple App Store &ndash; should I know anything about that?</span></p>
<p><span style={{fontWeight: 400}}>These Terms apply to your use of the Services, including the iOS applications available via the Apple, Inc. (&ldquo;Apple&rdquo;) App Store (the &ldquo;Application&rdquo;), but the following additional terms also apply to the Application:</span></p>
<ol>
<li style={{fontWeight: 400}}><span style={{fontWeight: 400,marginleft:35}}> 	◘ Both you and WeBoro acknowledge that the Terms are concluded between you and WeBoro only, and not with Apple, and that Apple is not responsible for the Application or the Content;</span></li>
<li style={{fontWeight: 400}}><span style={{fontWeight: 400,marginleft:35}}> 	◘ The Application is licensed to you on a limited, non-exclusive, non-transferrable, non-sublicensable basis, solely to be used in connection with the Services for your private, personal, non-commercial use, subject to all the terms and conditions of these Terms as they are applicable to the Services;</span></li>
<li style={{fontWeight: 400}}><span style={{fontWeight: 400,marginleft:35}}> 	◘ You will only use the Application in connection with an Apple device that you own or control;</span></li>
<li style={{fontWeight: 400}}><span style={{fontWeight: 400,marginleft:35}}> 	◘ You acknowledge and agree that Apple has no obligation whatsoever to furnish any maintenance and support services with respect to the Application;</span></li>
<li style={{fontWeight: 400}}><span style={{fontWeight: 400,marginleft:35}}> 	◘ In the event of any failure of the Application to conform to any applicable warranty, including those implied by law, you may notify Apple of such failure; upon notification, Apple&rsquo;s sole warranty obligation to you will be to refund to you the purchase price, if any, of the Application;</span></li>
<li style={{fontWeight: 400}}><span style={{fontWeight: 400,marginleft:35}}> 	◘ You acknowledge and agree that WeBoro, and not Apple, is responsible for addressing any claims you or any third party may have in relation to the Application;</span></li>
<li style={{fontWeight: 400}}><span style={{fontWeight: 400,marginleft:35}}> 	◘ You acknowledge and agree that, in the event of any third party claim that the Application or your possession and use of the Application infringes that third party&rsquo;s intellectual property rights, WeBoro, and not Apple, will be responsible for the investigation, defense, settlement and discharge of any such infringement claim;</span></li>
<li style={{fontWeight: 400}}><span style={{fontWeight: 400,marginleft:35}}> 	◘ You represent and warrant that you are not located in a country subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a &ldquo;terrorist supporting&rdquo; country, and that you are not listed on any U.S. Government list of prohibited or restricted parties;</span></li>
<li style={{fontWeight: 400}}><span style={{fontWeight: 400,marginleft:35}}> 	◘ Both you and WeBoro acknowledge and agree that, in your use of the Application, you will comply with any applicable third party terms of agreement which may affect or be affected by such use; and</span></li>
<li style={{fontWeight: 400}}><span style={{fontWeight: 400,marginleft:35}}> 	◘ Both you and WeBoro acknowledge and agree that Apple and Apple&rsquo;s subsidiaries are third party beneficiaries of these Terms, and that upon your acceptance of these Terms, Apple will have the right (and will be deemed to have accepted the right) to enforce these Terms against you as the third party beneficiary hereof.</span></li>
</ol>
<p><span style={{fontWeight: 400}}>Warranty Disclaimer. Neither WeBoro nor any of its licensors or suppliers makes any representations or warranties concerning any content contained in or accessed through the Services, and we will not be responsible or liable for the accuracy, copyright compliance, legality, or decency of material contained in or accessed through the Services. We (and our licensors and suppliers) make no representations or warranties regarding suggestions or recommendations of services or products offered or purchased through the Services. Any Content or Services purchased or offered (whether or not following such recommendations and suggestions) through the Services are provided &ldquo;AS IS&rdquo; and without any warranty of any kind from WeBoro or others. THE SERVICES AND CONTENT ARE PROVIDED ON AN &ldquo;AS-IS&rdquo; BASIS, WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, OR THAT USE OF THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE. SOME STATES DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.</span></p>
<p><span style={{fontWeight: 400}}>Limitation of Liability. TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY (INCLUDING, WITHOUT LIMITATION, TORT, CONTRACT, STRICT LIABILITY, OR OTHERWISE) SHALL WeBoro (OR ITS LICENSORS OR SUPPLIERS) BE LIABLE TO YOU OR TO ANY OTHER PERSON FOR (A) ANY INDIRECT, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES OF ANY KIND, INCLUDING DAMAGES FOR LOST PROFITS, LOSS OF GOODWILL, WORK STOPPAGE, ACCURACY OF RESULTS, OR COMPUTER FAILURE OR MALFUNCTION, OR (B) ANY AMOUNT, IN THE AGGREGATE, IN EXCESS OF THE GREATER OF (I) $100 OR (II) THE AMOUNTS PAID BY YOU TO WeBoro IN CONNECTION WITH THE SERVICES IN THE TWELVE (12) MONTH PERIOD PRECEDING THIS APPLICABLE CLAIM, OR (C) ANY MATTER BEYOND OUR REASONABLE CONTROL. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES, SO THE ABOVE LIMITATION AND EXCLUSIONS MAY NOT APPLY TO YOU.</span></p>
<p><span style={{fontWeight: 400}}>Indemnity. You agree to indemnify and hold WeBoro, its affiliates, officers, agents, employees, and partners harmless from and against any and all claims, liabilities, damages (actual and consequential), losses and expenses (including attorneys&rsquo; fees) arising from or in any way related to any third party claims relating to (a) your use of the Services (including any actions taken by a third party using your account), and (b) your violation of these Terms. In the event of such a claim, suit, or action (&ldquo;Claim&rdquo;), if you have an account, we will attempt to provide notice of the Claim to the contact information we have for your account (provided that failure to deliver such notice shall not eliminate or reduce your indemnification obligations hereunder).</span></p>
<p><span style={{fontWeight: 400}}>Assignment. You may not assign, delegate or transfer these Terms or your rights or obligations hereunder, or your Services account, in any way (by operation of law or otherwise) without WeBoro&rsquo;s prior written consent. We may transfer, assign, or delegate these Terms and our rights and obligations without consent.</span></p>
<p><span style={{fontWeight: 400}}>Choice of Law; Arbitration. These Terms are governed by and will be construed under the laws of the State of Massachusetts, without regard to the conflicts of laws provisions thereof. Any dispute arising from or relating to the subject matter of these Terms (whether brought against WeBoro or against any director, officer or employee of WeBoro in their personal capacity) shall be finally settled in Boston, Massachusetts, in English, in accordance with the Streamlined Arbitration Rules and Procedures of Judicial Arbitration and Mediation Services, Inc. ("JAMS") then in effect, by one commercial arbitrator with substantial experience in resolving intellectual property and commercial contract disputes, who shall be selected from the appropriate list of JAMS arbitrators in accordance with such Rules. Judgment upon the award rendered by such arbitrator may be entered in any court of competent jurisdiction. ANY ARBITRATION UNDER THESE TERMS WILL TAKE PLACE ON AN INDIVIDUAL BASIS: CLASS ARBITRATIONS AND CLASS ACTIONS ARE NOT PERMITTED. YOU UNDERSTAND AND AGREE THAT BY ENTERING INTO THESE TERMS, YOU AND WeBoro ARE EACH WAIVING THE RIGHT TO TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION. Notwithstanding the foregoing obligation to arbitrate disputes, each party shall have the right to pursue injunctive or other equitable relief at any time, from any court of competent jurisdiction. For all purposes of these Terms, the parties consent to exclusive jurisdiction and venue in the state or federal courts located in, respectively, Boston, Massachusetts.</span></p>
<p><span style={{fontWeight: 400}}>Miscellaneous. You will be responsible for paying, withholding, filing, and reporting all taxes, duties, and other governmental assessments associated with your activity in connection with the Services, provided that WeBoro may, in its sole discretion, do any of the foregoing on your behalf or for itself as it sees fit. The failure of either you or us to exercise, in any way, any right herein shall not be deemed a waiver of any further rights hereunder. If any provision of these Terms is found to be unenforceable or invalid, that provision will be limited or eliminated, to the minimum extent necessary, so that these Terms shall otherwise remain in full force and effect and enforceable. You and WeBoro agree that these Terms are the complete and exclusive statement of the mutual understanding between you and WeBoro, and that it supersedes and cancels all previous written and oral agreements, communications and other understandings relating to the subject matter of these Terms, and that all modifications to these Terms must be in a writing signed by both parties (except as otherwise provided herein). You hereby acknowledge and agree that you are not an employee, agent, partner or joint venture of WeBoro, and you do not have any authority of any kind to bind WeBoro in any respect whatsoever. Except as expressly set forth in the section above regarding the Apple Application, you and WeBoro agree there are no third party beneficiaries intended under these Terms.</span></p>
<p>&nbsp;</p>
          </div>
          {/* privacy contact  */}
          <FooterSection />
        </div>
        
      </React.Fragment>
    );
  }
}

export default Theme;
