import * as firebase from 'firebase/app';
import React from "react";
import firebaseConfig from '../HeroSection/firebaseConfig';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/firebase-database'
import 'firebase/firebase-storage'
import './util/style.css'
import Dashboard from './index'
class Admin extends React.Component {
  constructor(props){
    super(props);
    this.state = {
     display : <div className="body">
     <br/><br/><br/><br/>
     <center><h1>Admin Access Forbidden </h1></center>

</div>
  }
}
componentDidMount() {
  var actual = '';
    if(localStorage.getItem('adminloggedin') !== 'on')
      {
       
        var that = this;
        firebase.database().ref('Password/pass').once('value').then(function(snapshot) {
          
          if(snapshot && snapshot.exists())
          {
              
            const input = window.prompt("Enter Admin Password : ");
            console.log(snapshot.val())
               if(input === snapshot.val())
                {
                    console.log("Success")
                    localStorage.setItem('adminloggedin', 'on');
                    that.setState({display:<Dashboard />})
                }
          }
          
           
         })

        
      }
      if(localStorage.getItem('adminloggedin') === 'on')
      {
        this.setState({display:<Dashboard />})
    
      }
   
}
  
  render(){
  return(<div>{this.state.display}</div>)
  }
}

export default Admin;