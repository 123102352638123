import * as firebase from 'firebase/app';
import React from "react";
import firebaseConfig from '../../HeroSection/firebaseConfig';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/firebase-database'
import 'firebase/firebase-storage'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import DataTable from 'react-data-table-component';
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import Button from 'react-bootstrap/Button'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import ReactSnackBar from "react-js-snackbar";
import RiseLoader from "react-spinners/RiseLoader";
import Badge from 'react-bootstrap/Badge'
import PAYMENT_SERVER_URL from '../../../constants/server'

const columns = [
  {
    name: 'User Name',
    selector: 'username',
    sortable: false,
    
  },{
    name: 'Email',
    selector: 'email',
    sortable: false,
    
    //right: true,
  },
  {
    name: 'Payment Status',
    selector: 'payment',
    sortable: true,
    //right: true,
  },
  {
    name: 'Date',
    selector: 'date',
    sortable: true,
    //right: true,
  },
  {
    name: 'Type',
    selector: 'homeworktype',
    sortable: true,
    //right: true,
  },
  {
    name: 'Level',
    selector: 'educationlevel',
    sortable: true,
    //right: true,
  },
  {
    name: 'Time',
    selector: 'time',
    sortable: true,
    //right: true,
  },
  {
    name: 'Total Price',
    selector: 'price',
    sortable: true,
    //right: true,
  },
  {
    name: 'Status',
    selector: 'status',
    cell: row => <b><Badge variant={row.status === "Completed" ? "success" : row.status === "Processing" ?  "warning" : "danger"}>{row.status}</Badge></b>,
    sortable: true,
    //right: true,
  },
];

class Dashboard extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            title:'Dashboard',
            loading:true,
            Show: false,
            Showing: false,
            notificationMessage:'',
            status:false,
            userDocuments:'',
           data:[],
           finalTable:<div className="sweet-loading" style={{marginTop:'100px',width:'80%',marginLeft:'auto',marginRight:'auto'}}>
           <center><RiseLoader
             
             size={15}
             color={"#8d2ad7"}
             margin={2}
             loading={true}
           /></center>
         </div>
        }
    }
    deleteOrder = (id) => {
      console.log("Deleting : " + id );
      if(window.confirm("Are you Sure you Want to Delete ? Click Ok to Delete")){
        this.setState({notificationMessage:'Order Deleted Successfully'})
        var x = firebase.database().ref(`CustomOrders`).child(id);
      
        x.remove().then(() => {
          if (this.state.Showing) return;
    
          this.setState({ Show: true, Showing: true });
          setTimeout(() => {
            this.setState({ Show: false, Showing: false });
          }, 2000);
          this.loadData()
        })
      }
      

     
      
  }
     markStatus = (id,type,email) => {
        console.log("Changing Status : " + id + type + email);
       
        var x = firebase.database().ref(`CustomOrders`).child(id);
        if(type === 'Pending')
        {
            this.setState({notificationMessage:'Marked Order Status as Pending'})
            
        }
        if(type === 'Processing')
        {
            this.setState({notificationMessage:'Marked Order Status as Processing'})
        }
        if(type === 'Completed')
        {
            this.setState({notificationMessage:'Marked Order Status as Completed'})
        }
        x.update({status:type}).then(() => {
          if (this.state.Showing) return;
    
          this.setState({ Show: true, Showing: true });
          setTimeout(() => {
            this.setState({ Show: false, Showing: false });
          }, 2000);
          this.loadData()
        })

       this.sendEmail(type,email)
        
    }
    sendEmail = (type,email) => {
      var requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ to:email, subject: 'Order Status Updated as '+ type + ' - Weboro.co' ,message:'<center><h2><u>Your order has been marked as : '+type+'</u></h2></center>' })
      };
  
  
    fetch(PAYMENT_SERVER_URL+'/sendEmail', requestOptions)
          .then(response => response.json())
          .then(data => console.log(data));

    }
    loadData = () =>{
      this.setState({finalTable:<div className="sweet-loading" style={{marginTop:'100px',width:'80%',marginLeft:'auto',marginRight:'auto'}}>
      <center><RiseLoader
        
        size={15}
        color={"#8d2ad7"}
        margin={2}
        loading={true}
      /></center>
    </div>})
    
      const ExpanableComponent = ({ data }) => <div className="row col-lg-12" style={{paddingTop:15}}>
  
  <table class="table table-bordered table-sm" style={{marginLeft:'10px'}}>
  
  <tbody>
    <tr>
      <td scope="row"><b>Name :  </b></td>
      <td><b>{data.username}</b></td>
      <td scope="row"><b>Homework Type :  </b></td>
      <td><b>{data.homeworktype}</b></td>
      
    </tr>
    <tr>
        <td><b>Payment Status : </b></td>
      <td><b>{data.payment}</b></td>
      <td scope="row"><b>Education Level :  </b></td>
      <td><b>{data.educationlevel}</b></td>
      
    </tr>
    <tr>
    <td><b>Email : </b></td>
      <td><b>{data.email}</b></td>
      <td scope="row"><b>Order Time :  </b></td>
      <td><b>{data.time}</b></td>
      
    </tr>
    <tr>
    <td><b>Date & Time  : </b></td>
      <td><b>{data.date}</b></td>
      <td scope="row"><b>Order Status :  </b></td>
      <td><b><Badge variant={data.status === "Completed" ? "success" : data.status === "Processing" ?  "warning" : "danger"}>{data.status}</Badge></b></td>
      
    </tr>
    <tr>
    <td><b>Order Price : </b></td>
      <td><b>{data.price}</b></td>
      <td><b>Action : </b></td>
      <td>
      
      <ButtonGroup className="mr-2" size="sm" aria-label="Second group">
      <Button onClick={() => this.markStatus(data.docid,'Pending',data.email)} className="btn btn-default" style={{backgroundColor:'#8D2AD7',color:'white',borderColor:'white'}}> <span className="ti-flag-alt"></span> Mark as Pending</Button>
         <Button onClick={() => this.markStatus(data.docid,'Processing',data.email)} className="btn btn-default" style={{backgroundColor:'#8D2AD7',color:'white',borderColor:'white'}}><span className="ti-alarm-clock"></span> Mark as Processing</Button>
          <Button onClick={() => this.markStatus(data.docid,'Completed',data.email)} className="btn btn-default" style={{backgroundColor:'#8D2AD7',color:'white',borderColor:'white'}}><span className="ti-check-box"></span> Mark as Completed</Button>
      
      </ButtonGroup>
      <Button onClick={() => this.deleteOrder(data.docid)}  className="btn btn-danger btn-sm " style={{borderColor:'white'}}><span class="ti-trash"></span> Delete Order</Button>
      </td>
      
    </tr>
    {data.fileStatus ? <tr><td><b>File Attached</b></td> <td><a href={data.fileUrl} target="_blank"  className="btn btn-info btn-sm " style={{borderColor:'white'}}><span class="ti-eye"></span> Open File</a>
      </td></tr>:''}
  </tbody>
</table>
        
        
      
      
        
        
        </div>;
      var that = this;
      var user = firebase.auth().currentUser;
      firebase.database().ref('CustomOrders').once('value').then(function(snapshot) {
       // console.log(snapshot.val())
        that.setState({userDocuments:snapshot.val()})
       // console.log(that.state.userDocuments)
        if(snapshot && snapshot.exists())
        {
          var response = snapshot.val()
          //console.log(response)
          //data = [];
         that.setState({data:[]})
          var temp = {};
          Object.keys(snapshot.val()).map((current,index) =>(
            that.state.data.push({id:index + 4,username:response[current].userName,
              email:response[current].userEmail,
              payment:response[current].payment,
              homeworktype:response[current].ordertype,
              educationlevel:response[current].educationLevel,
              time:response[current].orderTime,
              price:'$ ' + response[current].orderPrice,
              status:response[current].status,
              date:response[current].date,
              docid:current,
              fileStatus:response[current].fileStatus ? true : false,
              fileUrl:response[current].fileStatus ? response[current].fileUrl : '',
            
            })

        ), 
        
        that.setState({status:true,loading:false})
        
        )
        }
       
        
      }).then(() => this.setState({finalTable: <DataTable
        expandableRows
       
        
        progressPending={this.state.loading}
       expandableRowsComponent={<ExpanableComponent/>}
        striped
        highlightOnHover
        columns={columns}
        data={this.state.data}
      />}))
      
    }
    componentDidMount() {
      this.loadData()
    }
    render()
    {
      var newData = [...this.state.data]
      var showTable = '';
      if(this.state.status){
        const ExpanableComponent = ({ data }) => <div className="row col-lg-12" style={{pddingTop:15}}>
    
        <div className="col-lg-6" style={{float:'left'}}>
        
      
      <ButtonGroup className="mr-2" aria-label="Second group">
        
          <DropdownButton as={ButtonGroup} title="Dropdown" id="bg-nested-dropdown">
    <Dropdown.Item onClick={() => this.markStatus(1,'pending')} eventKey="1">Dropdown link</Dropdown.Item>
    <Dropdown.Item onClick={() => this.markStatus(1,'pending')} eventKey="2">Dropdown link</Dropdown.Item>
    <Dropdown.Item onClick={() => this.markStatus(1,'pending')}  eventKey="2">Dropdown link</Dropdown.Item>
  </DropdownButton>
      
      </ButtonGroup>
      
      </div>
      <div className="col-lg-6" style={{float:'right'}}>
      <ButtonToolbar aria-label="Toolbar with button groups" style={{margin:10}}>
      <ButtonGroup aria-label="Third group">
        <Button className="btn btn-danger " style={{borderColor:'white'}}><span class="ti-trash"></span> Delete Order</Button>
      </ButtonGroup>
      </ButtonToolbar>
      </div>
        
        
        </div>;
        
      }
      
        return(<div>
         <center><button style={{fontWeight:'bold',color:'white',backgroundColor:'#8D2AD7'}} className="btn" onClick={() => this.loadData()}>
            <span class="ti-reload" ></span> Refresh</button></center>
        <div className="col-lg-12">{this.state.finalTable}</div>
      <ReactSnackBar Icon={<span class="ti-face-smile"></span>} Show={this.state.Show}>
         {this.state.notificationMessage}
        </ReactSnackBar>
        </div>)
    }
}

export default Dashboard;