import * as firebase from 'firebase/app';
import React from "react";
import './button.css'
import firebaseConfig from '../HeroSection/firebaseConfig';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/firebase-database'
import axios from 'axios';
import StripeCheckout from 'react-stripe-checkout';
import STRIPE_PUBLISHABLE from '../../constants/stripe';
import PAYMENT_SERVER_URL from '../../constants/server';
import Modal from 'react-bootstrap/Modal'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Pri from '../Pricing/Price3'
class Download extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      price: 0.00,
      openSub1:false,
      texttype:'',
        texttime:'',
        textlevel:'',
      type:"0",
      level:"0",
      openPurchase:false,
      time:"0",
      userId:"",
      userName:"",
      userEmail:"",
      userPhone:"",
      currentEmail:'',
      fileSelected:false,
      fileToUpload:'',
      status:'Paid',
      display:<a
      href="/login1"
      className={
        "btn mb-3 btn-block solid-btn custom" 
      }
      target="_blank"
    >
      <span className="ti-check-box"> </span>
       Order Now
    </a>
    };
    
var status = "Paid"

  }

  handleUploadFile = (event) => {
    let selectedFile = event.target.files;
   
    //Check File is not Empty
    if (selectedFile.length > 0) {
        // Select the very first file from list
        let fileToLoad = selectedFile[0];
        this.setState({
          fileToUpload: fileToLoad,
          fileSelected:true
         
        })
    }

 
}

  checklogin =() =>
  {
    var that = this;
    var user = firebase.auth().currentUser;
    firebase.auth().onAuthStateChanged(function(user) {    
      if(user) {
              
            //alert("Is Signed In")
           that.setState({ display:<center><button
           
            onClick={() =>that.state.price !== 0 ? that.setState({openPurchase:true}) : alert("Please Select The Options ")}
            className={
              "btn mb-3 btn-block solid-btn custom " 
            }
            target="_blank"
          >
            <span className="ti-check-box"> </span>
             Order Now
          </button></center>,userEmail:user.email,userName:user.displayName,userId:user.uid,currentEmail:user.email})
          console.log(user.displayName)
          console.log(user.uid)
          } 
        });
  }
  
  submitOrder = () =>{
      console.log("Submitting order")
      var user = firebase.auth().currentUser;
      var that = this;
      

      var postData = {
       
      }
      var x = firebase.database().ref("CustomOrders").push();
      x.set({ userName: this.state.userName,
        userEmail: this.state.userEmail,
        userId: this.state.userId,
        userName:this.state.userName,
        orderPrice:this.state.price,
        educationLevel:this.state.textlevel,
        orderTime:this.state.texttime,
        ordertype:this.state.texttype,
        status:'Pending',
        fileStatus:this.state.fileSelected,
        fileUrl:'',
        payment:this.state.status,
        date: new Date().toLocaleString()
       


    });
        if(this.state.fileSelected){
          const uploadTask = firebase.storage().ref("HomeworkFiles/" + user.uid).child(x.key).put(this.state.fileToUpload)
    
          uploadTask.then(uploadTaskSnapshot => {
            console.log("download")
            var urld = uploadTaskSnapshot.ref.getDownloadURL().then( url =>{
              console.log(url)
             x.update({fileUrl:url})
              
            })
          
    
          })
        }
        this.setState({openPurchase:false})
      alert("Order Confirmed , Our Team Will Contact you Shortly via Email/Phone")
      //console.log(newPostKey)
      /*firebase.database().ref('CustomOrders/' + this.state.userId).set({
       userName: this.state.userName,
       userEmail: this.state.userEmail,
       userId: this.state.userId,
       userPhone:this.state.userPhone
      }, function(error) {
        if (error) {
          console.log(error)
        } else {
          // Data saved successfully!
          console.log("Data Saved Successfully")
        }
      });*/

      this.sendEmail()
  }

  sendEmail = () => {
    var requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ to:'homeworkhelper@weboro.co', subject: 'New Homework Order - '+this.state.userName ,message:'<center><h2><u>Order Details</u></h2></center><hr/><h3>User Name : '+this.state.userName+'</h3><h3>Order Price : $'+this.state.price+'</h3><h3>Email Address : '+this.state.userEmail+'</h3><h3>Payment Status : '+this.state.status+'</h3><h3>Order Time : '+this.state.texttime+'</h3><h3>Education Level : '+this.state.textlevel+'</h3><h3>Type : '+this.state.texttype+'</h3>' })
    };


  fetch(PAYMENT_SERVER_URL+'/sendEmail', requestOptions)
        .then(response => response.json())
        .then(data => console.log(data));


       requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ to:this.state.userEmail, subject: 'Homework Helper Order Recieved - WeBoro.co - ' ,message:'<center><h2>Welcome to WeBoro</h2></center><hr/><h4>Dear '+this.state.userName+',<br/><br/> We Have recieved your order and will contact you soon .</h4>' })
        };
    
    
      fetch(PAYMENT_SERVER_URL+'/sendEmail', requestOptions)
            .then(response => response.json())
            .then(data => console.log(data));
  }
  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
    this.checklogin()
    this.setState({
      price: 0
    });
    
  }
  handleFormValueChange(inputName, event) {
      console.log(event.target.type)
if(event.target.type === "select-one")
{
   var  name = 'text'+inputName;
   console.log(name)
    let stateValue = {};
    stateValue[name] =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.selectedOptions[0].text;
    this.setState(stateValue);
    console.log("Selected value text" + event.target.selectedOptions[0].text)
}
    
    let stateValue = {};
    stateValue[inputName] =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    this.setState(stateValue,()=>  {

        
     
      console.log(this.state)
      if(this.state.type !=="0" && this.state.level !== "0" && this.state.time !=="0" )
      {
        //all values selected , perform calculations now 

        //academic papers
          if(this.state.type === "1")
          {
            console.log("Academic papers");

          
            if(this.state.level === "1")
            {
                //high school 

                if(this.state.time === "1")
                {
                    // 3 hours acedemic paper high school
                    console.log("3 hours high school academic paper")
                    this.setState({price:38})
                }
                if(this.state.time === "2")
                {
                    // 8 hours acedemic paper high school
                    this.setState({price:25})
                }
                if(this.state.time === "3")
                {
                    // 24 hours acedemic paper high school
                    this.setState({price:21})
                }
                if(this.state.time === "4")
                {
                    // 48 hours acedemic paper high school
                    this.setState({price:17})
                }
                if(this.state.time === "5")
                {
                    // 3 days acedemic paper high school
                    this.setState({price:15})
                }
                if(this.state.time === "6")
                {
                    // 5 days acedemic paper high school
                    this.setState({price:14})
                }
                if(this.state.time === "7")
                {
                    // 7 days acedemic paper high school
                    this.setState({price:12})
                }
                if(this.state.time === "8")
                {
                    // 14 days acedemic paper high school
                    this.setState({price:10})
                }
            }
            

             
            if(this.state.level === "2")
            {
              //Undergraduate
              if(this.state.time === "1")
              {
                  // 3 hours acedemic paper  undergraduate
                  console.log("3 hours undergraduate academic paper")
                  this.setState({price:40})
              }
              if(this.state.time === "2")
              {
                  // 8 hours acedemic paper undergraduate
                  this.setState({price:34})
              }
              if(this.state.time === "3")
              {
                  // 24 hours acedemic paper undergraduate
                  this.setState({price:32})
              }
              if(this.state.time === "4")
              {
                  // 48 hours undergraduate 
                  this.setState({price:25})
              }
              if(this.state.time === "5")
              {
                  // 3 days undergraduate 
                  this.setState({price:19})
              }
              if(this.state.time === "6")
              {
                  // 5 days undergraduate 
                  this.setState({price:16})
              }
              if(this.state.time === "7")
              {
                  // 7 days undergraduate 
                  this.setState({price:15})
              }
              if(this.state.time === "8")
              {
                  // 14 daysundergraduate 
                  this.setState({price:14})
              }
              

            }
           

             
            if(this.state.level === "3")
            {
                //masters

                if(this.state.time === "1")
                {
                    // 3 hours acedemic paper  masters
                    console.log("3 hours undergraduate academic paper")
                    this.setState({price:" - "})
                }
                if(this.state.time === "2")
                {
                    // 8 hours acedemic papermasters
                    this.setState({price: " - "})
                }
                if(this.state.time === "3")
                {
                    // 24 hours acedemic paper masters
                    this.setState({price:50})
                }
                if(this.state.time === "4")
                {
                    // 48 hoursmasters 
                    this.setState({price:40})
                }
                if(this.state.time === "5")
                {
                    // 3 days masters
                    this.setState({price:30})
                }
                if(this.state.time === "6")
                {
                    // 5 days masters
                    this.setState({price:27})
                }
                if(this.state.time === "7")
                {
                    // 7 days masters
                    this.setState({price:24})
                }
                if(this.state.time === "8")
                {
                    // 14 days masters
                    this.setState({price:22})
                }
            }
            

            if(this.state.level === "4")
            {
                //PHD

                if(this.state.time === "1")
                {
                    // 3 hours acedemic paper  phd
                    console.log("3 hours undergraduate academic paper")
                    this.setState({price:" - "})
                }
                if(this.state.time === "2")
                {
                    // 8 hours acedemic paper phd
                    this.setState({price:" - "})
                }
                if(this.state.time === "3")
                {
                    // 24 hours acedemic paper phd
                    this.setState({price:58})
                }
                if(this.state.time === "4")
                {
                    // 48 hours  phd 
                    this.setState({price:47})
                }
                if(this.state.time === "5")
                {
                    // 3 days phd
                    this.setState({price:35})
                }
                if(this.state.time === "6")
                {
                    // 5 days phd
                    this.setState({price:32})
                }
                if(this.state.time === "7")
                {
                    // 7 days phd
                    this.setState({price:31})
                }
                if(this.state.time === "8")
                {
                    // 14 phd
                    this.setState({price:29})
                }
            }
            


          }
         //academic papers
///////////////////////////////////////////////////////////////////
 

// Problem Solving 
 if(this.state.type === "2")
 {
   console.log("Problem Solving");

 
   if(this.state.level === "1")
   {
       //high school 

       if(this.state.time === "1")
       {
           // 3 hours problem solving high school
           console.log("3 hours high school academic paper")
           this.setState({price:51})
       }
       if(this.state.time === "2")
       {
           // 8 hours problem solving high school
           this.setState({price:37})
       }
       if(this.state.time === "3")
       {
           // 24 hours problem solving high school
           this.setState({price:31})
       }
       if(this.state.time === "4")
       {
           // 48 hours problem solving high school
           this.setState({price:22})
       }
       if(this.state.time === "5")
       {
           // 3 days problem solving high school
           this.setState({price:25})
       }
       if(this.state.time === "6")
       {
           // 5 days problem solving  high school
           this.setState({price:21})
       }
       if(this.state.time === "7")
       {
           // 7 days problem solving high school
           this.setState({price:18})
       }
       if(this.state.time === "8")
       {
           // 14 days problem solving high school
           this.setState({price:15})
       }
   }
   

    
   if(this.state.level === "2")
   {
     //Undergraduate
     if(this.state.time === "1")
     {
         // 3 hours problem solving  undergraduate
         console.log("3 hours undergraduate academic paper")
         this.setState({price:60})
     }
     if(this.state.time === "2")
     {
         // 8 hours problem solving undergraduate
         this.setState({price:51})
     }
     if(this.state.time === "3")
     {
         // 24 hours problem solving undergraduate
         this.setState({price:48})
     }
     if(this.state.time === "4")
     {
         // 48 hours undergraduate 
         this.setState({price:37})
     }
     if(this.state.time === "5")
     {
         // 3 days undergraduate 
         this.setState({price:28})
     }
     if(this.state.time === "6")
     {
         // 5 days undergraduate 
         this.setState({price:24})
     }
     if(this.state.time === "7")
     {
         // 7 days undergraduate 
         this.setState({price:22})
     }
     if(this.state.time === "8")
     {
         // 14 daysundergraduate 
         this.setState({price:21})
     }
     

   }
  

    
   if(this.state.level === "3")
   {
       //masters

       if(this.state.time === "1")
       {
           // 3 hours acedemic paper  masters
           console.log("3 hours undergraduate academic paper")
           this.setState({price:" - "})
       }
       if(this.state.time === "2")
       {
           // 8 hours acedemic papermasters
           this.setState({price: " - "})
       }
       if(this.state.time === "3")
       {
           // 24 hours acedemic paper masters
           this.setState({price:75})
       }
       if(this.state.time === "4")
       {
           // 48 hoursmasters 
           this.setState({price:60})
       }
       if(this.state.time === "5")
       {
           // 3 days masters
           this.setState({price:45})
       }
       if(this.state.time === "6")
       {
           // 5 days masters
           this.setState({price:40})
       }
       if(this.state.time === "7")
       {
           // 7 days masters
           this.setState({price:36})
       }
       if(this.state.time === "8")
       {
           // 14 days masters
           this.setState({price:33})
       }
   }
   

   if(this.state.level === "4")
   {
       //PHD

       if(this.state.time === "1")
       {
           // 3 hours acedemic paper  phd
           console.log("3 hours undergraduate academic paper")
           this.setState({price:" - "})
       }
       if(this.state.time === "2")
       {
           // 8 hours acedemic paper phd
           this.setState({price:" - "})
       }
       if(this.state.time === "3")
       {
           // 24 hours acedemic paper phd
           this.setState({price:87})
       }
       if(this.state.time === "4")
       {
           // 48 hours  phd 
           this.setState({price:70})
       }
       if(this.state.time === "5")
       {
           // 3 days phd
           this.setState({price:52})
       }
       if(this.state.time === "6")
       {
           // 5 days phd
           this.setState({price:48})
       }
       if(this.state.time === "7")
       {
           // 7 days phd
           this.setState({price:46})
       }
       if(this.state.time === "8")
       {
           // 14 phd
           this.setState({price:43})
       }
   }
   


 }
// Problem Solving 



///////////////////////////////////////////////////////////////////////


// MCQs
if(this.state.type === "3")
{
  console.log("Problem Solving");


  if(this.state.level === "1")
  {
      //high school 

      if(this.state.time === "1")
      {
          // 3 hours problem solving high school
          console.log("3 hours high school academic paper")
          this.setState({price:3.8})
      }
      if(this.state.time === "2")
      {
          // 8 hours problem solving high school
          this.setState({price:2.5})
      }
      if(this.state.time === "3")
      {
          // 24 hours problem solving high school
          this.setState({price:2.1})
      }
      if(this.state.time === "4")
      {
          // 48 hours problem solving high school
          this.setState({price:1.7})
      }
      if(this.state.time === "5")
      {
          // 3 days problem solving high school
          this.setState({price:1.5})
      }
      if(this.state.time === "6")
      {
          // 5 days problem solving  high school
          this.setState({price:1.4})
      }
      if(this.state.time === "7")
      {
          // 7 days problem solving high school
          this.setState({price:1.2})
      }
      if(this.state.time === "8")
      {
          // 14 days problem solving high school
          this.setState({price:1.0})
      }
  }
  

   
  if(this.state.level === "2")
  {
    //Undergraduate
    if(this.state.time === "1")
    {
        // 3 hours problem solving  undergraduate
        console.log("3 hours undergraduate academic paper")
        this.setState({price:4.0})
    }
    if(this.state.time === "2")
    {
        // 8 hours problem solving undergraduate
        this.setState({price:3.4})
    }
    if(this.state.time === "3")
    {
        // 24 hours problem solving undergraduate
        this.setState({price:3.2})
    }
    if(this.state.time === "4")
    {
        // 48 hours undergraduate 
        this.setState({price:2.5})
    }
    if(this.state.time === "5")
    {
        // 3 days undergraduate 
        this.setState({price:1.9})
    }
    if(this.state.time === "6")
    {
        // 5 days undergraduate 
        this.setState({price:1.6})
    }
    if(this.state.time === "7")
    {
        // 7 days undergraduate 
        this.setState({price:1.5})
    }
    if(this.state.time === "8")
    {
        // 14 daysundergraduate 
        this.setState({price:1.4})
    }
    

  }
 

   
  if(this.state.level === "3")
  {
      //masters

      if(this.state.time === "1")
      {
          // 3 hours acedemic paper  masters
          console.log("3 hours undergraduate academic paper")
          this.setState({price:" - "})
      }
      if(this.state.time === "2")
      {
          // 8 hours acedemic papermasters
          this.setState({price: " - "})
      }
      if(this.state.time === "3")
      {
          // 24 hours acedemic paper masters
          this.setState({price:5.0})
      }
      if(this.state.time === "4")
      {
          // 48 hoursmasters 
          this.setState({price:4.0})
      }
      if(this.state.time === "5")
      {
          // 3 days masters
          this.setState({price:3.0})
      }
      if(this.state.time === "6")
      {
          // 5 days masters
          this.setState({price:2.7})
      }
      if(this.state.time === "7")
      {
          // 7 days masters
          this.setState({price:2.4})
      }
      if(this.state.time === "8")
      {
          // 14 days masters
          this.setState({price:2.2})
      }
  }
  

  if(this.state.level === "4")
  {
      //PHD

      if(this.state.time === "1")
      {
          // 3 hours acedemic paper  phd
          console.log("3 hours undergraduate academic paper")
          this.setState({price:" - "})
      }
      if(this.state.time === "2")
      {
          // 8 hours acedemic paper phd
          this.setState({price:" - "})
      }
      if(this.state.time === "3")
      {
          // 24 hours acedemic paper phd
          this.setState({price:5.8})
      }
      if(this.state.time === "4")
      {
          // 48 hours  phd 
          this.setState({price:4.7})
      }
      if(this.state.time === "5")
      {
          // 3 days phd
          this.setState({price:3.5})
      }
      if(this.state.time === "6")
      {
          // 5 days phd
          this.setState({price:3.2})
      }
      if(this.state.time === "7")
      {
          // 7 days phd
          this.setState({price:3.1})
      }
      if(this.state.time === "8")
      {
          // 14 phd
          this.setState({price:2.9})
      }
  }
  


}
// MCQS


//////////////////////////////////////////////////////////////////////

      }
    
      else{
        this.setState({price:" - "})
      }

    });
  
   
    
  }

  subscribeUser =(option) =>{
    if(option === 1)
    {
      //4.99 monthly Pakcage  Average Student
      console.log("Start Success First Package ")
      console.log("End  Success First Package ")
    }
    if(option === 2)
    {
      //9.99 monthly All STar Student 
    }
  }
  render() {
    const CURRENCY = 'USD';
    const fromEuroToCent = amount => amount * 100;

    const successPayment1 = data => {
      alert('Payment Successful , You Have Successfully Subscribed to Monthly Subscription for $ 4.99');
      this.setState({status:'Paid'})
      this.subscribeUser(1)
      
    };
    const successPayment2 = data => {
      alert('Payment Successful , You Have Successfully Subscribed to Monthly Subscription for $ 9.99');
      this.setState({status:'Paid'})
      this.subscribeUser(2)
      
    };
    const successPayment = data => {
      alert('Payment Successful');
      this.setState({status:'Paid'})
      this.submitOrder()
      
    };
    const errorPayment = data => {
      alert('Payment Error');
      console.log(data)
      this.setState({status:'Not Paid'})
      this.submitOrder()
    };
    const onToken = (amount, description) => token =>
      axios.post(PAYMENT_SERVER_URL,
        {
          description,
          source: token.id,
          currency: CURRENCY,
          amount: fromEuroToCent(amount)
        })
        .then(successPayment)
        .catch(errorPayment);
        const onToken1 = (amount, description) => token =>
      axios.post(PAYMENT_SERVER_URL+'pack1',
        {
          description,
          source: token.id,
          currency: CURRENCY,
          email:this.state.userEmail,
          amount: fromEuroToCent(amount)
        })
        .then(successPayment1)
        .catch(errorPayment);
        const onToken2 = (amount, description) => token =>
      axios.post(PAYMENT_SERVER_URL+'/pack2',
        {
          description,
          source: token.id,
          currency: CURRENCY,
          email:this.state.userEmail,
          amount: fromEuroToCent(amount)
        })
        .then(successPayment1)
        .catch(errorPayment);
        const Checkout = ({ name, description, amount }) =>
        <StripeCheckout
          name={name}
          description={description}
          amount={fromEuroToCent(amount)}
          token={onToken(amount, description)}
          currency={CURRENCY}
          stripeKey={STRIPE_PUBLISHABLE}
        />
        const Checkout1 = ({ name, description, amount }) =>
        <StripeCheckout
          name={name}
          description={description}
         
          token={onToken1(amount, description)}
          currency={CURRENCY}
          stripeKey={STRIPE_PUBLISHABLE}
        />
        const Checkout2 = ({ name, description, amount }) =>
        <StripeCheckout
          name={name}
          description={description}
         
          token={onToken2(amount, description)}
          currency={CURRENCY}
          stripeKey={STRIPE_PUBLISHABLE}
        />
    return (
      <React.Fragment>
       
            
                <div className="">
                  {/*<img
                  style={{marginTop:'-35px'}}
                    src="img/sc1.png"
                    alt="download"
                    className="img-fluid"
                  />*/}

<div
                        className={
                          "card  single-pricing-pack popular-price " 
                        }
                        style={{borderRadius:'15px',border:'2px solid #9629e6'}}
                      >
                        <div className="pt-3 text-center">
                          <h5>24/7 Homework Help Calculator</h5>
                        </div>
                        
                       
                        <div className="card-body">
                        <div className="form-group">
                        <select
                          value={this.state.type}
                          onChange={e => this.handleFormValueChange("type", e)}
                          type="text"
                          style={{width:'100%'}}
                          className="form-control"
                          name="type"
                          placeholder="Enter name"
                          required="required"
                        >
                          <option value="0" disabled>- - - - Select Type of Work - - - - </option>
                         
                          <option value="1">Academic Papers</option>
                          <option value="2">Problem Solving</option>
                          <option value="3">Multiple Choice Questions</option>
                          
                          </select>
                          <br/>
                          <select
                          value={this.state.level}
                          onChange={e => this.handleFormValueChange("level", e)}
                          type="text"
                          style={{width:'100%'}}
                          className="form-control"
                          name="level"
                          placeholder="Enter name"
                          required="required"
                        >
                          
                          <option value="0" disabled>- - - - Select Education Level - - - - </option>
                          <option value="1">High School</option>
                          <option value="2">Undergraduate</option>
                          <option value="3">Masters</option>
                          <option value="4">Phd</option>
                         
                          
                          </select>

                          <br/>
                          <select
                          value={this.state.time}
                          onChange={e => this.handleFormValueChange("time", e)}
                          type="text"
                          style={{width:'100%'}}
                          className="form-control"
                          name="time"
                          placeholder="Enter name"
                          required="required"
                        >
                          
                          <option value="0" disabled>- - - - Select Due Time/Date - - - - </option>
                          <option value="1">3 Hours</option>
                          <option value="2">8 Hours</option>
                          <option value="3">24 Hours</option>
                          <option value="4">48 Hours</option>
                          <option value="5">3 Days</option>
                          <option value="6">5 Days</option>
                          <option value="7">7 Days</option>
                          <option value="8">14 Days</option>
                          </select>
                         
                        <label style={{textAlign:'left',marginTop:4}}>Attach File (optional)</label>
                         <input onChange={(e) => this.handleUploadFile(e)} type="file" style={{marginTop:5,border:'1px solid grey',padding:3}} />
                          
                      </div>


                      <div className="card-header py-4 border-0 pricing-header">
                          <div className="h3 text-center mb-0">
                            <span className="price font-weight-bold">
                              Estimated Cost : <i>$ {this.state.price}</i>
                            </span>
                          </div>
                      </div>

                          {this.state.display}
                        </div>
                      </div>
                </div>
             
           



        {/* ---------------------------------- SUbscripton ----------------------------------*

                          <Pri />

         ---------------------------------- SUbscripton ----------------------------------*/}
        <Modal
           
           show={this.state.openPurchase}
           onHide={() => this.setState({openPurchase:false})}
           
           
           aria-labelledby="example-custom-modal-styling-title"
         >
           <Modal.Header closeButton>
             <Modal.Title id="example-custom-modal-styling-title" style={{fontWeight:'bolder'}}>
             <b class="ti-import" style={{fontWeight:'bolder'}}></b>  Homework Help
             </Modal.Title>
           </Modal.Header>
           <Modal.Body>

           <Card style={{borderColor:'#6730e3'}}>
  <Card.Header as="h5" style={{color:'#6730e3'}}>Confirm Payment </Card.Header>
  <Card.Body>
    <div class="row">

       
       
                        <br/>
                        
       <div class="col-lg-12">
       
       <Card.Text>
      By Clicking the Below Button you agree to our terms and conditions and agree to pay for our services.
    </Card.Text>
    <Checkout
            name={'Homework Order'}
            description={'Undergraduate'}
            amount={this.state.price }
          />
                        <hr/>
       
       </div>
                     
    </div>
           
    </Card.Body>
</Card>

      </Modal.Body>
      </Modal>


       
      </React.Fragment>
    );
  }
}

export default Download;
