import React, { Component } from "react";
import Header from "../components/Header/header";
import HeroSection from "../components/HeroSection/HeroSectionChemistry";
import PromoSection from "../components/PromoSection/Promo3";
import AboutSection from "../components/AboutUs/AboutUs2";
import FeatureSection from "../components/Features/Feature2";
import VideoSection from "../components/Video";
import PricingSection from "../components/Pricing";
import Testimonial from "../components/Testimonial";
import Screenshots from "../components/Screenshots";
import FaqSection from "../components/Faq";
import TeamMember from "../components/TeamMember";
import ContactSection from "../components/Contact";
import TrustedCompany from "../components/TrustedCompany/TrustedCompany";
import SubscribeSection from "../components/Subscribe";
import FooterSection from "../components/Footer";
import firebaseConfig from '../components/HeroSection/firebaseConfig';
import 'firebase/auth';
import * as firebase from 'firebase/app';

class Theme extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      display:''
     
    };
   
 
  }
  checklogin =() =>
  {
    var that = this;
    var user = firebase.auth().currentUser;
    firebase.auth().onAuthStateChanged(function(user) {    
      if(user) {
              
            //alert("Is Signed In")
           that.setState({ display:<div><Header /><div className="main"><HeroSection /> <FooterSection /></div></div>})
          console.log(user.displayName)
          console.log(user.uid)
          } else {
            //alert("Not Signed In")
            window.location.assign("/login1");
            // No user is signed in.
          }
        });
  }
  

  componentDidMount(){
    this.checklogin()
  }
  
  render() {
    return (
      <React.Fragment>
        {this.state.display}
      </React.Fragment>
    );
  }
}

export default Theme;
