import * as firebase from 'firebase/app';
import React from "react";
import firebaseConfig from '../../HeroSection/firebaseConfig';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/firebase-database'
import 'firebase/firebase-storage'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import DataTable from 'react-data-table-component';
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import Button from 'react-bootstrap/Button'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import ReactSnackBar from "react-js-snackbar";
import RiseLoader from "react-spinners/RiseLoader";
import Badge from 'react-bootstrap/Badge'


var data = [{ id: 1, username: 'Conan the Barbarian', email: '1982'},{id: 2, username: 'Conan the Barbarian', email: '1982'},{id: 3, username: 'Conan the Barbarian', email: '1982' }];
const columns = [
    
  {
    name: 'Document Title',
    selector: 'title',
    sortable: false,
    
  },{
    name: 'Course',
    selector: 'course',
    sortable: true,
    //right: true,
  },
  
  {
    name: 'School',
    selector: 'school',
    
    sortable: true,
    //right: true,
  },
  {
    name: 'Uploaded By',
    selector: 'user',
    
    sortable: true,
    //right: true,
  },
  
  {
    name: 'Action',
    selector: 'action',
    right: true,
  },
];

class Dashboard extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            title:'Dashboard',
            loading:true,
            Show: false,
            Showing: false,
            notificationMessage:'',
            status:false,
            userDocuments:'',
           data:[],
           finalTable:<div className="sweet-loading" style={{marginTop:'100px',width:'80%',marginLeft:'auto',marginRight:'auto'}}>
           <center><RiseLoader
             
             size={15}
             color={"#8d2ad7"}
             margin={2}
             loading={true}
           /></center>
         </div>
        }
    }
    deleteOrder = (id,user) => {
      console.log("Deleting Document : " + id + "User" + user);
      if(window.confirm("Are you Sure you Want to Delete Document ? Click Ok to Delete")){
        this.setState({notificationMessage:'Document Deleted Successfully'})
        var x = firebase.database().ref(`Users/${user}/Documents`).child(id);
      
        x.remove().then(() => {
          if (this.state.Showing) return;
    
          this.setState({ Show: true, Showing: true });
          setTimeout(() => {
            this.setState({ Show: false, Showing: false });
          }, 2000);
          this.loadData()
        })
      }
      

     
      
  }
     markStatus = (id,type) => {
        console.log("Changing Status : " + id + type);
        var user = firebase.auth().currentUser;
        var x = firebase.database().ref(`CustomOrders`).child(id);
        if(type === 'Pending')
        {
            this.setState({notificationMessage:'Marked Order Status as Pending'})
            
        }
        if(type === 'Processing')
        {
            this.setState({notificationMessage:'Marked Order Status as Processing'})
        }
        if(type === 'Completed')
        {
            this.setState({notificationMessage:'Marked Order Status as Completed'})
        }
        x.update({status:type}).then(() => {
          if (this.state.Showing) return;
    
          this.setState({ Show: true, Showing: true });
          setTimeout(() => {
            this.setState({ Show: false, Showing: false });
          }, 2000);
          this.loadData()
        })

       
        
    }
    loadData = () =>{
      this.setState({finalTable:<div className="sweet-loading" style={{marginTop:'100px',width:'80%',marginLeft:'auto',marginRight:'auto'}}>
      <center><RiseLoader
        
        size={15}
        color={"#8d2ad7"}
        margin={2}
        loading={true}
      /></center>
    </div>})
    
      const ExpanableComponent = ({ data }) => <div className="row col-lg-12" style={{paddingTop:15}}>
  
  <table class="table table-bordered table-sm" style={{marginLeft:'10px'}}>
  
  <tbody>
    <tr>
      <td scope="row"><b>Name :  </b></td>
      <td><b>{data.username}</b></td>
      <td scope="row"><b>Homework Type :  </b></td>
      <td><b>{data.homeworktype}</b></td>
      
    </tr>
    <tr>
        <td><b>Phone : </b></td>
      <td><b>{data.phone}</b></td>
      <td scope="row"><b>Education Level :  </b></td>
      <td><b>{data.educationlevel}</b></td>
      
    </tr>
    <tr>
    <td><b>Email : </b></td>
      <td><b>{data.email}</b></td>
      <td scope="row"><b>Order Time :  </b></td>
      <td><b>{data.time}</b></td>
      
    </tr>
    <tr>
    <td><b>Date & Time  : </b></td>
      <td><b>{data.date}</b></td>
      <td scope="row"><b>Order Status :  </b></td>
      <td><b><Badge variant={data.status === "Completed" ? "success" : data.status === "Processing" ?  "warning" : "danger"}>{data.status}</Badge></b></td>
      
    </tr>
    <tr>
    <td><b>Order Price : </b></td>
      <td><b>{data.price}</b></td>
      <td><b>Action : </b></td>
      <td>
      
      <ButtonGroup className="mr-2" size="sm" aria-label="Second group">
      <Button onClick={() => this.markStatus(data.docid,'Pending')} className="btn btn-default" style={{backgroundColor:'#8D2AD7',color:'white',borderColor:'white'}}> <span className="ti-flag-alt"></span> Mark as Pending</Button>
         <Button onClick={() => this.markStatus(data.docid,'Processing')} className="btn btn-default" style={{backgroundColor:'#8D2AD7',color:'white',borderColor:'white'}}><span className="ti-alarm-clock"></span> Mark as Processing</Button>
          <Button onClick={() => this.markStatus(data.docid,'Completed')} className="btn btn-default" style={{backgroundColor:'#8D2AD7',color:'white',borderColor:'white'}}><span className="ti-check-box"></span> Mark as Completed</Button>
      
      </ButtonGroup>
      <Button onClick={() => this.deleteOrder(data.docid)}  className="btn btn-danger btn-sm " style={{borderColor:'white'}}><span class="ti-trash"></span> Delete Order</Button>
      </td>
      
    </tr>
  </tbody>
</table>
        
        
      
      
        
        
        </div>;
      var that = this;
      var user = firebase.auth().currentUser;
      firebase.database().ref('Users').once('value').then(function(snapshot) {
       // console.log(snapshot.val())
        that.setState({userDocuments:snapshot.val()})
       // console.log(that.state.userDocuments)
       var data = snapshot.val()
       if(snapshot && snapshot.exists())
       {
            that.state.data = [];
               for(var key in data){
                   for(var innerKey in data[key].Documents)
                   {
                    //console.log(key)
                       console.log(innerKey)
                      // console.log(data[key].Documents[innerKey])
                     //  console.log(data[key].Documents[innerKey].downloadUrl)
                     const temp = innerKey
                     const temp2 = key
                       that.state.data.push({
                           id:innerKey,
                           title:data[key].Documents[innerKey].docTitle,
                           course:data[key].Documents[innerKey].courseName,
                           school:data[key].Documents[innerKey].schoolName,
                           user:data[key].email,
                           action:<b><button onClick={()=> that.deleteOrder(temp,temp2)} className="btn btn-danger"><span class="ti-trash"></span> Delete</button></b>
                       })
                       //that.state.userDocuments.push({
                       //    title:data[key].Documents[innerKey].docTitle,
                       //    download:data[key].Documents[innerKey].downloadUrl})
                       //    that.setState({status:true})
                       }
               }
          //Object.keys(data).map((cur,index) =>{
           //  console.log(data[cur].Documents)
          //  console.log(data[cur].Documents[0])
            
              that.setState({status:true,loading:false})
              
         // })
       }
       
        
      }).then(() => this.setState({finalTable: <DataTable
        e
       
        
        progressPending={this.state.loading}
      
        striped
        highlightOnHover
        columns={columns}
        data={this.state.data}
      />}))
      
    }
    componentDidMount() {
      this.loadData()
    }
    render()
    {
      var newData = [...this.state.data]
      var showTable = '';
      if(this.state.status){
        const ExpanableComponent = ({ data }) => <div className="row col-lg-12" style={{pddingTop:15}}>
    
        <div className="col-lg-6" style={{float:'left'}}>
        
      
      <ButtonGroup className="mr-2" aria-label="Second group">
        
          <DropdownButton as={ButtonGroup} title="Dropdown" id="bg-nested-dropdown">
    <Dropdown.Item onClick={() => this.markStatus(1,'pending')} eventKey="1">Dropdown link</Dropdown.Item>
    <Dropdown.Item onClick={() => this.markStatus(1,'pending')} eventKey="2">Dropdown link</Dropdown.Item>
    <Dropdown.Item onClick={() => this.markStatus(1,'pending')}  eventKey="2">Dropdown link</Dropdown.Item>
  </DropdownButton>
      
      </ButtonGroup>
      
      </div>
      <div className="col-lg-6" style={{float:'right'}}>
      <ButtonToolbar aria-label="Toolbar with button groups" style={{margin:10}}>
      <ButtonGroup aria-label="Third group">
        <Button className="btn btn-danger " style={{borderColor:'white'}}><span class="ti-trash"></span> Delete Order</Button>
      </ButtonGroup>
      </ButtonToolbar>
      </div>
        
        
        </div>;
        
      }
      
        return(<div>
         <center><button style={{fontWeight:'bold',color:'white',backgroundColor:'#8D2AD7'}} className="btn" onClick={() => this.loadData()}>
            <span class="ti-reload" ></span> Refresh</button></center>
        {this.state.finalTable}
      <ReactSnackBar Icon={<span class="ti-face-smile"></span>} Show={this.state.Show}>
         {this.state.notificationMessage}
        </ReactSnackBar>
        </div>)
    }
}

export default Dashboard;