import React, { Component } from 'react';

import Checkout from '../Checkout1';

class App extends Component {
  render() {
    return (
      <div className="App">
        <div className="App-header">
         
          <h2>Welcome to React</h2>
        </div>
        <p className="App-intro">
          <Checkout
            name={'Subscription'}
            description={'Only the Book'}
           
          />
        </p>
      </div>
    );
  }
}
export default App;