import React from "react";
import _data from "../../data";

class TeamMember extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      teamMember: {},
    };
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
    this.setState({
      teamMember: _data.teamMember,
    });
  }

  render() {
    return (
      <React.Fragment>
        <section className="team-member-section ptb-100">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-7 col-md-8">
                <div className="section-heading text-center mb-4">
                  <h2>Leadership</h2>
                  <hr/>
                </div>
              </div>
            </div>
            <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6">
                
                </div>
                
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="single-team-member position-relative my-lg-3 my-md-3 my-sm-0">
                  <div className="team-image">
                    <img
                      src="img/ceo_weboro.jpg"
                      alt="Team Members"
                      className="img-fluid rounded"
                    />
                  </div>
                  <div className="team-info text-white rounded d-flex flex-column align-items-center justify-content-center">
                    <h5 className="mb-0">Gabriel Gavrilov</h5>
                    <h6>CEO @ WeBoro</h6>
                    <ul className="list-inline team-social social-icon my-4 text-white">
                      <li className="list-inline-item">
                        <a href="https://www.linkedin.com/in/gabrielgavrilov/" target="_blank">
                          <span className="ti-linkedin"></span>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="https://twitter.com/we_boro"  target="_blank">
                          <span className="ti-twitter"></span>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="https://www.instagram.com/weboro_homework_app/"  target="_blank">
                          <span className="ti-instagram"></span>
                        </a>
                      </li>
                      
                    </ul>
                    <a
                      href="https://www.linkedin.com/in/gabrielgavrilov/"
                      target="_blank"
                      className="btn app-store-btn"
                    >
                      View Bio
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6">
                
              </div>
              
             
          
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default TeamMember;
