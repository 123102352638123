import * as firebase from 'firebase/app';
import React from "react";
import firebaseConfig from '../HeroSection/firebaseConfig';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/firebase-database'
import 'firebase/firebase-storage'
import RiseLoader from "react-spinners/RiseLoader";
import { connect } from "react-redux";
import Modal from 'react-responsive-modal';
import Select from 'react-select';


class PromoSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      updateModal:false,
      loading:false,
      uploadOpen:false,
      deleteOpen:false,
      schoolName:'',
      courseName:'',
      term:'Fall',
      year:'2020',
      type:'Free',
      document:'',
      userDocuments:{schoolName:'',courseName:'',year:''},
      documentName:'',
      display:'',
      status:false,
      docTitle:'',
      toedit:'',
      selectedOption: null,
      current:'',
      files:[]
    };

    this.uploadDone = this.uploadDone.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    //this.onSubmit = this.onSubmit.bind(this);
  }

  

  handleUploadFile = (event) => {
    let selectedFile = event.target.files;
   
    //Check File is not Empty
    if (selectedFile.length > 0) {
        // Select the very first file from list
        let fileToLoad = selectedFile[0];
        this.setState({
          document: fileToLoad,
         
        })
    }

 
}
  loadData = () =>{
    var that = this;
    var user = firebase.auth().currentUser;
    firebase.database().ref('Users/' + user.uid).once('value').then(function(snapshot) {
      console.log(snapshot.val().Documents)
      that.setState({userDocuments:snapshot.val().Documents})
      console.log(that.state.userDocuments)
      if(snapshot.val().Documents)
      {
        console.log("i am in")
        that.setState({status:true})
      }
     
      
    });
  }
  onFileChange(e) {
    //console.log(e.target.files)
    this.setState({files:e.target.files})


}
  componentDidMount() {

    //listReactFiles('../DATA/Bacteriology').then(files => console.log(files))
    //this.pushDoc()
  // this.loadData()
    
  }
  onOpenModal = () => {
    this.setState({ open: true });
  };
 
  onCloseModal = () => {
    this.setState({ open: false });
  };

  onOpenDeleteModal = () => {
    this.setState({ deleteOpen: true });
  };
 
  onCloseDeleteModal = () => {
    this.setState({ deleteOpen: false });
  };
  onCloseUpdateModal = () => {
    this.setState({ updateModal: false });
  };
  onCloseUploadModal = () => {
    this.setState({ uploadOpen: false });
    this.loadData()
  };
  handleFormValueChange(inputName, event) {
    let stateValue = {};
    stateValue[inputName] =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
        this.setState(stateValue)
  }
 uploadDone = () =>{
  
 }


onOpenUpdateModal = (id) =>{
  this.setState({updateModal:true})

  console.log(id)
  this.setState({courseName:id.courseName,schoolName:id.schoolName,term:id.term,year:id.year,docTitle:id.docTitle,toedit:id.documentName})
  console.log(this.state)
}
 deleteDocument = (id) => {

  

  if(window.confirm("Are you sure you want to delete this document  ? Click ok to Delete")){
    console.log("Deleting Document" + id)
    var user = firebase.auth().currentUser;
    firebase.database().ref(`Users/${user.uid}/Documents`).child(id).remove();
    firebase.storage().ref(`Documents/${user.uid}`).child(id).delete().then(() =>  window.location.reload())
  }

  
  
 }

 
  uploadDocument = async _ =>  {
      console.log("Start")

      console.log("Calling")
    for (const key of Object.keys(this.state.files)) {
        console.log(this.state.files[key].name);

    console.log("Uploading Document")
    var x = await firebase.database().ref('Semester1').push();
    console.log(x.key)
    x.set({ 
      schoolName: 'ALL',
      term: 'Winter',
      year: '2019',
      courseName:'Chemistry Notes',
      documentName:x.key,
      downloadUrl:'',
      docTitle:this.state.files[key].name,
      type:'Free',
      category:'Semester12'
      }
      );
     

     const upl = await firebase.storage().ref("Documents/Semester1").child(x.key).put(this.state.files[key]).then(async uploadTaskSnapshot => {
        console.log("download")
         const finalUrl = await uploadTaskSnapshot.ref.getDownloadURL();
          console.log(finalUrl)
         const update = await x.update({downloadUrl:finalUrl})
        
    
      });
     
     
    };
    
  }
  handleChange = selectedOption => {
    this.setState({ selectedOption });
    this.setState({schoolName:selectedOption.value})
    console.log(`Option selected:`, selectedOption.value);
  };
  render() {
   
    const { selectedOption } = this.state;
    const IndicatorsContainer = ''
    var show = '';
    if(this.state.status){
       show = Object.keys(this.state.userDocuments).map((current,index) =>(
        
        <tr>
          <td>{this.state.userDocuments[current].docTitle}</td>
        <td>{this.state.userDocuments[current].schoolName}</td>
        <td>{this.state.userDocuments[current].courseName}</td>
        <td>{this.state.userDocuments[current].year}</td>
        <td><a target="_blank" href={this.state.userDocuments[current].downloadUrl}  class="btn btn-info btn-sm "><b class="ti-eye "></b></a> <a href="#" onClick={() => this.onOpenUpdateModal(this.state.userDocuments[current])} class="btn btn-primary btn-sm "><b class="ti-pencil "></b></a> <a onClick={() => this.deleteDocument(this.state.userDocuments[current].documentName)} href="#" class="btn btn-danger btn-sm "><b class="ti-trash "></b></a></td>
        </tr>
    ))
    }
    else{
       show = ''
    }
    const { open,deleteOpen } = this.state;
    return (
      <React.Fragment>
       
          Auto Processing 
          <br/><br/>
          <input id="fileItem" onChange={this.onFileChange} multiple type="file" />
          <button onClick={this.uploadDocument()}>Upload</button>
          {this.state.current}
         
          <br/>
         
          
      </React.Fragment>
    );
  }
}

export default connect(state => ({
  state
}))(PromoSection);
