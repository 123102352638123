import React from "react";
import { connect } from "react-redux";
import { subscribe } from "../../actions/index";
import _data from "../../data";
import InputGroup from 'react-bootstrap/InputGroup'
import Button from 'react-bootstrap/Button'
import FormControl from 'react-bootstrap/FormControl'
import Calculator from '../Download'
class HeroSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hero: {},
      searchTerm:'',
      email: "",
      disableBtn: false,
      btnText: "Subscribe"
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  changeBtnText = btnText => {
    this.setState({ btnText });
  };

  handleFormValueChange(inputName, event) {
    let stateValue = {};
    stateValue[inputName] =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    this.setState(stateValue);
  }

  handleSubmit(event) {
    event.preventDefault();

    // disable the button
    this.setState({ disableBtn: true });

    // get action
    const subscribeAction = subscribe(this.state);

    // Dispatch the contact from data
    this.props.dispatch(subscribeAction);

    // added delay to change button text to previous
    setTimeout(
      function() {
        // enable the button
        this.setState({ disableBtn: false });

        // change to button name
        this.changeBtnText("Subscribe");

        // get action again to update state
        const subscribeAction = subscribe(this.state);

        // Dispatch the contact from data
        this.props.dispatch(subscribeAction);

        // clear form data
        this.setState({
          email: ""
        });
      }.bind(this),
      3000
    );
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */

    this.setState({
      hero: _data.hero
    });
  }

  render() {
    return (
      <React.Fragment>
        <section
          className="hero-section pt-100 background-img"
          style={{
            backgroundImage: `url('img/image(2).png')`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundSize: "cover"
          }}
        >
          <div className="container">
            <div className="row align-items-center justify-content-between py-5">
              <div className="col-md-7 col-lg-6">
                <div className="hero-content-left text-white">
                  <h3 className="text-white">
                  We provide 24/7 on demand homework help and a library of vetted study resources </h3>
                  <InputGroup className="mb-3">
    <FormControl
      placeholder="Search Documents (e.g Organic Chemistry Notes)"
      value={this.state.searchTerm}
      onChange={(e) => this.setState({searchTerm:e.target.value})}
      aria-label="Recipient's username"
      aria-describedby="basic-addon2"
    />
    <InputGroup.Append>
      <Button onClick={(e) => window.location.href = '/search?keyword='+this.state.searchTerm} style={{borderColor:'white',color:'white',backgroundColor:'#5433A1',paddingLeft:20,paddingRight:20}}><span class="ti-search"></span></Button>
    </InputGroup.Append>
  </InputGroup>
                 
                  <p className="lead">
                    {/*this.state.hero.description*/}
                  </p>

                 {/* DOwnload Image  */}
                 <a href="https://apps.apple.com/us/app/weboro-1-homework-app/id1439371295" ><img
                    alt="placeholder"
                    src="img/download.png"
                    className="img-center img-fluid"
                  /></a>

                  <div className="video-promo-content py-4 d-flex align-items-center">
                    <a
                      href="https://www.youtube.com/watch?v=wUKnSMRSvpo"
                      className="popup-youtube video-play-icon-without-bip video-play-icon mr-3"
                    >
                      <span className="ti-control-play"></span>
                    </a>{" "}
                    Watch Video Overview
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-lg-4">
                <div className="hero-animation-img">
                  <Calculator/>
                </div>
              </div>
            </div>
          </div>
          <div className="bottom-img-absolute">
            <img
              src="img/hero-bg-shape-1.svg"
              alt="wave shape"
              className="img-fluid"
            />
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default connect(state => ({
  state
}))(HeroSection);
