import React from "react";
import { connect } from "react-redux";
import firebaseConfig from '../HeroSection/firebaseConfig';
import 'firebase/auth';
import * as firebase from 'firebase/app';


const displayHeader = <ul className="navbar-nav ml-auto"><li className="nav-item">
<a className="nav-link page-scroll" href="/">
  Home
</a>
</li>
<li className="nav-item">
<a className="nav-link page-scroll" href="/#whyus">
  Why Us ?
</a>
</li>
<li className="nav-item">
<a className="nav-link page-scroll" href="/#features">
  Features
</a>
</li>
<li className="nav-item">
<a className="nav-link page-scroll" href="/#pricing">
  Homework Calculator
</a>
</li>
<li className="nav-item">
<a className="nav-link page-scroll" href="/#screenshots">
  Screenshots
</a>
</li>

<li className="nav-item">
<a className="nav-link page-scroll" href="/blog">
  Blog
</a>
</li>

<li className="nav-item">
<a className="nav-link page-scroll" href="/login1">
 Login
</a>
</li>
<li className="nav-item">
<a className="nav-link page-scroll" href="/#contact">
  Contact
</a>
</li></ul>;

const displayHeader2 = <ul className="navbar-nav ml-auto">
  <li className="nav-item">
<a className="nav-link page-scroll" href="/dashboard">
  Dashboard
</a>
</li>
<li className="nav-item">
<a className="nav-link page-scroll" href="/profile">
  Profile
</a>
</li>

<li className="nav-item">
<a className="nav-link page-scroll" href="/#pricing">
  Homework Calculator
</a>
</li>
<li className="nav-item">
<a className="nav-link page-scroll" href="/blog">
  Blog
</a>
</li>

<li className="nav-item">
<a className="nav-link page-scroll" href="/#contact">
 Contact Us
</a>
</li>
<li className="nav-item">
<a className="nav-link page-scroll" onClick={() => Header.logout()}  href="#">
  Logout
</a>
</li></ul>;

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      display:displayHeader
     
    };
   
 
  }

  checklogin =() =>
  {
    var that = this;
    var user = firebase.auth().currentUser;
    firebase.auth().onAuthStateChanged(function(user) {    
      if(user) {
              
            //alert("Is Signed In")
           that.setState({ display:displayHeader2})
          } else {
            //alert("Not Signed In")
            that.setState({ display:displayHeader})
            // No user is signed in.
          }
        });
  }
  static logout = () => {
    firebase.auth().signOut().then(function() {
      console.log("Signout Successfull")
    }).catch(function(error) {
      // An error happened.
    });
  }

  componentDidMount(){
    this.checklogin()
  }
  render() {
    
    return (
      <React.Fragment>
        
        <header className="header">
          <nav className={"navbar navbar-expand-lg fixed-top " + (this.props.bgColor && this.props.bgColor === 'white' ? 'custom-nav white-bg' : 'bg-transparent')}>
            <div className="container">
              <a className="navbar-brand" href="/">
                {/*<img
                  src={this.props.bgColor && this.props.bgColor === 'white' ? "img/logo-color-1x.png" : "img/logo-white-1x.png"}
                  width="120"
                  alt="logo"
                  className="img-fluid"
                />*/}
                <span className="text-white" style={{fontSize:24,fontWeight:'bold'}}>WeBoro</span>
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="ti-menu"></span>
              </button>

              <div
                className="collapse navbar-collapse main-menu"
                id="navbarSupportedContent"
              >
               {this.state.display}
              </div>
            </div>
          </nav>
        </header>
      </React.Fragment>
    );
  }
}

export default connect(state => ({}))(Header);
