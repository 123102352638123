import * as firebase from 'firebase/app';
import React from "react";
import firebaseConfig from '../HeroSection/firebaseConfig';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/firebase-database'
import 'firebase/firebase-storage'
import { connect } from "react-redux";
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Modal from 'react-responsive-modal';


class PromoSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openPurchase:false
    };
  }
  openPurchaseModal = (e) =>{

    console.log("Opening Purchase Model")
    this.setState(
      {
        openPurchase:true,
        open:false
    }
    )
  }
 
  componentDidMount() {
    
    
  }
 

  handleFormValueChange(inputName, event) {
    let stateValue = {};
    stateValue[inputName] =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
        this.setState(stateValue)
  }

  uploadDocument = () => {
    console.log("Uploading Document")
    var user = firebase.auth().currentUser;
    console.log(user.uid)
    console.log(this.state.term)
    console.log(this.state.year)
    var x = firebase.database().ref('Users/' + user.uid).child("Documents").push();
    console.log(x.key)
    x.set({ 
      schoolName: this.state.schoolName,
      term: this.state.term,
      year: this.state.year,
      courseName:this.state.courseName,
      documentName:x.key,
      downloadUrl:'',
      docTitle:this.state.docTitle
      
  }
      );

      const uploadTask = firebase.storage().ref("Documents/" + user.uid).child(x.key).put(this.state.document)

      uploadTask.then(uploadTaskSnapshot => {
        console.log("download")
        var urld = uploadTaskSnapshot.ref.getDownloadURL().then( url =>{
          console.log(url)
         x.update({downloadUrl:url})
          
        })
        //console.log(urld["i"])
       // this.setState({downloadUrl:uploadTaskSnapshot.ref.getDownloadURL()})
       // var a = firebase.database().ref('Users/' + user.uid).child("Documents").child(x.key);
       // a.set({downloadUrl:uploadTaskSnapshot.ref.getDownloadURL()})

      })
      this.setState({open:false})
    alert("Document Uploaded Successfully ")
  }
  render() {
    var show = '';
    if(this.state.status){
       show = Object.keys(this.state.userDocuments).map((current,index) =>(
        <tr>
          <td>{this.state.userDocuments[current].docTitle}</td>
        <td>{this.state.userDocuments[current].schoolName}</td>
        <td>{this.state.userDocuments[current].courseName}</td>
        <td>{this.state.userDocuments[current].year}</td>
        <td><a target="_blank" href={this.state.userDocuments[current].downloadUrl}  class="btn btn-info btn-sm "><b class="ti-eye "></b></a> <a href="#" onClick={this.onOpenModal} class="btn btn-primary btn-sm "><b class="ti-pencil "></b></a> <a onClick={this.onOpenDeleteModal} href="#" class="btn btn-danger btn-sm "><b class="ti-trash "></b></a></td>
        </tr>
    ))
    }
    else{
       show = ''
    }
    const { open,deleteOpen } = this.state;
    return (
      <React.Fragment>
       
      </React.Fragment>
    );
  }
}

export default connect(state => ({
  state
}))(PromoSection);
