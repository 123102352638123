import React, { Component } from "react";

import Header from "../components/Header/header";
import HeroSection from "../components/HeroSection/HeroSectionBlog";
import BlogSection from "../components/Blog";

import FooterSection from "../components/Footer";

class Theme extends Component {
  render() {
    return (
      <React.Fragment>
        <Header />
        <div className="main">
        <section
          className="hero-section ptb-100 background-img"
         
        >
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-md-9 col-lg-7">
                <div className="page-header-content text-white text-center pt-sm-5 pt-md-5 pt-lg-0">
                  <h1 className="text-white mb-0">Honor Code</h1>
                 
                </div>
              </div>
            </div>
          </div>
        </section>
         {/* privacy contact  */}
          <div className="container">
              <br/>
          <p><span style={{fontWeight: 400}}>WeBoro&nbsp; goes above and beyond to help you&mdash; not just in passing your classes, but in expanding your education by connecting you with new topics, concepts, ideas, educators, and students. We&rsquo;re dedicated to providing high-quality academic resources to help you learn and study more effectively, and we&rsquo;re equally committed to ensuring proper usage and protecting ownership of those resources.&nbsp;</span></p>
<p>&nbsp;</p>
<p><span style={{fontWeight: 400}}>While we believe that most WeBoro&nbsp; members use the site in the manner for which it was intended, upon registration all users must confirm that they have read, understood, and agreed to comply with WeBoro &rsquo;s Honor Code. We expect our users to act with academic integrity. Therefore, anyone who misuses our site for the express purpose of cheating and/or claiming another user&rsquo;s content as their own will be met with appropriate action.&nbsp;</span></p>
<p>&nbsp;</p>
<p><span style={{fontWeight: 400}}>Review our rules for proper use below: Do Use WeBoro&nbsp; materials to study more effectively and to better understand your course material. Upload quality original study materials to WeBoro&nbsp; that you have created entirely on your own. Use WeBoro&nbsp; study materials and tutors to assist with your assignments when it is appropriate. Use WeBoro&nbsp; in a manner that follows your instructors&rsquo; or institution&rsquo;s academic honor codes.&nbsp;</span></p>
<p>&nbsp;</p>
<p><span style={{fontWeight: 400}}>Don't Copy solutions directly from WeBoro&nbsp; resources and submit them as your own work. Don&rsquo;t upload another person&rsquo;s study materials to WeBoro&nbsp; and claim them as your own study materials. Don&rsquo;t use WeBoro&nbsp; materials or tutors to complete assignments when instructed not to use outside help. Don&rsquo;t use WeBoro&nbsp; in any manner that violates your instructors&rsquo; or institution&rsquo;s academic honor codes. Cheating is ill-advised for several reasons: First, by stealing someone else&rsquo;s ideas, you&rsquo;re not bothering to learn the material yourself.&nbsp;</span></p>
<p>&nbsp;</p>
<p><span style={{fontWeight: 400}}>You&rsquo;ve shelled out a handsome sum in tuition, and cheating only erodes the value of the education in which you&rsquo;ve invested. You&rsquo;re really cheating yourself, in the end. Second, you will likely get caught. Many universities leverage sophisticated technologies to scan for plagiarized material i</span></p>
<p><span style={{fontWeight: 400}}>n student-submitted work. We are an online database, and therefore WeBoro&nbsp; materials are often crawled by these content monitoring tools. We&rsquo;re even going out of our way to make sure that they are.&nbsp;</span></p>
<p>&nbsp;</p>
<p><span style={{fontWeight: 400}}>Third, the consequences are severe, and for many institutions, convictions of academic dishonesty can result in probation, suspension, or even expulsion. We welcome suggestions and feedback on how we can improve our Honor Code. Please email your ideas to us at </span><a href="mailto:info@weboro.co"><span style={{fontWeight: 400}}>info@weboro.co</span></a><span style={{fontWeight: 400}}>&nbsp;</span></p>
          </div>
          {/* privacy contact  */}
          <FooterSection />
        </div>
        
      </React.Fragment>
    );
  }
}

export default Theme;
