import React from "react";

class Feature extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <div id="features" className={"feature-section ptb-100 " + (this.props.bgColor && this.props.bgColor === 'gray' ? 'gray-light-bg' : '')}>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <div className="section-heading text-center mb-5">
                  <span className="badge badge-primary badge-pill">
                    WeBoro
                  </span>
                  <h2>
                  Powerful Features of Our Mobile App <br />
                    {/*<span>as a Student</span>*/}
                  </h2>
                  <hr/>
                  <p>
                   
                  </p>
                </div>
              </div>
            </div>

            <div className="row row-grid align-items-center">
              <div className="col-lg-4">
                <div className="d-flex align-items-start mb-5">
                  <div className="pr-4">
                    <div className="icon icon-shape icon-color-1 rounded-circle">
                      <span className="ti-face-smile"></span>
                    </div>
                  </div>
                  <div className="icon-text">
                    <h5>Easy to Use</h5>
                    <p className="mb-0">
                    Takes less than one minute to post your class notes for sale.
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-start mb-5">
                  <div className="pr-4">
                    <div className="icon icon-shape icon-color-2 rounded-circle">
                      <span className="ti-lock"></span>
                    </div>
                  </div>
                  <div className="icon-text">
                    <h5>Secure Platform</h5>
                    <p className="mb-0">
                    We use Stripe which is SSL protected and a PCI level 1 service provider. All credit card numbers are encrypted.
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-start">
                  <div className="pr-4">
                    <div className="icon icon-shape icon-color-3 rounded-circle">
                      <span className="ti-headphone-alt"></span>
                    </div>
                  </div>
                  <div className="icon-text">
                    <h5>Responsive Support Team.</h5>
                    <p className="mb-0">
                    With the press of a button you can call our corporate line and a human will answer 24/7.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="position-relative" style={{zIndex: 10}}>
                  <img
                    alt="placeholder"
                    src="img/features.png"
                    className="img-center img-fluid"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="d-flex align-items-start mb-5">
                  <div className="pr-4">
                    <div className="icon icon-shape icon-color-4 rounded-circle">
                      <span className="ti-comments"></span>
                    </div>
                  </div>
                  <div className="icon-text">
                    <h5>In App Messaging</h5>
                    <p className="mb-0">
                    Message and chat with other students in the app.
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-start mb-5">
                  <div className="pr-4">
                    <div className="icon icon-shape icon-color-5 rounded-circle">
                      <span className="ti-layout-cta-right"></span>
                    </div>
                  </div>
                  <div className="icon-text">
                    <h5>Free to List</h5>
                    <p className="mb-0">
                    Low on cash? Put your notes for sale.
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-start">
                  <div className="pr-4">
                    <div className="icon icon-shape icon-color-6 rounded-circle">
                      <span className="ti-bolt"></span>
                    </div>
                  </div>
                  <div className="icon-text">
                    <h5>Instant Transfers</h5>
                    <p className="mb-0">
                    When you sell your notes funds will show up in your bank account in 1-2 days.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Feature;
