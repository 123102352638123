import * as firebase from 'firebase/app';
import React from "react";
import firebaseConfig from '../HeroSection/firebaseConfig';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/firebase-database'
import axios from 'axios';
import StripeCheckout from 'react-stripe-checkout';
import STRIPE_PUBLISHABLE from '../../constants/stripe';
import PAYMENT_SERVER_URL from '../../constants/server';
import Modal from 'react-bootstrap/Modal'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Pri from '../Pricing/Price3'
import _data from "../../data";

class Pricing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      price: {},
      openSub1:false,
      openSub2:false,
      currentEmail:'',
      userID:'',
      loginStatus:false
    };
  }
  checklogin =() =>
  {
    var that = this;
    
    firebase.auth().onAuthStateChanged(function(user) {    
      if(user) {
              console.log("Email is :" + user.email)
              that.setState({currentEmail:user.email,userID:user.uid,loginStatus:true})
           
          } 
        });
  }
  componentDidMount() {
    
    this.setState({
      price: _data.price
    });

    this.checklogin();


  }


  openModal = (option) => {
    if(this.state.loginStatus)
    {
        if(option === 1)
        {
          //alert("First")
          this.setState({openSub1:true})
        }
        if(option === 2)
        {
          //alert("second")
          this.setState({openSub2:true})
        }
    }
    else
    {
      window.location.href = "/login1"
    }
  }
  subscribeUser =(option,subID) =>{
    if(option === 1)
    {
      //4.99 monthly Pakcage  Average Student
      console.log("Start Success First Package ")
      var x = firebase.database().ref("Users/"+this.state.userID);
      x.update({ subscriptionType:'Average Student Monthly',subId:subID
       


    }
        );
        this.setState({openSub1:false})
        alert('Payment Successful , You Have Successfully Subscribed to Monthly Subscription for $ 4.99');
      console.log("End  Success First Package ")
    }
    if(option === 2)
    {
      //9.99 monthly All STar Student 
      //4.99 monthly Pakcage  Average Student
      console.log("Start Success Second Package ")
      var x = firebase.database().ref("Users/"+this.state.userID);
      x.update({ subscriptionType:'All Star Student Monthly',subId:subID
       


    }
        );
        this.setState({openSub2:false})
        alert('Payment Successful , You Have Successfully Subscribed to Monthly Subscription for $ 9.99');
      console.log("End  Success Second Package ")
    }
  }
  render() {
    const CURRENCY = 'USD';
    const fromEuroToCent = amount => amount * 100;

    const successPayment1 = data => {
     
     
      this.subscribeUser(1,data.data.success.id)
      
    };
    const successPayment2 = data => {
      
      this.subscribeUser(2,data.data.success.id)
      
    };
    const successPayment = data => {
      alert('Payment Successful');
      this.setState({status:'Paid'})
      this.submitOrder()
      
    };
    const errorPayment = data => {
      alert('Payment Error , Failed Due to Server Error ! ');
      console.log(data)
      
    };
    
        const onToken1 = (amount, description) => token =>
      axios.post(PAYMENT_SERVER_URL+'/pack1',
        {
          description,
          source: token.id,
          currency: CURRENCY,
          email:this.state.currentEmail,
          amount: fromEuroToCent(amount)
        })
        .then(successPayment1)
        .catch(errorPayment);
        const onToken2 = (amount, description) => token =>
      axios.post(PAYMENT_SERVER_URL+'/pack2',
        {
          description,
          source: token.id,
          currency: CURRENCY,
          email:this.state.currentEmail,
          amount: fromEuroToCent(amount)
        })
        .then(successPayment2)
        .catch(errorPayment);
        
        const Checkout1 = ({ name, description, amount }) =>
        <StripeCheckout
          name={name}
          description={description}
         
          token={onToken1(amount, description)}
          currency={CURRENCY}
          stripeKey={STRIPE_PUBLISHABLE}
        />
        const Checkout2 = ({ name, description, amount }) =>
        <StripeCheckout
          name={name}
          description={description}
         
          token={onToken2(amount, description)}
          currency={CURRENCY}
          stripeKey={STRIPE_PUBLISHABLE}
        />
    return (
      <React.Fragment>
        <section id="subscription" className="package-section ptb-100 white">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <div className="section-heading text-center mb-5">
                  <h2>Subscription Packages</h2>
                  <p className="lead">Choose one of the packages below, you can cancel anytime.</p>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              {(this.state.price.packages || []).map((_package, index) => {
                return (
                    <div className="col-lg-4 col-md" key={index}>
                    <div className={"card text-center single-pricing-pack " + (_package.isFeatured ? 'primary-bg ' : '')}>
                      <div className={"card-header py-5 border-0 pricing-header " + (_package.isFeatured ? 'text-white' : '')}>
                        <div className="h1 text-center mb-0">
                          <span className="price ">{_package.price}</span>
                        </div>
                        <span className={"h6 " + (_package.isFeatured ? 'text-white' : 'text-muted')}>{_package.license} </span>
                      </div>
                      <div className="card-body">
                        <ul className={"list-unstyled text-sm mb-4 pricing-feature-list " + (_package.isFeatured ? 'text-white' : '')}>
                        {(_package.features || []).map(feature => {
                            return (
                                <li key={feature}>{feature}</li>
                            )
                        })}
                        </ul>
                        <a
                          onClick={()=> {_package.isFeatured ? this.openModal(2) :  this.openModal(1)}}
                          className={"btn mb-3 " + (_package.isFeatured ? 'app-store-btn' : 'solid-btn')}
                          target="_blank"
                        >
                          Subscribe
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
           
            {
              this.props.faq &&
              <div className="row pt-5">
                <div className="col-md-6">
                    <div className="single-faq">
                        <h5>How can I pay for this?</h5>
                        <p>Intrinsicly implement high standards in strategic theme areas via inexpensive solutions.
                            Assertively conceptualize prospective bandwidth whereas client-based imperatives.</p>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="single-faq">
                        <h5>Is it possible to pay yearly?</h5>
                        <p>Assertively iterate user friendly innovation without open-source markets. Monotonectally extend
                            resource sucking manufactured products without high-payoff paradigms. Objectively customize
                            ubiquitous information before economically sound relationships. </p>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="single-faq">
                        <h5>Do you offer discounts on multiple items?</h5>
                        <p>Dramatically target focused testing procedures after holistic ideas. Collaboratively maximize
                            high-payoff ROI and value-added products. Distinctively deliver cooperative collaboration and
                            idea-sharing whereas customized</p>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="single-faq">
                        <h5>Is VAT included in plan prices?</h5>
                        <p>Distinctively simplify high-quality initiatives for highly efficient applications. Monotonectally
                            repurpose integrated customer service after magnetic e-services. </p>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="single-faq">
                        <h5>Will I pay more for some features?</h5>
                        <p>Enthusiastically pontificate resource-leveling supply chains whereas scalable markets.
                            Authoritatively streamline resource maximizing methods of empowerment</p>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="single-faq">
                        <h5>Why are there no limits on the number of messages?</h5>
                        <p>Assertively target turnkey ideas for market-driven portals. Appropriately e-enable world-class
                            intellectual capital whereas 2.0 mindshare.</p>
                    </div>
                </div>
              </div>
            }
          </div>
        </section>

         {/*= Modal SUbscription 1 */}
         <Modal
           
           show={this.state.openSub1}
           onHide={() => this.setState({openSub1:false})}
           
           
           aria-labelledby="example-custom-modal-styling-title"
         >
           <Modal.Header closeButton>
             <Modal.Title id="example-custom-modal-styling-title" style={{fontWeight:'bolder'}}>
             <b class="ti-import" style={{fontWeight:'bolder'}}></b>  Average Student Monthly Subscription
             </Modal.Title>
           </Modal.Header>
           <Modal.Body>

           <Card style={{borderColor:'#6730e3'}}>
  <Card.Header as="h5" style={{color:'#6730e3'}}>Confirm Payment </Card.Header>
  <Card.Body>
    <div class="row">

       
       
                        <br/>
                        
       <div class="col-lg-12">
       
       <Card.Text>
      By Clicking the Below Button you agree to our terms and conditions and agree to pay for our services.
    </Card.Text>
    <Checkout1
            name={'Monthly Subscription'}
            description={'Average Student Monthly Subscription'}
           
          />
                        <hr/>
       
       </div>
                     
    </div>
           
    </Card.Body>
</Card>

      </Modal.Body>
      </Modal>
        {/*Model Subscription 1  */}


          {/*= Modal SUbscription 1 */}
          <Modal
           
           show={this.state.openSub2}
           onHide={() => this.setState({openSub2:false})}
           
           
           aria-labelledby="example-custom-modal-styling-title"
         >
           <Modal.Header closeButton>
             <Modal.Title id="example-custom-modal-styling-title" style={{fontWeight:'bolder'}}>
             <b class="ti-import" style={{fontWeight:'bolder'}}></b>  All Start Student Monthly Subscription
             </Modal.Title>
           </Modal.Header>
           <Modal.Body>

           <Card style={{borderColor:'#6730e3'}}>
  <Card.Header as="h5" style={{color:'#6730e3'}}>Confirm Payment </Card.Header>
  <Card.Body>
    <div class="row">

       
       
                        <br/>
                        
       <div class="col-lg-12">
       
       <Card.Text>
      By Clicking the Below Button you agree to our terms and conditions and agree to pay for our services.
    </Card.Text>
    <Checkout2
            name={'Monthly Subscription'}
            description={'Average Student Monthly Subscription'}
           
          />
                        <hr/>
       
       </div>
                     
    </div>
           
    </Card.Body>
</Card>

      </Modal.Body>
      </Modal>
        {/*Model Subscription 1  */}
      </React.Fragment>
    );
  }
}

export default Pricing;
