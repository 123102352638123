import React, { Component } from "react";

import Header from "../components/Header/header";
import HeroSection from "../components/HeroSection/HeroSectionBlog";
import BlogSection from "../components/Blog";

import FooterSection from "../components/Footer";

class Theme extends Component {
  render() {
    return (
      <React.Fragment>
        <Header />
        <div className="main">
        <section
          className="hero-section ptb-100 background-img"
         
        >
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-md-9 col-lg-7">
                <div className="page-header-content text-white text-center pt-sm-5 pt-md-5 pt-lg-0">
                  <h1 className="text-white mb-0">Privacy Policy</h1>
                 
                </div>
              </div>
            </div>
          </div>
        </section>
         {/* privacy contact  */}
          <div className="container">
            <br/>
          <p><span style={{fontWeight:400}}>This Privacy Policy applies to </span><a href="https://www.weboro.co/"><span style={{fontWeight:400}}>www.WeBoro .co</span></a><span style={{fontWeight:400}}> owned and operated by WeBoro , Inc. WeBoro 's Privacy Policy is designed to help you understand how we collect and use the personal information you decide to share, and help you make informed decisions when using WeBoro&nbsp; located at </span><a href="https://www.weboro.co/"><span style={{fontWeight:400}}>www.WeBoro .co</span></a><span style={{fontWeight:400}}> (the "Website").</span></p>
<p><span style={{fontWeight:400}}>By using or accessing the Website, you are accepting the practices described in this Privacy Policy</span></p>
<h6><span style={{fontWeight:'bold',color:'black',fontSize:15}}>Information we collect</span></h6>
<p><span style={{fontWeight:400}}>When you visit the Website you provide us with two types of information: (i) personal information you knowingly choose to disclose that is collected by us and (ii) use information collected by us as you interact with the Website.</span></p>
<p><span style={{fontWeight:400}}>When you register with the Website, you provide us with certain personal information, such as your name, your email address, your school, your status, your major, your graduation year, as well as sensitive information such as credit card information and any other personal or preference information that you provide to us.</span></p>
<h6><span style={{fontWeight:'bold',color:'black',fontSize:15}}>Tracking technologies</span></h6>
<p><span style={{fontWeight:400}}>Technologies such as: cookies, beacons, tags and scripts are used by the Website and our partners, affiliates, or analytics or service providers. These technologies are used in analyzing trends, administering the Website, tracking users&rsquo; movements around the Website and to gather demographic information about our user base as a whole. We may receive reports based on the use of these technologies by these companies on an individual as well as aggregated basis.</span></p>
<p><span style={{fontWeight:400}}>Users can control the use of cookies at the individual browser level. If you reject cookies, you may still use our Website, but your ability to use some features or areas of our Website may be limited.</span></p>
<h6><span style={{fontWeight:'bold',color:'black',fontSize:15}}>Log files</span></h6>
<p><span style={{fontWeight:400}}>As is true of most websites, we gather certain information automatically and store it in log files. This information may include internet protocol (IP) addresses, browser type, internet service provider (ISP), referring/exit pages, operating system, date/time stamp, and/or clickstream data. We may combine this automatically collected log information with other information we collect about you. We do this to improve services we offer you, to improve marketing, analytics, or Website functionality.</span></p>
<h6><span style={{fontWeight:'bold',color:'black',fontSize:15}}>Behavioral targeting / re-targeting</span></h6>
<p><span style={{fontWeight:400}}>We partner with a third party to either display advertising on our Website or to manage our advertising on other sites. Our third party partner may use technologies such as cookies to gather information about your activities on this Website and other sites in order to provide you advertising based upon your browsing activities and interests.&nbsp; If you wish to not have this information used for the purpose of serving you interest-based ads, you may opt-out by clicking </span><a href="http://preferences-mgr.truste.com/"><span style={{fontWeight:400}}>here</span></a><span style={{fontWeight:400}}>. Please note this does not opt you out of being served ads.&nbsp; You will continue to receive generic ads.</span></p>
<h6><span style={{fontWeight:'bold',color:'black',fontSize:15}}>Local storage objects (HTML5)</span></h6>
<p><span style={{fontWeight:400}}>We and third parties with whom we partner to provide certain features on our Website or to display advertising based upon your Web browsing activity use LSOs such as HTML 5 to collect and store information.</span></p>
<p><span style={{fontWeight:400}}>Various browsers may offer their own management tools for removing HTML5 LSOs.</span></p>
<h6><span style={{fontWeight:'bold',color:'black',fontSize:15}}>Use of information obtained by the website</span></h6>
<p><span style={{fontWeight:400}}>We use this information to:</span></p>
<ul>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ Fulfill your order</span></li>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ Send you an order confirmation</span></li>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ Assess the needs of your business to determine suitable products</span></li>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ Send you requested product or service information</span></li>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ Send product updates or warranty information</span></li>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ Respond to customer service requests</span></li>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ Administer your account</span></li>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ Send you periodical email newsletters about joint ventures, significant updates to the Website or marketing communications</span></li>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ Send you marketing communications</span></li>
</ul>
<ul>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ Administer contests and sweepstakes you entered, and notify you if you won</span></li>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ Respond to your questions and concerns</span></li>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ Improve our Website and marketing efforts</span></li>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ Conduct research and analysis</span></li>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ Display content based upon your interests</span></li>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ Facilitate your transactions with other users</span></li>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ Enable you to post your resume, search job postings, and contact or be contacted by prospective employers</span></li>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ List you in our member directory made available only to other members</span></li>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ List you in our publicly accessible member directory. If you do not want to be listed in our directory contact us at </span><span style={{fontWeight:400}}>privacy@WeBoro.co</span></li>
</ul>
<h6><span style={{fontWeight:'bold',color:'black',fontSize:15}}>Choice/opt-out</span></h6>
<p><span style={{fontWeight:400}}>You may choose to stop receiving our newsletter or marketing emails by following the unsubscribe instructions included in these emails or you can contact us at </span><span style={{fontWeight:400}}>privacy@WeBoro .co</span><span style={{fontWeight:400}}>.</span></p>
<p><span style={{fontWeight:400}}>We enable you to create a profile, and share information such as messages, photos, and videos with others. Please note that we cannot control the actions of others with whom you may choose to share your pages and information.</span></p>
<p><span style={{fontWeight:400}}>When you register with the Website, you create your own account. Your username and email will be attached to material you upload on the Website. The Website will not provide any of your other personal information to third parties.</span></p>
<p><span style={{fontWeight:400}}>When you disclose your Facebook account email address and password to the Website on the Invite Friends page, the Website will only use such information to (i) add you to the Facebook group named "WeBoro&nbsp; Inc." and (ii) invite your Facebook friends to join the Facebook group named "WeBoro&nbsp; Inc." All such Facebook transmissions using your Facebook password are automatically performed over a secure, encrypted connection. The Website does not store your Facebook password.</span></p>
<h6><span style={{fontWeight:'bold',color:'black',fontSize:15}}>Information sharing</span></h6>
<p><span style={{fontWeight:400}}>We will share your personal information with third parties only in the ways that are described in this Privacy Policy. Except as set forth below, we do not sell, share, rent or trade your personal information to third parties for their promotional purposes.</span></p>
<p><span style={{fontWeight:400}}>We may provide your personal information to companies that provide services to help us with our business activities such as shipping your order or offering customer service. These companies are authorized to use your personal information only as necessary to provide these services to us.</span></p>
<p><span style={{fontWeight:400}}>We may also disclose your personal information</span></p>
<ul>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ in response to lawful requests by public authorities, including to meet national security or law enforcement requirements.</span></li>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ as required by law such as to comply with a subpoena, or similar legal process;</span></li>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a government request;</span></li>
</ul>
<ul>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ if WeBoro&nbsp; is involved in a merger, acquisition, or sale of all or a portion of its assets, you will be notified via email and/or a prominent notice on our Website of any change in ownership; or</span></li>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ to any other third party with your prior consent to do so.</span></li>
</ul>
<p><span style={{fontWeight:400}}>We may share your personal information with other companies so that they can market their products or services to you. If you do not want us to share your personal information with these companies, contact us at </span><span style={{fontWeight:400}}>privacy@WeBoro .co</span><span style={{fontWeight:400}}>.</span></p>
<p><span style={{fontWeight:400}}>We make your contact information available to other users that you transact with on this Website to help complete the transaction.</span></p>
<h6><span style={{fontWeight:'bold',color:'black',fontSize:15}}>Links</span></h6>
<p><span style={{fontWeight:400}}>The Website may contain links to other websites. The Website is of course not responsible for the privacy practices of other websites. The Website encourages users to be aware when they leave the Website to read the privacy policy of each and every website that collects personal information. This Privacy Policy applies solely to information collected by the Website.</span></p>
<h6><span style={{fontWeight:'bold',color:'black',fontSize:15}}>Blog</span></h6>
<p><span style={{fontWeight:400}}>Our Website offers publicly accessible blogs or community forums. You should be aware that any information you provide in these areas may be read, collected, and used by others who access them. To request removal of your personal information from our blog or community forum, contact us at </span><span style={{fontWeight:400}}>privacy@WeBoro .co</span><span style={{fontWeight:400}}>. In some cases, we may not be able to remove your personal information, in which case we will let you know if we are unable to do so and why.</span></p>
<h6><span style={{fontWeight:'bold',color:'black',fontSize:15}}>Changing or removing information</span></h6>
<p><span style={{fontWeight:400}}>Upon request, WeBoro will provide you with information about whether we hold any of your personal information. To request this information please complete this form, contact us at </span><span style={{fontWeight:400}}>privacy@WeBoro .co</span><span style={{fontWeight:400}}> with a link to the identified material/content. We will respond to your request within 5 business days.</span></p>
<p>&nbsp;</p>
<h6><span style={{fontWeight:'bold',color:'black',fontSize:15}}>Protecting information and security</span></h6>
<p><span style={{fontWeight:400}}>The Website takes appropriate precautions to protect our users' information. Your account information is located on a secured server behind a firewall. Because email and instant messaging are not recognized as secure communications, the Website requests that you not send private information to us by email.</span></p>
<p><span style={{fontWeight:400}}>The security of your personal information is important to us. When you enter sensitive information (such as a credit card number) on our order forms, we encrypt the transmission of that information using secure socket layer technology (SSL).</span></p>
<p><span style={{fontWeight:400}}>We follow generally accepted standards to protect the personal information submitted to us, both during transmission and once we receive it. No method of transmission over the Internet, or method of electronic storage, is 100% secure, however. Therefore, we cannot guarantee its absolute security. If you have any questions about security on our Website, you can contact us at </span><span style={{fontWeight:400}}>privacy@WeBoro .co</span><span style={{fontWeight:400}}>.</span></p>
<h6><span style={{fontWeight:'bold',color:'black',fontSize:15}}>Data retention</span></h6>
<p><span style={{fontWeight:400}}>We will retain your information for as long as your account is active or as needed to provide you services. If you wish to cancel your account or request that we no longer use your information to provide you services contact us at </span><span style={{fontWeight:400}}>privacy@WeBoro .co</span><span style={{fontWeight:400}}>. We will retain and use your information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.</span></p>
<h6><span style={{fontWeight:'bold',color:'black',fontSize:15}}>Referrals</span></h6>
<p><span style={{fontWeight:400}}>If you choose to use our referral service to tell a friend about our Website, we will ask you for your friend&rsquo;s name and email address. We will automatically send your friend a one-time email inviting him or her to visit the Website. WeBoro&nbsp; stores this information for the sole purpose of sending this one-time email and tracking the success of our referral program.</span></p>
<p><span style={{fontWeight:400}}>Your friend may contact us at </span><span style={{fontWeight:400}}>privacy@WeBoro .co</span><span style={{fontWeight:400}}> to request that we remove this information from our database.</span></p>
<h6><span style={{fontWeight:'bold',color:'black',fontSize:15}}>Social media widgets</span></h6>
<p><span style={{fontWeight:400}}>Our Website includes Social Media Features, such as the Facebook Like button and Widgets, such as the Share this button or interactive mini-programs that run on our Website. These Features may collect your IP address, which page you are visiting on our Website, and may set a cookie to enable the Feature to function properly. Social Media Features and Widgets are either hosted by a third party or hosted directly on our Website. Your interactions with these Features are governed by the privacy policy of the company providing it.</span></p>
<h6><span style={{fontWeight:'bold',color:'black',fontSize:15}}>Single sign on</span></h6>
<p><span style={{fontWeight:400}}>You can log in to our Website using sign-in services such as Facebook Connect or an Open ID provider. These services will authenticate your identity and provide you the option to share certain personal information with us such as your name and email address to pre-populate our sign up form.&nbsp; Services like Facebook Connect give you the option to post information about your activities on this Website to your profile page to share with others within your network.</span></p>
<h6><span style={{fontWeight:'bold',color:'black',fontSize:15}}>Public profile</span></h6>
<p><span style={{fontWeight:400}}>The profile you create on our Website will be publicly accessible unless otherwise indicated. You may change the privacy settings of your profile through your account portal. If you wish to request removal of your information from our directory, you can contact us at </span><span style={{fontWeight:400}}>privacy@WeBoro .co</span><span style={{fontWeight:400}}>.</span></p>
<h6><span style={{fontWeight:'bold',color:'black',fontSize:15}}>Unauthorized profile</span></h6>
<p><span style={{fontWeight:400}}>To remove unauthorized profiles, please send an email to </span><span style={{fontWeight:400}}>privacy@WeBoro .co</span><span style={{fontWeight:400}}>.</span></p>
<h6><span style={{fontWeight:'bold',color:'black',fontSize:15}}>Changes in this privacy policy</span></h6>
<p><span style={{fontWeight:400}}>If we decide to change our Privacy Policy, we will alert you to changes by email and/or by placing a notice on our home page and other places we deem appropriate so that you are aware of what information we collect, how we use it, and under what circumstances, if any, we disclose it.</span></p>
<p><span style={{fontWeight:400}}>We reserve the right to modify this Privacy Policy at any time, so please review it frequently.</span></p>
<h6><span style={{fontWeight:'bold',color:'black',fontSize:15}}>Age restriction</span></h6>
<p><span style={{fontWeight:400}}>If you are under 13 years of age, then please do not use or access the Website at any time or in any manner. If WeBoro&nbsp; learns that personal information of persons under 13 years of age has been collected through the Website, then WeBoro&nbsp; will take the appropriate steps to delete this information.</span></p>
<h6><span style={{fontWeight:'bold',color:'black',fontSize:15}}>Contact us</span></h6>
<p><span style={{fontWeight:400}}>WeBoro , Inc.</span></p>
<p><span style={{fontWeight:400}}>6 Liberty Square,&nbsp;</span></p>
<p><span style={{fontWeight:400}}>Boston,MA, 02109&nbsp;</span></p>
<p><span style={{fontWeight:400}}>617-315-8646</span></p>
<p><span style={{fontWeight:400}}>info@weboro.co</span></p>
<h5><span style={{fontWeight:400}}>EU Residents</span></h5>
<p><span style={{fontWeight:400}}>If you are a resident of the European Union (&ldquo;EU&rdquo;), United Kingdom, Lichtenstein, Norway, or Iceland, you may have additional rights under the EU General Data Protection Regulation (the &ldquo;GDPR&rdquo;) with respect to your Personal Data, as outlined below.</span></p>
<p><span style={{fontWeight:400}}>For this section, we use the terms &ldquo;Personal Data&rdquo; and &ldquo;processing&rdquo; as they are defined in the GDPR, but &ldquo;Personal Data&rdquo; generally means information that can be used to individually identify a person, and &ldquo;processing&rdquo; generally covers actions that can be performed in connection with data such as collection, use, storage and disclosure. Company will be the controller of your Personal Data processed in connection with the Website.</span></p>
<p><span style={{fontWeight:400}}>Where applicable, this GDPR Privacy Notice is intended to supplement, and not replace, the Company&rsquo;s Privacy Policy. If there are any conflicts between this section and any other provision of this Privacy Policy, the policy or portion that is more protective of Personal Data shall control to the extent of such conflict. If you have any questions about this section or whether any of the following applies to you, please contact us at </span><span style={{fontWeight:400}}>privacy@WeBoro .co</span><span style={{fontWeight:400}}>.</span></p>
<p><span style={{fontWeight:400}}>What Personal Data Do We Collect From You? We collect Personal Data about you when you provide such information directly to us, when third parties such as our business partners or service providers provide us with Personal Data about you, or when Personal Data about you is automatically collected in connection with your use of our Website.</span></p>
<p><em><span style={{fontWeight:400}}>Information we collect directly from you</span></em><span style={{fontWeight:400}}>: We receive Personal Data directly from you when you provide us with such Personal Data. For example, you provide us with your username, email address, and/or Facebook user ID when you login into the Website. When you pay for certain WeBoro&nbsp; features, you provide us with Personal Data such as your name, credit card information, PayPal information, address, and other payment related information. If you populate your WeBoro&nbsp; profile, you might provide us information including your first and last name, phone number, gender, year of birth, graduation year, academic major, profile picture, and current and past school and course information. Finally, if you receive payments from WeBoro , you would provide us with additional Personal Data including your tax address and tax identification number.</span></p>
<p><em><span style={{fontWeight:400}}>Information we automatically collect when you use our Website</span></em><span style={{fontWeight:400}}>: When you use the Website, we automatically collect some information such as your IP address, browser and computer information such as browser type, version, operating system, time zone setting, cookie number, and your device ID. We collect information about your usage of WeBoro&nbsp; such as the pages or content you view, your searches, and other actions.</span></p>
<p><span style={{fontWeight:400}}>How Do We Use Your Personal Data? We process Personal Data to operate, improve, understand and personalize our Website. For example, we use Personal Data to:</span></p>
<ul>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ Create and manage user profiles</span></li>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ Communicate with you about the Website</span></li>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ Process orders</span></li>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ Contact you about Service announcements, updates or offers</span></li>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ Provide support and assistance for the Website</span></li>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ Improve and/or personalize website content and communications</span></li>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ Meet contract or legal obligations</span></li>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ Respond to user inquiries</span></li>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ Fulfill user requests</span></li>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ Comply with our legal or contractual obligations</span></li>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ Resolve disputes</span></li>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ Protect against or deter fraudulent, illegal or harmful actions</span></li>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ Enforce our Terms of Service</span></li>
</ul>
<p><span style={{fontWeight:400}}>We will only process your Personal Data if we have a lawful basis for doing so. Lawful bases for processing include consent, contractual necessity and our &ldquo;legitimate interests&rdquo; or the legitimate interest of others, as further described below.</span></p>
<ul>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ Contractual Necessity: We process Personal Data as a matter of &ldquo;contractual necessity&rdquo;, meaning that we need to process the data to perform under our Terms of Use with you, which enables us to provide you with the Website. When we process data due to contractual necessity, failure to provide such Personal Data will result in your inability to use some or all portions of the Website that require such data.</span></li>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ Legitimate Interest: We process the other Personal Data listed above when we believe it furthers the legitimate interest of us or third parties.</span><span style={{fontWeight:400}}><br /></span><span style={{fontWeight:400}}>Examples of these legitimate interests include:</span></li>
<ul>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ Operation and improvement of our business, products and services</span></li>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ Marketing of our products and services</span></li>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ Provision of customer support</span></li>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ Protection from fraud or security threats</span></li>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ Compliance with legal obligations</span></li>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ Completion of corporate transactions</span></li>
</ul>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ Consent: In some cases, we process Personal Data based on the consent you expressly grant to us at the time we collect such data. When we process Personal Data based on your consent, it will be expressly indicated to you at the point and time of collection.</span></li>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ Other Processing Grounds: From time to time we may also need to process Personal Data to comply with a legal obligation, if it is necessary to protect the vital interests of you or other data subjects, or if it is necessary for a task carried out in the public interest.</span></li>
</ul>
<p><span style={{fontWeight:400}}>How and With Whom Do We Share Your Data? We share Personal Data with vendors, third party service providers and agents who work on our behalf and provide us with services related to the purposes described in this Privacy Policy or our Terms of Service. These parties may include:</span></p>
<ul>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ Payment processors</span></li>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ Fraud prevention service providers</span></li>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ Ad networks</span></li>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ Analytics service providers</span></li>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ Staff augmentation and contract personnel</span></li>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ Hosting service providers</span></li>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ Co-location service providers</span></li>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ Telecommunications service providers</span></li>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ Financial and tax audit service providers</span></li>
</ul>
<p><span style={{fontWeight:400}}>We also share Personal Data when necessary to complete a transaction initiated or authorized by you or provide you with a product or service you have requested. In addition to those set forth above, these parties also include:</span></p>
<ul>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ Other users (where you post information publicly or as otherwise necessary to effect a transaction initiated or authorized by you through the Website)</span></li>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ Social media services (if you interact with them through your use of the Website)</span></li>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ Third party business partners who you access through the Website</span></li>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ Other parties authorized by you</span></li>
</ul>
<p><span style={{fontWeight:400}}>We also share Personal Data when we believe it is necessary to:</span></p>
<ul>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ Comply with applicable law or respond to valid legal process, including from law enforcement or other government agencies</span></li>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ Protect us, our business or our users, for example to enforce our terms of service, prevent spam or other unwanted communications and investigate or protect against fraud</span></li>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ Maintain the security of our products and services</span></li>
</ul>
<p><span style={{fontWeight:400}}>We also share information with third parties when you give us consent to do so.</span></p>
<p><span style={{fontWeight:400}}>Last, we share Personal Data with our affiliates or other members of our corporate family. Furthermore, if we choose to buy or sell assets, user information is typically one of the transferred business assets. Moreover, if we, or substantially all of our assets, were acquired, or if we go out of business or enter bankruptcy, user information would be one of the assets that is transferred or acquired by a third party, and we would share Personal Data with the party that is acquiring our assets. You acknowledge that such transfers may occur, and that any acquirer of us or our assets may continue to use your Personal Information as set forth in this policy.</span></p>
<p><span style={{fontWeight:400}}>How Long Do We Retain Your Personal Data? We retain Personal Data about you for as long as you have an open account with us or as otherwise necessary to provide you the Website. In some cases we retain Personal Data for longer, if doing so is necessary to comply with our legal obligations, resolve disputes or collect fees owed, or is otherwise permitted or required by applicable law, rule or regulation. Afterwards, we retain some information in a depersonalized or aggregated form but not in a way that would identify you personally.</span></p>
<p><span style={{fontWeight:400}}>What Security Measures Do We Use? We seek to protect Personal Data using appropriate technical and organizational measures based on the type of Personal Data and applicable processing activity.</span></p>
<p><span style={{fontWeight:400}}>Personal Data of Children: As noted in the </span><a href="https://www.weboro.co/terms-of-use/"><span style={{fontWeight:400}}>Terms of Use</span></a><span style={{fontWeight:400}}>, we do not knowingly collect or solicit Personal Data from anyone under the age of 13. If you are under 13, please do not attempt to register for the Website or send any Personal Data about yourself to us. If we learn that we have collected Personal Data from a child under age 13, we will delete that information as quickly as possible. If you believe that a child under 13 may have provided us Personal Data, please contact us at </span><span style={{fontWeight:400}}>privacy@WeBoro .co</span><span style={{fontWeight:400}}>.</span></p>
<p><span style={{fontWeight:400}}>What Rights Do You Have Regarding Your Personal Data? You have certain rights with respect to your Personal Data, including those set forth below. Please note that in some circumstances, we may not be able to fully comply with your request, such as if it is frivolous or extremely impractical, if it jeopardizes the rights of others, or if it is not required by law, but in those circumstances, we will still respond to notify you of such a decision. In some cases, we may also need to you to provide us with additional information, which may include Personal Data, if necessary to verify your identity and the nature of your request.</span></p>
<ul>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ Access: You can request more information about the Personal Data we hold about you and request a copy of such Personal Data. You can also access certain of your Personal Data by emailing </span><span style={{fontWeight:400}}>privacy@WeBoro .co</span><span style={{fontWeight:400}}>.</span></li>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ Rectification: If you believe that any Personal Data we are holding about you is incorrect or incomplete, you can request that we correct or supplement such data. You can correct some of this information directly by emailing </span><span style={{fontWeight:400}}>privacy@WeBoro .co</span><span style={{fontWeight:400}}>.</span></li>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ Erasure: You can request that we erase some or all of your Personal Data from our systems.</span></li>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ Withdrawal of Consent: If we are processing your Personal Data based on your consent (as indicated at the time of collection of such data), you have the right to withdraw your consent at any time. Please note, however, that if you exercise this right, you may have to then provide express consent on a case-by-case basis for the use or disclosure of certain of your Personal Data, if such use or disclosure is necessary to enable you to utilize some or all of our Website.</span></li>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ Portability: You can ask for a copy of your Personal Data in a machine-readable format. You can also request that we transmit the data to another controller where technically feasible.</span></li>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ Objection: You can contact us to let us know that you object to the further use or disclosure of your Personal Data for certain purposes, such as for direct marketing purposes.</span></li>
<li style={{fontWeight:400}}><span style={{fontWeight:400,marginLeft:35}}>  ◘ Restriction of Processing: You can ask us to restrict further processing of your Personal Data. Right to File Complaint: You have the right to lodge a complaint about Company&rsquo;s practices with respect to your Personal Data with the supervisory authority of your country or EU Member State.</span></li>
</ul>
<p><span style={{fontWeight:400}}>Transfers of Personal Data: The Website is hosted and operated in the United States (&ldquo;U.S.&rdquo;) through Company and its service providers, and if you do not reside in the U.S., laws in the U.S. may differ from the laws where you reside. By using the Website, you acknowledge that any Personal Data about you, regardless of whether provided by you or obtained from a third party, is being provided to Company in the U.S. and will be hosted on U.S. servers, and you authorize Company to transfer, store and process your information to and in the U.S., and possibly other countries. You hereby consent to the transfer of your data to the U.S. pursuant to EU-U.S. and Swiss-U.S. Privacy Shield Framework, the details of which are further set forth below.</span></p>
<p><span style={{fontWeight:400}}>Company has certified to the EU-U.S. and Swiss-U.S. Privacy Shield Framework set forth by the U.S. Department of Commerce regarding the collection and use of Personal Data transferred from the EU, the United Kingdom, and/or Switzerland. For more information about the Privacy Shield Program, and to view Company&rsquo;s certification, please visit www.privacyshield.gov. Company is committed to the Privacy Shield Principles of (1) notice, (2) consent, (3) accountability for onward transfer, (4) security, (5) data integrity and purpose limitation, (6) access and (7) recourse, enforcement and liability with respect to all Personal Data received from within the EU in reliance on the Privacy Shield. The Privacy Shield Principles require that we remain potentially liable if any third party processing Personal Data on our behalf fails to comply with these Privacy Shield Principles (except to the extent we are not responsible for the event giving rise to any alleged damage). Company&rsquo;s compliance with the Privacy Shield is subject to the investigatory and enforcement powers of the U.S. Federal Trade Commission.</span></p>
<p><span style={{fontWeight:400}}>Under certain conditions, you may also be entitled to invoke binding arbitration for complaints not resolved by other means.</span></p>
<p><span style={{fontWeight:400}}>What If You Have Questions Regarding Your Personal Data? If you have any questions about this section or our data practices generally, please contact us using the following information:</span></p>
<p>&nbsp;</p>
          </div>
          {/* privacy contact  */}
          <FooterSection />
        </div>
        
      </React.Fragment>
    );
  }
}

export default Theme;
