import * as firebase from 'firebase/app';
import React from "react";
import firebaseConfig from '../HeroSection/firebaseConfig';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/firebase-database'
import _data from "../../data";
import Button from 'react-bootstrap/Button';

class TeamMember extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayName:'',
      email:'',
      emailVerified:'',
      password:'',
      cpassword:''
    };
  }

  changePassword = () =>{

    var user = firebase.auth().currentUser;

    if(this.state.password === '' || this.state.password === '')
    {
      alert("Please Enter Valid Password !");
    }
    else
    {
      if(this.state.password !== this.state.cpassword)
      {
        alert("Please enter same password in both fields")
      }
      else {

        user.updatePassword(this.state.password).then(function() {
          alert("Password updated Successfully , Login again using New Password")
          firebase.auth().signOut();
        }).catch(function(error) {
          alert(error)
        });
        
      }
    }
  }
  changeEmail = () => {
    var user = firebase.auth().currentUser;
    if(this.state.email === '')
    {
      alert("Please Enter a Valid Email Address ");
    }
    else
    {
      if(this.state.email === user.email)
      {
        alert("This email is already being used , please enter a different to update ")
      }
      else
      {
        user.updateEmail(this.state.email).then(function() {
          alert("Email Updated Successfully , Login again with New Email Address ")
          firebase.auth().signOut();
        }).catch(function(error) {
          alert(error)
        });
      }
    }
  }
  sendVerification = () => {
    var user = firebase.auth().currentUser;

    user.sendEmailVerification().then(function() {
      alert("Email Verification Link Has Been Sent to your Email Address")
    }).catch(function(error) {
       alert(error)
    });
  }
   componentDidMount() {
    var user = firebase.auth().currentUser;
    firebase.auth().onAuthStateChanged( (user) => {
      if (user) { 
        console.log(user.displayName)
        this.setState({
          displayName:user.displayName,
          email:user.email,
          emailVerified:user.emailVerified,

        })
      
      }
      else
      {
        window.location.href = "/login1"
      }
    });

   

    console.log(user)
    this.setState({
      teamMember: _data.teamMember,
    });
  }

  render() {
    return (
      <React.Fragment>
        <section className="team-single-section ptb-100">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12 col-sm-12 col-lg-5">
                <div className="team-single-img">
                  <img
                    src="img/profile.png"
                    alt="member"
                    className="img-fluid rounded shadow-sm"
                  />
                </div>
              </div>
              <div className="col-md-12 col-sm-12 col-lg-6">
                <div className="team-single-text">
                  <div className="team-name mb-4">
                    <h4 className="mb-1">{this.state.displayName}</h4>
                    
                  </div>
                  <ul className="team-single-info">
                  <li>
                      <strong>Email Status  : </strong>
                      <span> {this.state.emailVerified ? "Verified" : 
                      
                      <span> Not Verified  <a onClick={(e) => this.sendVerification()} className="btn btn-primary btn-sm" style={{color:'white'}}>Verify Now</a></span>}</span>
                    </li>
                  <li>
                      <strong>Email Address : </strong>
                       <input
                            value={this.state.email}
                            onChange={e => this.setState({email:e.target.value})}
                            type="email"
                            name="email"
                            className="form-control"
                            placeholder="Enter New EMail Address"
                            required
                          />
                    
                    </li>

                 

                    
                  </ul>
                  <Button onClick={(e)=> this.changeEmail()} className="btn btn-primary btn-block">Change Email Address </Button>
                      
                      <br/>
                  <div className="text-content mt-20">
                    <hr/>

                    <div className="form-group">
                        <div className="row">
                          <div className="col">
                            <label className="pb-1">New Password</label>
                          </div>
                          
                        </div>
                        <div className="input-group input-group-merge">
                         
                          <input
                            value={this.state.password}
                            onChange={e => {this.setState({password:e.target.value})}}
                            type="password"
                            name="password"
                            className="form-control"
                            placeholder="Enter New password"
                            required
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <div className="col">
                            <label className="pb-1">Confirm New Password</label>
                          </div>
                          
                        </div>
                        <div className="input-group input-group-merge">
                         
                          <input
                            value={this.state.cpassword}
                            onChange={e => {this.setState({cpassword:e.target.value})}}
                            type="password"
                            name="cpassword"
                            className="form-control"
                            placeholder="Confirm New password"
                            required
                          />
                        </div>
                              <br/>
                        <Button onClick={(e)=> this.changePassword()} className="btn btn-primary btn-block">Change Password </Button>
                      </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default TeamMember;