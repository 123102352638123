const OPTIONS_SCHOOLS = [{value:'ALL',label:'All Schools'},
    {value : 'A.T. Still University', label : 'A.T. Still University'} ,
{value : 'Abilene Christian University', label : 'Abilene Christian University'} ,
{value : 'Abraham Baldwin Agricultural College', label : 'Abraham Baldwin Agricultural College'} ,
{value : 'Academy of Art University', label : 'Academy of Art University'} ,
{value : 'Adams State University', label : 'Adams State University'} ,
{value : 'Adelphi University', label : 'Adelphi University'} ,
{value : 'Adler Graduate School', label : 'Adler Graduate School'} ,
{value : 'Adler University', label : 'Adler University'} ,
{value : 'Adrian College', label : 'Adrian College'} ,
{value : 'AdventHealth University', label : 'AdventHealth University'} ,
{value : 'Agnes Scott College', label : 'Agnes Scott College'} ,
{value : 'Air Force Institute of Technology', label : 'Air Force Institute of Technology'} ,
{value : 'Alabama A&M University', label : 'Alabama A&M University'} ,
{value : 'Alabama State University', label : 'Alabama State University'} ,
{value : 'Alaska Bible College', label : 'Alaska Bible College'} ,
{value : 'Alaska Pacific University', label : 'Alaska Pacific University'} ,
{value : 'Albany College of Pharmacy and Health Sciences', label : 'Albany College of Pharmacy and Health Sciences'} ,
{value : 'Albany Law School', label : 'Albany Law School'} ,
{value : 'Albany Medical College', label : 'Albany Medical College'} ,
{value : 'Albany State University', label : 'Albany State University'} ,
{value : 'Albertus Magnus College', label : 'Albertus Magnus College'} ,
{value : 'Albion College', label : 'Albion College'} ,
{value : 'Albright College', label : 'Albright College'} ,
{value : 'Alcorn State University', label : 'Alcorn State University'} ,
{value : 'Alderson Broaddus University', label : 'Alderson Broaddus University'} ,
{value : 'Alfred State College', label : 'Alfred State College'} ,
{value : 'Alfred University', label : 'Alfred University'} ,
{value : 'Alice Lloyd College', label : 'Alice Lloyd College'} ,
{value : 'Allegheny College', label : 'Allegheny College'} ,
{value : 'Allen College', label : 'Allen College'} ,
{value : 'Allen University', label : 'Allen University'} ,
{value : 'Alliant International University', label : 'Alliant International University'} ,
{value : 'Alma College', label : 'Alma College'} ,
{value : 'Alvernia University', label : 'Alvernia University'} ,
{value : 'Alverno College', label : 'Alverno College'} ,
{value : 'Amberton University', label : 'Amberton University'} ,
{value : 'American Baptist College', label : 'American Baptist College'} ,
{value : 'American Film Institute Conservatory', label : 'American Film Institute Conservatory'} ,
{value : 'American International College', label : 'American International College'} ,
{value : 'American Jewish University', label : 'American Jewish University'} ,
{value : 'American University', label : 'American University'} ,
{value : 'Amherst College', label : 'Amherst College'} ,
{value : 'Anderson University', label : 'Anderson University'} ,
{value : 'Anderson University, ', label : 'Anderson University, '} ,
{value : 'Andrews University', label : 'Andrews University'} ,
{value : 'Angelo State University', label : 'Angelo State University'} ,
{value : 'Anna Maria College', label : 'Anna Maria College'} ,
{value : 'Antioch University', label : 'Antioch University'} ,
{value : 'Antioch University ', label : 'Antioch University '} ,
{value : 'Antioch University ', label : 'Antioch University '} ,
{value : 'Antioch University ', label : 'Antioch University '} ,
{value : 'Antioch University ', label : 'Antioch University '} ,
{value : 'Appalachian Bible College', label : 'Appalachian Bible College'} ,
{value : 'Appalachian College of Pharmacy', label : 'Appalachian College of Pharmacy'} ,
{value : 'Appalachian State University', label : 'Appalachian State University'} ,
{value : 'Aquinas College', label : 'Aquinas College'} ,
{value : 'Aquinas College, ', label : 'Aquinas College, '} ,
{value : 'Arcadia University', label : 'Arcadia University'} ,
{value : 'Arizona Christian University', label : 'Arizona Christian University'} ,
{value : 'Arizona State University', label : 'Arizona State University'} ,
{value : 'Arkansas Baptist College', label : 'Arkansas Baptist College'} ,
{value : 'Arkansas State University', label : 'Arkansas State University'} ,
{value : 'Arkansas Tech University', label : 'Arkansas Tech University'} ,
{value : 'Arlington Baptist University', label : 'Arlington Baptist University'} ,
{value : 'Art Academy of Cincinnati', label : 'Art Academy of Cincinnati'} ,
{value : 'ArtCenter College of Design', label : 'ArtCenter College of Design'} ,
{value : 'Asbury University', label : 'Asbury University'} ,
{value : 'Ashland University', label : 'Ashland University'} ,
{value : 'Assumption College', label : 'Assumption College'} ,
{value : 'Athens State University', label : 'Athens State University'} ,
{value : 'Atlanta Metropolitan State College', label : 'Atlanta Metropolitan State College'} ,
{value : 'Auburn University', label : 'Auburn University'} ,
{value : 'Auburn University ', label : 'Auburn University '} ,
{value : 'Augsburg College', label : 'Augsburg College'} ,
{value : 'Augusta University', label : 'Augusta University'} ,
{value : 'Augustana College', label : 'Augustana College'} ,
{value : 'Augustana University', label : 'Augustana University'} ,
{value : 'Aurora University', label : 'Aurora University'} ,
{value : 'Austin CollegeSherman', label : 'Austin CollegeSherman'} ,
{value : 'Austin Peay State University', label : 'Austin Peay State University'} ,
{value : 'Ave Maria University', label : 'Ave Maria University'} ,
{value : 'Averett University', label : 'Averett University'} ,
{value : 'Avila University', label : 'Avila University'} ,
{value : 'Azusa Pacific University', label : 'Azusa Pacific University'} ,
{value : 'Babson College', label : 'Babson College'} ,
{value : 'Bacone College', label : 'Bacone College'} ,
{value : 'Baker College', label : 'Baker College'} ,
{value : 'Baker University', label : 'Baker University'} ,
{value : 'Baldwin Wallace University', label : 'Baldwin Wallace University'} ,
{value : 'Ball State University', label : 'Ball State University'} ,
{value : 'Bank Street College of Education', label : 'Bank Street College of Education'} ,
{value : 'Baptist Bible College', label : 'Baptist Bible College'} ,
{value : 'Baptist Memorial College of Health Sciences', label : 'Baptist Memorial College of Health Sciences'} ,
{value : 'Baptist University of the Americas', label : 'Baptist University of the Americas'} ,
{value : 'Barclay College', label : 'Barclay College'} ,
{value : 'Bard College', label : 'Bard College'} ,
{value : 'Bard College ', label : 'Bard College '} ,
{value : 'Barnard College', label : 'Barnard College'} ,
{value : 'Barnes-Jewish College Goldfarb School of Nursing', label : 'Barnes-Jewish College Goldfarb School of Nursing'} ,
{value : 'Barry University', label : 'Barry University'} ,
{value : 'Barton College', label : 'Barton College'} ,
{value : 'Baruch College,', label : 'Baruch College,'} ,
{value : 'Bastyr University', label : 'Bastyr University'} ,
{value : 'Bates College', label : 'Bates College'} ,
{value : 'Bay Path University', label : 'Bay Path University'} ,
{value : 'Bay State College', label : 'Bay State College'} ,
{value : 'Baylor College of Medicine', label : 'Baylor College of Medicine'} ,
{value : 'Baylor University', label : 'Baylor University'} ,
{value : 'Beacon College', label : 'Beacon College'} ,
{value : 'Becker College', label : 'Becker College'} ,
{value : 'Belhaven University', label : 'Belhaven University'} ,
{value : 'Bellarmine University', label : 'Bellarmine University'} ,
{value : 'Bellevue College', label : 'Bellevue College'} ,
{value : 'Bellevue University', label : 'Bellevue University'} ,
{value : 'Bellin College', label : 'Bellin College'} ,
{value : 'Belmont Abbey College', label : 'Belmont Abbey College'} ,
{value : 'Belmont University', label : 'Belmont University'} ,
{value : 'Beloit College', label : 'Beloit College'} ,
{value : 'Bemidji State University', label : 'Bemidji State University'} ,
{value : 'Benedict College', label : 'Benedict College'} ,
{value : 'Benedictine College', label : 'Benedictine College'} ,
{value : 'Benedictine University', label : 'Benedictine University'} ,
{value : 'Benjamin Franklin Institute of Technology', label : 'Benjamin Franklin Institute of Technology'} ,
{value : 'Bennett College', label : 'Bennett College'} ,
{value : 'Bennington College', label : 'Bennington College'} ,
{value : 'Bentley University', label : 'Bentley University'} ,
{value : 'Berea CollegeBerea', label : 'Berea CollegeBerea'} ,
{value : 'Berkeley College', label : 'Berkeley College'} ,
{value : 'Berklee College of Music', label : 'Berklee College of Music'} ,
{value : 'Berry College', label : 'Berry College'} ,
{value : 'Bethany College', label : 'Bethany College'} ,
{value : 'Bethany College, ', label : 'Bethany College, '} ,
{value : 'Bethany Lutheran College', label : 'Bethany Lutheran College'} ,
{value : 'Bethel College', label : 'Bethel College'} ,
{value : 'Bethel University', label : 'Bethel University'} ,
{value : 'Bethel University', label : 'Bethel University'} ,
{value : 'Bethel University, ', label : 'Bethel University, '} ,
{value : 'Bethesda University', label : 'Bethesda University'} ,
{value : 'Bethune-Cookman University', label : 'Bethune-Cookman University'} ,
{value : 'Beulah Heights University', label : 'Beulah Heights University'} ,
{value : 'Binghamton University, State University of New York', label : 'Binghamton University, State University of New York'} ,
{value : 'Biola University', label : 'Biola University'} ,
{value : 'Birmingham-Southern College', label : 'Birmingham-Southern College'} ,
{value : 'Bismarck State College', label : 'Bismarck State College'} ,
{value : 'Black Hills State University', label : 'Black Hills State University'} ,
{value : 'Blackburn College', label : 'Blackburn College'} ,
{value : 'Blessing-Rieman College of Nursing', label : 'Blessing-Rieman College of Nursing'} ,
{value : 'Bloomfield College', label : 'Bloomfield College'} ,
{value : 'Bloomsburg University', label : 'Bloomsburg University'} ,
{value : 'Blue Mountain College', label : 'Blue Mountain College'} ,
{value : 'Bluefield College', label : 'Bluefield College'} ,
{value : 'Bluefield State College', label : 'Bluefield State College'} ,
{value : 'Bluffton University', label : 'Bluffton University'} ,
{value : 'Bob Jones University', label : 'Bob Jones University'} ,
{value : 'Boise Bible College', label : 'Boise Bible College'} ,
{value : 'Boise State University', label : 'Boise State University'} ,
{value : 'Boricua College', label : 'Boricua College'} ,
{value : 'Boston Architectural College', label : 'Boston Architectural College'} ,
{value : 'Boston College', label : 'Boston College'} ,
{value : 'Boston Graduate School of Psychoanalysis', label : 'Boston Graduate School of Psychoanalysis'} ,
{value : 'Boston University', label : 'Boston University'} ,
{value : 'Bowdoin College', label : 'Bowdoin College'} ,
{value : 'Bowie State University', label : 'Bowie State University'} ,
{value : 'Bowling Green State University', label : 'Bowling Green State University'} ,
{value : 'Bradley University', label : 'Bradley University'} ,
{value : 'Brandeis University', label : 'Brandeis University'} ,
{value : 'Brandman University', label : 'Brandman University'} ,
{value : 'Brazosport College', label : 'Brazosport College'} ,
{value : 'Brenau University', label : 'Brenau University'} ,
{value : 'Brescia University', label : 'Brescia University'} ,
{value : 'Brevard College', label : 'Brevard College'} ,
{value : 'Brewton-Parker College', label : 'Brewton-Parker College'} ,
{value : 'Briar Cliff University', label : 'Briar Cliff University'} ,
{value : 'Bridgewater College', label : 'Bridgewater College'} ,
{value : 'Bridgewater State University', label : 'Bridgewater State University'} ,
{value : 'Brigham Young University', label : 'Brigham Young University'} ,
{value : 'Brigham Young University', label : 'Brigham Young University'} ,
{value : 'Brigham Young University', label : 'Brigham Young University'} ,
{value : 'Brooklyn College', label : 'Brooklyn College'} ,
{value : 'Brooklyn Law School', label : 'Brooklyn Law School'} ,
{value : 'Broward College', label : 'Broward College'} ,
{value : 'Brown Mackie College', label : 'Brown Mackie College'} ,
{value : 'Brown University', label : 'Brown University'} ,
{value : 'Bryan College', label : 'Bryan College'} ,
{value : 'Bryan College of Health Sciences', label : 'Bryan College of Health Sciences'} ,
{value : 'Bryant and Stratton College', label : 'Bryant and Stratton College'} ,
{value : 'Bryant University', label : 'Bryant University'} ,
{value : 'Bryn Athyn College', label : 'Bryn Athyn College'} ,
{value : 'Bryn Mawr College', label : 'Bryn Mawr College'} ,
{value : 'Bucknell University', label : 'Bucknell University'} ,
{value : 'Buena Vista University', label : 'Buena Vista University'} ,
{value : 'Buffalo State College', label : 'Buffalo State College'} ,
{value : 'Butler University', label : 'Butler University'} ,
{value : 'Cabarrus College of Health Sciences', label : 'Cabarrus College of Health Sciences'} ,
{value : 'Cabrini University', label : 'Cabrini University'} ,
{value : 'Cairn University', label : 'Cairn University'} ,
{value : 'Caldwell University', label : 'Caldwell University'} ,
{value : 'California Baptist University', label : 'California Baptist University'} ,
{value : 'California College of the Arts', label : 'California College of the Arts'} ,
{value : 'California Institute of Integral Studies', label : 'California Institute of Integral Studies'} ,
{value : 'California Institute of Technology', label : 'California Institute of Technology'} ,
{value : 'California Institute of the Arts', label : 'California Institute of the Arts'} ,
{value : 'California Lutheran University', label : 'California Lutheran University'} ,
{value : 'California Polytechnic State University', label : 'California Polytechnic State University'} ,
{value : 'California State Polytechnic University', label : 'California State Polytechnic University'} ,
{value : 'California State University', label : 'California State University'} ,
{value : 'California State University Maritime Academy', label : 'California State University Maritime Academy'} ,
{value : 'California State University San Marcos', label : 'California State University San Marcos'} ,
{value : 'California State University, Bakersfield', label : 'California State University, Bakersfield'} ,
{value : 'California State University, Chico', label : 'California State University, Chico'} ,
{value : 'California State University, Dominguez Hills', label : 'California State University, Dominguez Hills'} ,
{value : 'California State University, East Bay', label : 'California State University, East Bay'} ,
{value : 'California State University, Fresno', label : 'California State University, Fresno'} ,
{value : 'California State University, Fullerton', label : 'California State University, Fullerton'} ,
{value : 'California State University, Long Beach', label : 'California State University, Long Beach'} ,
{value : 'California State University, Los Angeles', label : 'California State University, Los Angeles'} ,
{value : 'California State University, Monterey Bay', label : 'California State University, Monterey Bay'} ,
{value : 'California State University, Northridge', label : 'California State University, Northridge'} ,
{value : 'California State University, Sacramento', label : 'California State University, Sacramento'} ,
{value : 'California State University, San Bernardino', label : 'California State University, San Bernardino'} ,
{value : 'California State University, StanislausTurlock ', label : 'California State University, StanislausTurlock '} ,
{value : 'California University of Pennsylvania', label : 'California University of Pennsylvania'} ,
{value : 'California Western School of Law', label : 'California Western School of Law'} ,
{value : 'Calumet College of St. Joseph', label : 'Calumet College of St. Joseph'} ,
{value : 'Calvary University', label : 'Calvary University'} ,
{value : 'Calvin College', label : 'Calvin College'} ,
{value : 'Cambridge College', label : 'Cambridge College'} ,
{value : 'Cameron University', label : 'Cameron University'} ,
{value : 'Campbell University', label : 'Campbell University'} ,
{value : 'Campbellsville University', label : 'Campbellsville University'} ,
{value : 'Canisius College', label : 'Canisius College'} ,
{value : 'Capital University', label : 'Capital University'} ,
{value : 'Capitol Technology University', label : 'Capitol Technology University'} ,
{value : 'Cardinal Stritch University', label : 'Cardinal Stritch University'} ,
{value : 'Carleton College', label : 'Carleton College'} ,
{value : 'Carlow University', label : 'Carlow University'} ,
{value : 'Carnegie Mellon University', label : 'Carnegie Mellon University'} ,
{value : 'Carroll College', label : 'Carroll College'} ,
{value : 'Carroll University', label : 'Carroll University'} ,
{value : 'Carson-Newman University', label : 'Carson-Newman University'} ,
{value : 'Carthage College', label : 'Carthage College'} ,
{value : 'Case Western Reserve University', label : 'Case Western Reserve University'} ,
{value : 'Castleton University', label : 'Castleton University'} ,
{value : 'Catawba College', label : 'Catawba College'} ,
{value : 'Cazenovia College', label : 'Cazenovia College'} ,
{value : 'Cedar Crest College', label : 'Cedar Crest College'} ,
{value : 'Cedarville University', label : 'Cedarville University'} ,
{value : 'Centenary College of Louisiana', label : 'Centenary College of Louisiana'} ,
{value : 'Centenary University', label : 'Centenary University'} ,
{value : 'Central Baptist College', label : 'Central Baptist College'} ,
{value : 'Central Christian College of Kansas', label : 'Central Christian College of Kansas'} ,
{value : 'Central Christian College of the Bible', label : 'Central Christian College of the Bible'} ,
{value : 'Central CollegePella', label : 'Central CollegePella'} ,
{value : 'Central Connecticut State University', label : 'Central Connecticut State University'} ,
{value : 'Central Methodist University', label : 'Central Methodist University'} ,
{value : 'Central Michigan University', label : 'Central Michigan University'} ,
{value : 'Central Penn College', label : 'Central Penn College'} ,
{value : 'Central State University', label : 'Central State University'} ,
{value : 'Central Washington University', label : 'Central Washington University'} ,
{value : 'Centralia College', label : 'Centralia College'} ,
{value : 'Centre College', label : 'Centre College'} ,
{value : 'Chadron State College', label : 'Chadron State College'} ,
{value : 'Chamberlain University', label : 'Chamberlain University'} ,
{value : 'Chaminade University of Honolulu', label : 'Chaminade University of Honolulu'} ,
{value : 'Champlain College', label : 'Champlain College'} ,
{value : 'Chapman University', label : 'Chapman University'} ,
{value : 'Charles R. Drew University of Medicine and Science', label : 'Charles R. Drew University of Medicine and Science'} ,
{value : 'Charleston Southern University', label : 'Charleston Southern University'} ,
{value : 'Chatham University', label : 'Chatham University'} ,
{value : 'Chestnut Hill College', label : 'Chestnut Hill College'} ,
{value : 'Cheyney University of Pennsylvania', label : 'Cheyney University of Pennsylvania'} ,
{value : 'Chicago State University', label : 'Chicago State University'} ,
{value : 'Chipola CollegeMarianna', label : 'Chipola CollegeMarianna'} ,
{value : 'Chowan University', label : 'Chowan University'} ,
{value : 'Christian Brothers University', label : 'Christian Brothers University'} ,
{value : 'Christopher Newport University', label : 'Christopher Newport University'} ,
{value : 'Cincinnati Christian University', label : 'Cincinnati Christian University'} ,
{value : 'City University of Seattle', label : 'City University of Seattle'} ,
{value : 'Claflin University', label : 'Claflin University'} ,
{value : 'Claremont Graduate University', label : 'Claremont Graduate University'} ,
{value : 'Claremont McKenna College', label : 'Claremont McKenna College'} ,
{value : 'Clarion University of Pennsylvania', label : 'Clarion University of Pennsylvania'} ,
{value : 'Clark Atlanta University', label : 'Clark Atlanta University'} ,
{value : 'Clark University', label : 'Clark University'} ,
{value : 'Clarke University', label : 'Clarke University'} ,
{value : 'Clarks Summit University', label : 'Clarks Summit University'} ,
{value : 'Clarkson College', label : 'Clarkson College'} ,
{value : 'Clarkson University', label : 'Clarkson University'} ,
{value : 'Clayton State University', label : 'Clayton State University'} ,
{value : 'Clear Creek Baptist Bible College', label : 'Clear Creek Baptist Bible College'} ,
{value : 'Cleary University', label : 'Cleary University'} ,
{value : 'Clemson University', label : 'Clemson University'} ,
{value : 'Cleveland Institute of Art', label : 'Cleveland Institute of Art'} ,
{value : 'Cleveland Institute of Music', label : 'Cleveland Institute of Music'} ,
{value : 'Cleveland State University', label : 'Cleveland State University'} ,
{value : 'Cleveland University-Kansas City', label : 'Cleveland University-Kansas City'} ,
{value : 'Coastal Carolina University', label : 'Coastal Carolina University'} ,
{value : 'Coe CollegeCedar Rapids', label : 'Coe CollegeCedar Rapids'} ,
{value : 'Cogswell Polytechnical College', label : 'Cogswell Polytechnical College'} ,
{value : 'Coker University', label : 'Coker University'} ,
{value : 'Colby College', label : 'Colby College'} ,
{value : 'Colby-Sawyer College', label : 'Colby-Sawyer College'} ,
{value : 'Colgate University', label : 'Colgate University'} ,
{value : 'College for Creative Studies', label : 'College for Creative Studies'} ,
{value : 'College of Biblical Studies', label : 'College of Biblical Studies'} ,
{value : 'College of Central Florida', label : 'College of Central Florida'} ,
{value : 'College of Charleston', label : 'College of Charleston'} ,
{value : 'College of Coastal Georgia', label : 'College of Coastal Georgia'} ,
{value : 'College of Mount Saint Vincent', label : 'College of Mount Saint Vincent'} ,
{value : 'College of Our Lady of the Elms', label : 'College of Our Lady of the Elms'} ,
{value : 'College of Saint Benedict/Saint Johns University', label : 'College of Saint Benedict/Saint Johns University'} ,
{value : 'College of Saint Elizabeth', label : 'College of Saint Elizabeth'} ,
{value : 'College of Saint Mary', label : 'College of Saint Mary'} ,
{value : 'College of Southern Nevada', label : 'College of Southern Nevada'} ,
{value : 'College of St. Joseph', label : 'College of St. Joseph'} ,
{value : 'College of Staten Island', label : 'College of Staten Island'} ,
{value : 'College of the AtlanticBar ', label : 'College of the AtlanticBar '} ,
{value : 'College of the Holy Cross', label : 'College of the Holy Cross'} ,
{value : 'College of the Ozarks', label : 'College of the Ozarks'} ,
{value : 'College of William & Mary', label : 'College of William & Mary'} ,
{value : 'Colorado Christian University', label : 'Colorado Christian University'} ,
{value : 'Colorado College', label : 'Colorado College'} ,
{value : 'Colorado Mesa University', label : 'Colorado Mesa University'} ,
{value : 'Colorado School of Mines', label : 'Colorado School of Mines'} ,
{value : 'Colorado State University', label : 'Colorado State University'} ,
{value : 'Colorado State University-Pueblo', label : 'Colorado State University-Pueblo'} ,
{value : 'Colorado Technical University', label : 'Colorado Technical University'} ,
{value : 'Columbia Basin College', label : 'Columbia Basin College'} ,
{value : 'Columbia College', label : 'Columbia College'} ,
{value : 'Columbia College Chicago', label : 'Columbia College Chicago'} ,
{value : 'Columbia College Hollywood', label : 'Columbia College Hollywood'} ,
{value : 'Columbia College of Nursing', label : 'Columbia College of Nursing'} ,
{value : 'College, South Carolina', label : 'College, South Carolina'} ,
{value : 'Columbia International University', label : 'Columbia International University'} ,
{value : 'Columbia University in the City of New York', label : 'Columbia University in the City of New York'} ,
{value : 'Columbus College of Art and Design', label : 'Columbus College of Art and Design'} ,
{value : 'Columbus State University', label : 'Columbus State University'} ,
{value : 'Concord University', label : 'Concord University'} ,
{value : 'Concordia College', label : 'Concordia College'} ,
{value : 'Concordia College Alabama', label : 'Concordia College Alabama'} ,
{value : 'Concordia College-New York', label : 'Concordia College-New York'} ,
{value : 'Concordia University Ann Arbor', label : 'Concordia University Ann Arbor'} ,
{value : 'Concordia University ', label : 'Concordia University '} ,
{value : 'Concordia University Irvine', label : 'Concordia University Irvine'} ,
{value : 'Concordia University Texas', label : 'Concordia University Texas'} ,
{value : 'Concordia University Wisconsin', label : 'Concordia University Wisconsin'} ,
{value : 'Concordia University, Nebraska', label : 'Concordia University, Nebraska'} ,
{value : 'Concordia University, Oregon', label : 'Concordia University, Oregon'} ,
{value : 'Concordia University, St. Paul', label : 'Concordia University, St. Paul'} ,
{value : 'Connecticut College', label : 'Connecticut College'} ,
{value : 'Converse College', label : 'Converse College'} ,
{value : 'Conway School of Landscape Design', label : 'Conway School of Landscape Design'} ,
{value : 'Coppin State University', label : 'Coppin State University'} ,
{value : 'Corban University', label : 'Corban University'} ,
{value : 'Cornell CollegeMount ', label : 'Cornell CollegeMount '} ,
{value : 'Cornell University', label : 'Cornell University'} ,
{value : 'Cornerstone University', label : 'Cornerstone University'} ,
{value : 'Cornish College of the Arts', label : 'Cornish College of the Arts'} ,
{value : 'Cottey College', label : 'Cottey College'} ,
{value : 'Covenant College', label : 'Covenant College'} ,
{value : 'Cox College', label : 'Cox College'} ,
{value : 'Cranbrook Academy of Art', label : 'Cranbrook Academy of Art'} ,
{value : 'Creighton University', label : 'Creighton University'} ,
{value : 'Criswell College', label : 'Criswell College'} ,
{value : 'Crossroads College', label : 'Crossroads College'} ,
{value : 'Crowleys Ridge College', label : 'Crowleys Ridge College'} ,
{value : 'Crown College', label : 'Crown College'} ,
{value : 'Culver-Stockton College', label : 'Culver-Stockton College'} ,
{value : 'Cumberland University', label : 'Cumberland University'} ,
{value : 'CUNY School of Law', label : 'CUNY School of Law'} ,
{value : 'Curry College', label : 'Curry College'} ,
{value : 'Curtis Institute of Music', label : 'Curtis Institute of Music'} ,
{value : 'Daemen College', label : 'Daemen College'} ,
{value : 'Dakota State University', label : 'Dakota State University'} ,
{value : 'Dakota Wesleyan University', label : 'Dakota Wesleyan University'} ,
{value : 'Dallas Baptist University', label : 'Dallas Baptist University'} ,
{value : 'Dallas Christian College', label : 'Dallas Christian College'} ,
{value : 'Dalton State College', label : 'Dalton State College'} ,
{value : 'Dartmouth College', label : 'Dartmouth College'} ,
{value : 'Davenport University', label : 'Davenport University'} ,
{value : 'Davidson College', label : 'Davidson College'} ,
{value : 'Davis & Elkins College', label : 'Davis & Elkins College'} ,
{value : 'Davis CollegeJohnson ', label : 'Davis CollegeJohnson '} ,
{value : 'Daytona State College', label : 'Daytona State College'} ,
{value : 'Dean College', label : 'Dean College'} ,
{value : 'Defiance College', label : 'Defiance College'} ,
{value : 'Delaware State University', label : 'Delaware State University'} ,
{value : 'Delaware Valley University', label : 'Delaware Valley University'} ,
{value : 'Delta State University', label : 'Delta State University'} ,
{value : 'Denver College of Nursing', label : 'Denver College of Nursing'} ,
{value : 'DePaul University', label : 'DePaul University'} ,
{value : 'DePauw University', label : 'DePauw University'} ,
{value : 'Des Moines University', label : 'Des Moines University'} ,
{value : 'DeSales University', label : 'DeSales University'} ,
{value : 'DeVry University', label : 'DeVry University'} ,
{value : 'Dharma Realm Buddhist University', label : 'Dharma Realm Buddhist University'} ,
{value : 'Dickinson College', label : 'Dickinson College'} ,
{value : 'Dickinson State University', label : 'Dickinson State University'} ,
{value : 'Dillard University', label : 'Dillard University'} ,
{value : 'Dixie State University', label : 'Dixie State University'} ,
{value : 'Doane University', label : 'Doane University'} ,
{value : 'Dominican College', label : 'Dominican College'} ,
{value : 'Dominican School of Philosophy & Theology', label : 'Dominican School of Philosophy & Theology'} ,
{value : 'Dominican University', label : 'Dominican University'} ,
{value : 'Dominican University of California', label : 'Dominican University of California'} ,
{value : 'Donnelly College', label : 'Donnelly College'} ,
{value : 'Dordt University', label : 'Dordt University'} ,
{value : 'Drake University', label : 'Drake University'} ,
{value : 'Drew UniversityMadison', label : 'Drew UniversityMadison'} ,
{value : 'Drexel University', label : 'Drexel University'} ,
{value : 'Drury University', label : 'Drury University'} ,
{value : 'Duke UniversityDurham', label : 'Duke UniversityDurham'} ,
{value : 'Dunwoody College of Technology', label : 'Dunwoody College of Technology'} ,
{value : 'Duquesne University', label : 'Duquesne University'} ,
{value : 'DYouville College', label : 'D Youville College'} ,
{value : 'Earlham CollegeRichmond', label : 'Earlham CollegeRichmond'} ,
{value : 'East Carolina University', label : 'East Carolina University'} ,
{value : 'East Central UniversityAda', label : 'East Central UniversityAda'} ,
{value : 'East Georgia State College', label : 'East Georgia State College'} ,
{value : 'East Stroudsburg University', label : 'East Stroudsburg University'} ,
{value : 'East Tennessee State University', label : 'East Tennessee State University'} ,
{value : 'East Texas Baptist University', label : 'East Texas Baptist University'} ,
{value : 'Eastern Connecticut State University', label : 'Eastern Connecticut State University'} ,
{value : 'Eastern Illinois University', label : 'Eastern Illinois University'} ,
{value : 'Eastern Kentucky University', label : 'Eastern Kentucky University'} ,
{value : 'Eastern Mennonite University', label : 'Eastern Mennonite University'} ,
{value : 'Eastern Michigan University', label : 'Eastern Michigan University'} ,
{value : 'Eastern Nazarene College', label : 'Eastern Nazarene College'} ,
{value : 'Eastern New Mexico University', label : 'Eastern New Mexico University'} ,
{value : 'Eastern Oregon University', label : 'Eastern Oregon University'} ,
{value : 'Eastern University', label : 'Eastern University'} ,
{value : 'Eastern Virginia Medical ', label : 'Eastern Virginia Medical '} ,
{value : 'Eastern Washington University', label : 'Eastern Washington University'} ,
{value : 'East-West University', label : 'East-West University'} ,
{value : 'Ecclesia College', label : 'Ecclesia College'} ,
{value : 'Eckerd College', label : 'Eckerd College'} ,
{value : 'ECPI University', label : 'ECPI University'} ,
{value : 'Edgewood College', label : 'Edgewood College'} ,
{value : 'Edinboro University of Pennsylvania', label : 'Edinboro University of Pennsylvania'} ,
{value : 'Edward Waters College', label : 'Edward Waters College'} ,
{value : 'Elizabeth City State University', label : 'Elizabeth City State University'} ,
{value : 'Elizabethtown College', label : 'Elizabethtown College'} ,
{value : 'Elmhurst College', label : 'Elmhurst College'} ,
{value : 'Elmira College', label : 'Elmira College'} ,
{value : 'Elon University', label : 'Elon University'} ,
{value : 'Embry-Riddle Aeronautical University', label : 'Embry-Riddle Aeronautical University'} ,
{value : 'Emerson College', label : 'Emerson College'} ,
{value : 'Emmanuel College', label : 'Emmanuel College'} ,
{value : 'Emmanuel College, Georgia', label : 'Emmanuel College, Georgia'} ,
{value : 'Emmaus Bible College', label : 'Emmaus Bible College'} ,
{value : 'Emory & Henry College', label : 'Emory & Henry College'} ,
{value : 'Emory University', label : 'Emory University'} ,
{value : 'Emporia State University', label : 'Emporia State University'} ,
{value : 'Endicott College', label : 'Endicott College'} ,
{value : 'Erskine College', label : 'Erskine College'} ,
{value : 'Eureka College', label : 'Eureka College'} ,
{value : 'Evangel University', label : 'Evangel University'} ,
{value : 'Everglades University', label : 'Everglades University'} ,
{value : 'Fairfield University', label : 'Fairfield University'} ,
{value : 'Fairleigh Dickinson University', label : 'Fairleigh Dickinson University'} ,
{value : 'Fairmont State University', label : 'Fairmont State University'} ,
{value : 'Faith Baptist Bible College and Theological Seminary', label : 'Faith Baptist Bible College and Theological Seminary'} ,
{value : 'Farmingdale State College', label : 'Farmingdale State College'} ,
{value : 'Fashion Institute of Technology', label : 'Fashion Institute of Technology'} ,
{value : 'Faulkner University', label : 'Faulkner University'} ,
{value : 'Fayetteville State University', label : 'Fayetteville State University'} ,
{value : 'Ferris State University', label : 'Ferris State University'} ,
{value : 'Ferrum College', label : 'Ferrum College'} ,
{value : 'Fielding Graduate University', label : 'Fielding Graduate University'} ,
{value : 'Finlandia University', label : 'Finlandia University'} ,
{value : 'Fisher College', label : 'Fisher College'} ,
{value : 'Fisk University', label : 'Fisk University'} ,
{value : 'Fitchburg State University', label : 'Fitchburg State University'} ,
{value : 'Five Towns College', label : 'Five Towns College'} ,
{value : 'Flagler College', label : 'Flagler College'} ,
{value : 'Florida Agricultural and Mechanical University', label : 'Florida Agricultural and Mechanical University'} ,
{value : 'Florida Atlantic University', label : 'Florida Atlantic University'} ,
{value : 'Florida College', label : 'Florida College'} ,
{value : 'Florida Gateway College', label : 'Florida Gateway College'} ,
{value : 'Florida Gulf Coast University', label : 'Florida Gulf Coast University'} ,
{value : 'Florida Institute of Technology', label : 'Florida Institute of Technology'} ,
{value : 'Florida International University', label : 'Florida International University'} ,
{value : 'Florida Memorial University', label : 'Florida Memorial University'} ,
{value : 'National University', label : 'National University'} ,
{value : 'Florida Southern College', label : 'Florida Southern College'} ,
{value : 'Florida SouthWestern State College', label : 'Florida SouthWestern State College'} ,
{value : 'Florida State College a Jacksonville', label : 'Florida State College a Jacksonville'} ,
{value : 'Florida State University', label : 'Florida State University'} ,
{value : 'Fontbonne University', label : 'Fontbonne University'} ,
{value : 'Fordham University', label : 'Fordham University'} ,
{value : 'Fort Hays State University', label : 'Fort Hays State University'} ,
{value : 'Fort Lewis CollegeDurango', label : 'Fort Lewis CollegeDurango'} ,
{value : 'Fort Valley State University', label : 'Fort Valley State University'} ,
{value : 'Framingham State University', label : 'Framingham State University'} ,
{value : 'Francis Marion University', label : 'Francis Marion University'} ,
{value : 'Franciscan Missionaries of Our Lady University', label : 'Franciscan Missionaries of Our Lady University'} ,
{value : 'Franciscan University of Steubenville', label : 'Franciscan University of Steubenville'} ,
{value : 'Franklin and Marshall College', label : 'Franklin and Marshall College'} ,
{value : 'Franklin College', label : 'Franklin College'} ,
{value : 'Franklin Pierce University', label : 'Franklin Pierce University'} ,
{value : 'Franklin University', label : 'Franklin University'} ,
{value : 'Franklin W. Olin College of Engineering', label : 'Franklin W. Olin College of Engineering'} ,
{value : 'Freed-Hardeman University', label : 'Freed-Hardeman University'} ,
{value : 'Fresno Pacific University', label : 'Fresno Pacific University'} ,
{value : 'Friends University', label : 'Friends University'} ,
{value : 'Frostburg State University', label : 'Frostburg State University'} ,
{value : 'Furman University', label : 'Furman University'} ,
{value : 'Gallaudet University', label : 'Gallaudet University'} ,
{value : 'Gannon University', label : 'Gannon University'} ,
{value : 'Gardner-Webb University', label : 'Gardner-Webb University'} ,
{value : 'Geisinger Commonwealth School of Medicine', label : 'Geisinger Commonwealth School of Medicine'} ,
{value : 'Geneva CollegeBeaver ', label : 'Geneva CollegeBeaver '} ,
{value : 'George Fox University', label : 'George Fox University'} ,
{value : 'George Mason University', label : 'George Mason University'} ,
{value : 'George Washington University', label : 'George Washington University'} ,
{value : 'Georgetown College', label : 'Georgetown College'} ,
{value : 'Georgetown University', label : 'Georgetown University'} ,
{value : 'Georgia College and State ', label : 'Georgia College and State '} ,
{value : 'Georgia Gwinnett College', label : 'Georgia Gwinnett College'} ,
{value : 'Georgia Institute of Technology', label : 'Georgia Institute of Technology'} ,
{value : 'Georgia Southern University', label : 'Georgia Southern University'} ,
{value : 'Georgia Southwestern State University', label : 'Georgia Southwestern State University'} ,
{value : 'Georgia State University', label : 'Georgia State University'} ,
{value : 'Georgian Court University', label : 'Georgian Court University'} ,
{value : 'Gettysburg College', label : 'Gettysburg College'} ,
{value : 'Glenville State College', label : 'Glenville State College'} ,
{value : 'Goddard College...', label : 'Goddard College...'} ,
{value : 'Gods Bible School and College', label : 'Gods Bible School and College'} ,
{value : 'Golden Gate University', label : 'Golden Gate University'} ,
{value : 'Goldey-Beacom College', label : 'Goldey-Beacom College'} ,
{value : 'Gonzaga University', label : 'Gonzaga University'} ,
{value : 'Good Samaritan College of Nursing and Health Science', label : 'Good Samaritan College of Nursing and Health Science'} ,
{value : 'Goodwin College', label : 'Goodwin College'} ,
{value : 'Gordon College', label : 'Gordon College'} ,
{value : 'Gordon State College', label : 'Gordon State College'} ,
{value : 'Goshen College', label : 'Goshen College'} ,
{value : 'Goucher College', label : 'Goucher College'} ,
{value : 'Governors State University', label : 'Governors State University'} ,
{value : 'Grace Christian University823Grace College', label : 'Grace Christian University823Grace College'} ,
{value : 'Graceland University', label : 'Graceland University'} ,
{value : 'Grambling State University', label : 'Grambling State University'} ,
{value : 'Grand Canyon University', label : 'Grand Canyon University'} ,
{value : 'Grand Valley State University', label : 'Grand Valley State University'} ,
{value : 'Grand View University', label : 'Grand View University'} ,
{value : 'Granite State College', label : 'Granite State College'} ,
{value : 'Gratz College', label : 'Gratz College'} ,
{value : 'Great Basin College', label : 'Great Basin College'} ,
{value : 'Great Lakes Christian College', label : 'Great Lakes Christian College'} ,
{value : 'Greensboro College', label : 'Greensboro College'} ,
{value : 'Greenville ', label : 'Greenville '} ,
{value : 'Grinnell College', label : 'Grinnell College'} ,
{value : 'Grove City College', label : 'Grove City College'} ,
{value : 'Guilford College', label : 'Guilford College'} ,
{value : 'Gulf Coast State College', label : 'Gulf Coast State College'} ,
{value : 'Gustavus Adolphus College', label : 'Gustavus Adolphus College'} ,
{value : 'Gwynedd Mercy University', label : 'Gwynedd Mercy University'} ,
{value : 'Hamilton College', label : 'Hamilton College'} ,
{value : 'Hamline University', label : 'Hamline University'} ,
{value : 'Hampden-Sydney College', label : 'Hampden-Sydney College'} ,
{value : 'Hampshire College', label : 'Hampshire College'} ,
{value : 'Hampton University', label : 'Hampton University'} ,
{value : 'Hannibal-LaGrange University', label : 'Hannibal-LaGrange University'} ,
{value : 'Hanover College', label : 'Hanover College'} ,
{value : 'Harding University', label : 'Harding University'} ,
{value : 'Hardin-Simmons University', label : 'Hardin-Simmons University'} ,
{value : 'Harrisburg University of Science and Technology', label : 'Harrisburg University of Science and Technology'} ,
{value : 'Harris-Stowe State University', label : 'Harris-Stowe State University'} ,
{value : 'Hartwick College', label : 'Hartwick College'} ,
{value : 'Harvard University', label : 'Harvard University'} ,
{value : 'Harvey Mudd College', label : 'Harvey Mudd College'} ,
{value : 'Hastings College', label : 'Hastings College'} ,
{value : 'Haverford College', label : 'Haverford College'} ,
{value : 'Hawaii Pacific University', label : 'Hawaii Pacific University'} ,
{value : 'Hebrew College', label : 'Hebrew College'} ,
{value : 'Heidelberg University', label : 'Heidelberg University'} ,
{value : 'Helene Fuld College of Nursing', label : 'Helene Fuld College of Nursing'} ,
{value : 'Henderson State University', label : 'Henderson State University'} ,
{value : 'Hendrix College', label : 'Hendrix College'} ,
{value : 'Heritage Christian University', label : 'Heritage Christian University'} ,
{value : 'Heritage University', label : 'Heritage University'} ,
{value : 'Herzing University', label : 'Herzing University'} ,
{value : 'High Point University', label : 'High Point University'} ,
{value : 'Hilbert College', label : 'Hilbert College'} ,
{value : 'Hillsdale College', label : 'Hillsdale College'} ,
{value : 'Hiram College', label : 'Hiram College'} ,
{value : 'Hobart and William Smith Colleges', label : 'Hobart and William Smith Colleges'} ,
{value : '1398Hodges University', label : '1398Hodges University'} ,
{value : 'Hofstra University', label : 'Hofstra University'} ,
{value : 'Hollins University', label : 'Hollins University'} ,
{value : 'Holy Apostles College and Seminary', label : 'Holy Apostles College and Seminary'} ,
{value : 'Holy Cross College', label : 'Holy Cross College'} ,
{value : 'Holy Family College', label : 'Holy Family College'} ,
{value : 'Holy Family University', label : 'Holy Family University'} ,
{value : 'Holy Names University', label : 'Holy Names University'} ,
{value : 'Hood College', label : 'Hood College'} ,
{value : 'Hope College', label : 'Hope College'} ,
{value : 'Hope International University', label : 'Hope International University'} ,
{value : 'Houghton College', label : 'Houghton College'} ,
{value : 'Houston Baptist University', label : 'Houston Baptist University'} ,
{value : 'Howard Payne University', label : 'Howard Payne University'} ,
{value : 'Howard University', label : 'Howard University'} ,
{value : 'Hult International Business School', label : 'Hult International Business School'} ,
{value : 'Humboldt State University', label : 'Humboldt State University'} ,
{value : 'Humphreys University', label : 'Humphreys University'} ,
{value : 'Hunter College, CUNY', label : 'Hunter College, CUNY'} ,
{value : 'Huntingdon College', label : 'Huntingdon College'} ,
{value : 'Huntington University', label : 'Huntington University'} ,
{value : 'Husson University', label : 'Husson University'} ,
{value : 'Huston-Tillotson University', label : 'Huston-Tillotson University'} ,
{value : 'Icahn School of Medicine at Mount Sinai', label : 'Icahn School of Medicine at Mount Sinai'} ,
{value : 'Idaho State University', label : 'Idaho State University'} ,
{value : 'Illinois College', label : 'Illinois College'} ,
{value : 'Illinois College of Optometry', label : 'Illinois College of Optometry'} ,
{value : 'Illinois Institute of Technology', label : 'Illinois Institute of Technology'} ,
{value : 'Illinois State University', label : 'Illinois State University'} ,
{value : 'Illinois Wesleyan University', label : 'Illinois Wesleyan University'} ,
{value : 'Immaculata University', label : 'Immaculata University'} ,
{value : 'Indian River State College', label : 'Indian River State College'} ,
{value : 'Indiana Institute ', label : 'Indiana Institute '} ,
{value : 'Indiana State University', label : 'Indiana State University'} ,
{value : 'Indiana University - Purdue University Indianapolis', label : 'Indiana University - Purdue University Indianapolis'} ,
{value : 'Indiana University ', label : 'Indiana University '} ,
{value : 'Indiana University ', label : 'Indiana University '} ,
{value : 'Indiana University Fort Wayne', label : 'Indiana University Fort Wayne'} ,
{value : 'Indiana University Kokomo', label : 'Indiana University Kokomo'} ,
{value : 'Indiana University Northwest', label : 'Indiana University Northwest'} ,
{value : 'Indiana University of Pennsylvania', label : 'Indiana University of Pennsylvania'} ,
{value : 'Indiana University ', label : 'Indiana University '} ,
{value : 'Indiana University ', label : 'Indiana University '} ,
{value : 'Iona College', label : 'Iona College'} ,
{value : 'Iowa State University of Science and TechnologyAmes', label : 'Iowa State University of Science and TechnologyAmes'} ,
{value : 'Iowa Wesleyan University', label : 'Iowa Wesleyan University'} ,
{value : 'Irell and Manella Graduate School of Biological Sciences', label : 'Irell and Manella Graduate School of Biological Sciences'} ,
{value : 'Ithaca CollegeIthaca', label : 'Ithaca CollegeIthaca'} ,
{value : 'Jackson State University', label : 'Jackson State University'} ,
{value : 'Jacksonville State University', label : 'Jacksonville State University'} ,
{value : 'Jacksonville University', label : 'Jacksonville University'} ,
{value : 'James Madison University', label : 'James Madison University'} ,
{value : 'Jamestown Business College', label : 'Jamestown Business College'} ,
{value : 'Jarvis Christian College', label : 'Jarvis Christian College'} ,
{value : 'John Brown University', label : 'John Brown University'} ,
{value : 'John Carroll UniversityUniversity ', label : 'John Carroll UniversityUniversity '} ,
{value : 'John F. Kennedy University', label : 'John F. Kennedy University'} ,
{value : 'John Jay College of Criminal Justice', label : 'John Jay College of Criminal Justice'} ,
{value : 'John Paul the Great Catholic University', label : 'John Paul the Great Catholic University'} ,
{value : 'Johns Hopkins University', label : 'Johns Hopkins University'} ,
{value : 'Johnson and Wales University', label : 'Johnson and Wales University'} ,
{value : 'Johnson C. Smith University', label : 'Johnson C. Smith University'} ,
{value : 'Johnson University', label : 'Johnson University'} ,
{value : 'Judson College', label : 'Judson College'} ,
{value : 'Judson University', label : 'Judson University'} ,
{value : 'Juniata College', label : 'Juniata College'} ,
{value : 'Kalamazoo College', label : 'Kalamazoo College'} ,
{value : 'Kansas City Art Institute', label : 'Kansas City Art Institute'} ,
{value : 'Kansas City University of Medicine and Biosciences', label : 'Kansas City University of Medicine and Biosciences'} ,
{value : 'Kansas State University', label : 'Kansas State University'} ,
{value : 'Kansas Wesleyan University', label : 'Kansas Wesleyan University'} ,
{value : 'Kean University', label : 'Kean University'} ,
{value : 'Keck Graduate Institute', label : 'Keck Graduate Institute'} ,
{value : 'Keene State College', label : 'Keene State College'} ,
{value : 'Keiser University', label : 'Keiser University'} ,
{value : 'Kendall CollegeChicago', label : 'Kendall CollegeChicago'} ,
{value : 'Kennesaw State University', label : 'Kennesaw State University'} ,
{value : 'Kent State University', label : 'Kent State University'} ,
{value : 'Kentucky Christian University', label : 'Kentucky Christian University'} ,
{value : 'Kentucky State University', label : 'Kentucky State University'} ,
{value : 'Kentucky Wesleyan College', label : 'Kentucky Wesleyan College'} ,
{value : 'Kenyon CollegeGambier', label : 'Kenyon CollegeGambier'} ,
{value : 'Kettering College', label : 'Kettering College'} ,
{value : 'Kettering University', label : 'Kettering University'} ,
{value : 'Keuka College', label : 'Keuka College'} ,
{value : 'Keystone College', label : 'Keystone College'} ,
{value : 'King University', label : 'King University'} ,
{value : 'Kings College', label : 'Kings College'} ,
{value : 'Knox College', label : 'Knox College'} ,
{value : 'Kutztown University of Pennsylvania', label : 'Kutztown University of Pennsylvania'} ,
{value : 'Kuyper College', label : 'Kuyper College'} ,
{value : 'La Roche University', label : 'La Roche University'} ,
{value : 'La Salle University', label : 'La Salle University'} ,
{value : 'La Sierra University', label : 'La Sierra University'} ,
{value : 'Labouré College', label : 'Labouré College'} ,
{value : 'Lafayette College', label : 'Lafayette College'} ,
{value : 'LaGrange College', label : 'LaGrange College'} ,
{value : 'Laguna College of Art and Design', label : 'Laguna College of Art and Design'} ,
{value : 'Lake Erie College', label : 'Lake Erie College'} ,
{value : 'Lake Forest College', label : 'Lake Forest College'} ,
{value : 'Lake Forest Graduate School of Management', label : 'Lake Forest Graduate School of Management'} ,
{value : 'Lake Superior State University', label : 'Lake Superior State University'} ,
{value : 'Lake Washington Institute of Technology', label : 'Lake Washington Institute of Technology'} ,
{value : 'Lakeland College', label : 'Lakeland College'} ,
{value : 'Lakeview College of Nursing', label : 'Lakeview College of Nursing'} ,
{value : 'Lamar University', label : 'Lamar University'} ,
{value : 'Lancaster Bible College', label : 'Lancaster Bible College'} ,
{value : 'Lander University', label : 'Lander University'} ,
{value : 'Landmark College', label : 'Landmark College'} ,
{value : 'Lane College', label : 'Lane College'} ,
{value : 'Langston University', label : 'Langston University'} ,
{value : 'Lasell College', label : 'Lasell College'} ,
{value : 'Lawrence Technological University', label : 'Lawrence Technological University'} ,
{value : 'Lawrence University', label : 'Lawrence University'} ,
{value : 'Le Moyne College', label : 'Le Moyne College'} ,
{value : 'Lebanon Valley College', label : 'Lebanon Valley College'} ,
{value : 'Lee University', label : 'Lee University'} ,
{value : 'Lees-McRae College', label : 'Lees-McRae College'} ,
{value : 'Lehigh University', label : 'Lehigh University'} ,
{value : 'Lehman College, CUNY', label : 'Lehman College, CUNY'} ,
{value : 'LeMoyne-Owen College', label : 'LeMoyne-Owen College'} ,
{value : 'Lenoir-Rhyne University', label : 'Lenoir-Rhyne University'} ,
{value : 'Lesley University', label : 'Lesley University'} ,
{value : 'LeTourneau University', label : 'LeTourneau University'} ,
{value : 'Lewis & Clark College', label : 'Lewis & Clark College'} ,
{value : 'Lewis University', label : 'Lewis University'} ,
{value : 'Lewis-Clark State College', label : 'Lewis-Clark State College'} ,
{value : 'Liberty University', label : 'Liberty University'} ,
{value : 'Life Pacific College', label : 'Life Pacific College'} ,
{value : 'Life University', label : 'Life University'} ,
{value : 'LIM College', label : 'LIM College'} ,
{value : 'Lincoln Christian University', label : 'Lincoln Christian University'} ,
{value : 'Lincoln CollegeLincoln', label : 'Lincoln CollegeLincoln'} ,
{value : 'Lincoln Memorial University', label : 'Lincoln Memorial University'} ,
{value : 'Lincoln University', label : 'Lincoln University'} ,
{value : 'Lincoln University, California', label : 'Lincoln University, California'} ,
{value : 'Lincoln University, Missouri', label : 'Lincoln University, Missouri'} ,
{value : 'Lindenwood University', label : 'Lindenwood University'} ,
{value : 'Lindsey Wilson College', label : 'Lindsey Wilson College'} ,
{value : 'Linfield College', label : 'Linfield College'} ,
{value : 'Lipscomb University', label : 'Lipscomb University'} ,
{value : 'Livingstone College', label : 'Livingstone College'} ,
{value : 'Lock Haven University', label : 'Lock Haven University'} ,
{value : 'Logan University', label : 'Logan University'} ,
{value : 'Loma Linda University', label : 'Loma Linda University'} ,
{value : 'Long Island University', label : 'Long Island University'} ,
{value : 'Longwood University', label : 'Longwood University'} ,
{value : 'Loras College', label : 'Loras College'} ,
{value : 'Louisiana College', label : 'Louisiana College'} ,
{value : 'Louisiana State University', label : 'Louisiana State University'} ,
{value : 'Louisiana State University Health Sciences Center', label : 'Louisiana State University Health Sciences Center'} ,
{value : 'Louisiana State University Health Sciences Center-Shreveport', label : 'Louisiana State University Health Sciences Center-Shreveport'} ,
{value : 'Louisiana State University in Shreveport', label : 'Louisiana State University in Shreveport'} ,
{value : 'Louisiana State University of Alexandria', label : 'Louisiana State University of Alexandria'} ,
{value : 'Louisiana Tech University', label : 'Louisiana Tech University'} ,
{value : 'Lourdes University', label : 'Lourdes University'} ,
{value : 'Loyola Marymount University', label : 'Loyola Marymount University'} ,
{value : 'Loyola University Chicago', label : 'Loyola University Chicago'} ,
{value : 'Loyola University Maryland', label : 'Loyola University Maryland'} ,
{value : 'Loyola University New Orleans', label : 'Loyola University New Orleans'} ,
{value : 'Lubbock Christian University', label : 'Lubbock Christian University'} ,
{value : 'Luther College', label : 'Luther College'} ,
{value : 'Lycoming College', label : 'Lycoming College'} ,
{value : 'Lynn University', label : 'Lynn University'} ,
{value : 'Lyon College', label : 'Lyon College'} ,
{value : 'Macalester College', label : 'Macalester College'} ,
{value : 'MacMurray College', label : 'MacMurray College'} ,
{value : 'Madonna University', label : 'Madonna University'} ,
{value : 'Magdalen College of the Liberal Arts', label : 'Magdalen College of the Liberal Arts'} ,
{value : 'Maharishi University of Management', label : 'Maharishi University of Management'} ,
{value : 'Maine College of Art', label : 'Maine College of Art'} ,
{value : 'Maine Maritime Academy', label : 'Maine Maritime Academy'} ,
{value : 'Malone University', label : 'Malone University'} ,
{value : 'Manchester University', label : 'Manchester University'} ,
{value : 'Manhattan Christian College', label : 'Manhattan Christian College'} ,
{value : 'Manhattan College', label : 'Manhattan College'} ,
{value : 'Manhattan School of Music', label : 'Manhattan School of Music'} ,
{value : 'Manhattanville College', label : 'Manhattanville College'} ,
{value : 'Mansfield University of Pennsylvania', label : 'Mansfield University of Pennsylvania'} ,
{value : 'Maranatha Baptist University', label : 'Maranatha Baptist University'} ,
{value : 'Maria College', label : 'Maria College'} ,
{value : 'Marian University', label : 'Marian University'} ,
{value : 'Marian University, Wisconsin', label : 'Marian University, Wisconsin'} ,
{value : 'Marietta College', label : 'Marietta College'} ,
{value : 'Marist College', label : 'Marist College'} ,
{value : 'Marlboro College', label : 'Marlboro College'} ,
{value : 'Marquette University', label : 'Marquette University'} ,
{value : 'Mars Hill University', label : 'Mars Hill University'} ,
{value : 'Marshall B. Ketchum University', label : 'Marshall B. Ketchum University'} ,
{value : 'Marshall University', label : 'Marshall University'} ,
{value : 'Martin Luther College', label : 'Martin Luther College'} ,
{value : 'Martin Methodist College', label : 'Martin Methodist College'} ,
{value : 'Martin University', label : 'Martin University'} ,
{value : 'Mary Baldwin University', label : 'Mary Baldwin University'} ,
{value : 'Marygrove College', label : 'Marygrove College'} ,
{value : 'Maryland Institute College of Art', label : 'Maryland Institute College of Art'} ,
{value : 'Maryland University of Integrative Health', label : 'Maryland University of Integrative Health'} ,
{value : 'Marymount California University', label : 'Marymount California University'} ,
{value : 'Marymount Manhattan College', label : 'Marymount Manhattan College'} ,
{value : 'Marymount University', label : 'Marymount University'} ,
{value : 'Maryville College', label : 'Maryville College'} ,
{value : 'Maryville University', label : 'Maryville University'} ,
{value : 'Marywood University', label : 'Marywood University'} ,
{value : 'Massachusetts College of Art and Design', label : 'Massachusetts College of Art and Design'} ,
{value : 'Massachusetts College of Liberal Arts', label : 'Massachusetts College of Liberal Arts'} ,
{value : 'Massachusetts Institute of Technology', label : 'Massachusetts Institute of Technology'} ,
{value : 'Massachusetts Maritime Academy', label : 'Massachusetts Maritime Academy'} ,
{value : 'Massachusetts School of Law', label : 'Massachusetts School of Law'} ,
{value : 'Mayo Medical School', label : 'Mayo Medical School'} ,
{value : 'Mayville State University', label : 'Mayville State University'} ,
{value : 'McDaniel College', label : 'McDaniel College'} ,
{value : 'McKendree University', label : 'McKendree University'} ,
{value : 'McMurry University', label : 'McMurry University'} ,
{value : 'McNeese State University', label : 'McNeese State University'} ,
{value : 'McPherson College', label : 'McPherson College'} ,
{value : 'MCPHS University', label : 'MCPHS University'} ,
{value : 'Medaille College', label : 'Medaille College'} ,
{value : 'Medgar Evers College', label : 'Medgar Evers College'} ,
{value : 'Medical College of Wisconsin', label : 'Medical College of Wisconsin'} ,
{value : 'Medical University of South Carolina', label : 'Medical University of South Carolina'} ,
{value : 'Meharry Medical College', label : 'Meharry Medical College'} ,
{value : 'Memphis College of Art', label : 'Memphis College of Art'} ,
{value : 'Menlo College', label : 'Menlo College'} ,
{value : 'Mercer University', label : 'Mercer University'} ,
{value : 'Mercy College', label : 'Mercy College'} ,
{value : 'Mercy College of Health Sciences', label : 'Mercy College of Health Sciences'} ,
{value : 'Mercy College of Ohio', label : 'Mercy College of Ohio'} ,
{value : 'Mercyhurst University', label : 'Mercyhurst University'} ,
{value : 'Meredith College', label : 'Meredith College'} ,
{value : 'Merrimack College', label : 'Merrimack College'} ,
{value : 'Messiah College', label : 'Messiah College'} ,
{value : 'Methodist College', label : 'Methodist College'} ,
{value : 'Methodist University', label : 'Methodist University'} ,
{value : 'Metropolitan College of New York', label : 'Metropolitan College of New York'} ,
{value : 'Metropolitan State University', label : 'Metropolitan State University'} ,
{value : 'Metropolitan State University of Denver', label : 'Metropolitan State University of Denver'} ,
{value : 'MGH Institute of Health Professions', label : 'MGH Institute of Health Professions'} ,
{value : 'Miami Dade College', label : 'Miami Dade College'} ,
{value : 'Miami University', label : 'Miami University'} ,
{value : 'Michigan School of Psychology', label : 'Michigan School of Psychology'} ,
{value : 'Michigan State University', label : 'Michigan State University'} ,
{value : 'Michigan Technological University', label : 'Michigan Technological University'} ,
{value : 'Mid-America Christian University', label : 'Mid-America Christian University'} ,
{value : 'MidAmerica Nazarene University', label : 'MidAmerica Nazarene University'} ,
{value : 'Mid-Atlantic Christian University', label : 'Mid-Atlantic Christian University'} ,
{value : 'Middle Georgia State University', label : 'Middle Georgia State University'} ,
{value : 'Middle Tennessee School of Anesthesia', label : 'Middle Tennessee School of Anesthesia'} ,
{value : 'Middle Tennessee State University', label : 'Middle Tennessee State University'} ,
{value : 'Middlebury College', label : 'Middlebury College'} ,
{value : 'Midland College', label : 'Midland College'} ,
{value : 'Midland University', label : 'Midland University'} ,
{value : 'Midstate College', label : 'Midstate College'} ,
{value : 'Midway University', label : 'Midway University'} ,
{value : 'Midwestern State University', label : 'Midwestern State University'} ,
{value : 'Midwestern University', label : 'Midwestern University'} ,
{value : 'Miles College', label : 'Miles College'} ,
{value : 'Millersville University of Pennsylvania', label : 'Millersville University of Pennsylvania'} ,
{value : 'Milligan College', label : 'Milligan College'} ,
{value : 'Millikin University', label : 'Millikin University'} ,
{value : 'Mills College', label : 'Mills College'} ,
{value : 'Millsaps College', label : 'Millsaps College'} ,
{value : 'Milwaukee Institute of Art & Design', label : 'Milwaukee Institute of Art & Design'} ,
{value : 'Milwaukee School of Engineering', label : 'Milwaukee School of Engineering'} ,
{value : 'Minneapolis College of Art and Design', label : 'Minneapolis College of Art and Design'} ,
{value : 'Minnesota State University Moorhead', label : 'Minnesota State University Moorhead'} ,
{value : 'Minnesota State University, Mankato', label : 'Minnesota State University, Mankato'} ,
{value : 'Minot State University', label : 'Minot State University'} ,
{value : 'Misericordia University', label : 'Misericordia University'} ,
{value : 'Mississippi College', label : 'Mississippi College'} ,
{value : 'Mississippi State University', label : 'Mississippi State University'} ,
{value : 'Mississippi University for Women', label : 'Mississippi University for Women'} ,
{value : 'Mississippi Valley State University', label : 'Mississippi Valley State University'} ,
{value : 'Missouri Baptist University', label : 'Missouri Baptist University'} ,
{value : 'Missouri Southern State University', label : 'Missouri Southern State University'} ,
{value : 'Missouri State University', label : 'Missouri State University'} ,
{value : 'Missouri University of Science and Technology', label : 'Missouri University of Science and Technology'} ,
{value : 'Missouri Valley College', label : 'Missouri Valley College'} ,
{value : 'Missouri Western State University', label : 'Missouri Western State University'} ,
{value : 'Mitchell College', label : 'Mitchell College'} ,
{value : 'Mitchell Hamline School of Law', label : 'Mitchell Hamline School of Law'} ,
{value : 'Molloy College912Monmouth College', label : 'Molloy College912Monmouth College'} ,
{value : 'Monmouth University', label : 'Monmouth University'} ,
{value : 'Monroe College', label : 'Monroe College'} ,
{value : 'Montana State University', label : 'Montana State University'} ,
{value : 'Montana State University Billings', label : 'Montana State University Billings'} ,
{value : 'Montana State University-Northern', label : 'Montana State University-Northern'} ,
{value : 'Montana Tech', label : 'Montana Tech'} ,
{value : 'Montclair State University', label : 'Montclair State University'} ,
{value : 'Montreat College', label : 'Montreat College'} ,
{value : 'Montserrat College of Art', label : 'Montserrat College of Art'} ,
{value : 'Moody Bible Institute', label : 'Moody Bible Institute'} ,
{value : 'Moore College of Art and Design', label : 'Moore College of Art and Design'} ,
{value : 'Moravian College', label : 'Moravian College'} ,
{value : 'Morehead State University', label : 'Morehead State University'} ,
{value : 'Morehouse College', label : 'Morehouse College'} ,
{value : 'Morehouse School of Medicine', label : 'Morehouse School of Medicine'} ,
{value : 'Morgan State University', label : 'Morgan State University'} ,
{value : 'Morningside College', label : 'Morningside College'} ,
{value : 'Morris College', label : 'Morris College'} ,
{value : 'Morrisville State College', label : 'Morrisville State College'} ,
{value : 'Mount Aloysius College', label : 'Mount Aloysius College'} ,
{value : 'Mount Carmel College of Nursing', label : 'Mount Carmel College of Nursing'} ,
{value : 'Mount Holyoke College', label : 'Mount Holyoke College'} ,
{value : 'Mount Marty College', label : 'Mount Marty College'} ,
{value : 'Mount Mary University', label : 'Mount Mary University'} ,
{value : 'Mount Mercy University', label : 'Mount Mercy University'} ,
{value : 'Mount Saint Mary College', label : 'Mount Saint Mary College'} ,
{value : 'Mount Saint Marys University', label : 'Mount Saint Marys University'} ,
{value : 'Mount St. Joseph University', label : 'Mount St. Joseph University'} ,
{value : 'Mount St. Marys University', label : 'Mount St. Marys University'} ,
{value : 'Mount Vernon Nazarene University', label : 'Mount Vernon Nazarene University'} ,
{value : 'Muhlenberg College', label : 'Muhlenberg College'} ,
{value : 'Multnomah University', label : 'Multnomah University'} ,
{value : 'Murray State University', label : 'Murray State University'} ,
{value : 'Muskingum University', label : 'Muskingum University'} ,
{value : 'Naropa University', label : 'Naropa University'} ,
{value : 'National American University', label : 'National American University'} ,
{value : 'National Defense University', label : 'National Defense University'} ,
{value : 'National Louis University', label : 'National Louis University'} ,
{value : 'National University', label : 'National University'} ,
{value : 'National University of Health Sciences', label : 'National University of Health Sciences'} ,
{value : 'Naval Postgraduate School', label : 'Naval Postgraduate School'} ,
{value : 'Nazarene Bible College', label : 'Nazarene Bible College'} ,
{value : 'Nazareth College', label : 'Nazareth College'} ,
{value : 'Nebraska Christian College', label : 'Nebraska Christian College'} ,
{value : 'Nebraska Methodist College', label : 'Nebraska Methodist College'} ,
{value : 'Nebraska Wesleyan University', label : 'Nebraska Wesleyan University'} ,
{value : 'Neumann University', label : 'Neumann University'} ,
{value : 'Neumont University', label : 'Neumont University'} ,
{value : 'Nevada State College', label : 'Nevada State College'} ,
{value : 'New College of Florida', label : 'New College of Florida'} ,
{value : 'New England College', label : 'New England College'} ,
{value : 'New England College of Business', label : 'New England College of Business'} ,
{value : 'New England College of Optometry', label : 'New England College of Optometry'} ,
{value : 'New England Conservatory', label : 'New England Conservatory'} ,
{value : 'New England Institute of Technology', label : 'New England Institute of Technology'} ,
{value : 'New England Law | Boston', label : 'New England Law | Boston'} ,
{value : 'New Hope Christian College', label : 'New Hope Christian College'} ,
{value : 'New Jersey City UniversityNew Jersey Institute of Technology', label : 'New Jersey City UniversityNew Jersey Institute of Technology'} ,
{value : 'New Mexico Highlands University', label : 'New Mexico Highlands University'} ,
{value : 'New Mexico Institute of Mining and Technology', label : 'New Mexico Institute of Mining and Technology'} ,
{value : 'New Mexico State University', label : 'New Mexico State University'} ,
{value : 'New York Academy of Art', label : 'New York Academy of Art'} ,
{value : 'New York City College of Technology, CUNY', label : 'New York City College of Technology, CUNY'} ,
{value : 'New York College of Podiatric Medicine', label : 'New York College of Podiatric Medicine'} ,
{value : 'New York Institute of Technology', label : 'New York Institute of Technology'} ,
{value : 'New York Law School', label : 'New York Law School'} ,
{value : 'New York Medical College', label : 'New York Medical College'} ,
{value : 'New York School of Interior Design', label : 'New York School of Interior Design'} ,
{value : 'Newberry College', label : 'Newberry College'} ,
{value : 'NewSchool of Architecture and Design', label : 'NewSchool of Architecture and Design'} ,
{value : 'Niagara University', label : 'Niagara University'} ,
{value : 'Nicholls State University', label : 'Nicholls State University'} ,
{value : 'Nichols College', label : 'Nichols College'} ,
{value : 'Norfolk State University', label : 'Norfolk State University'} ,
{value : 'North Carolina Agricultural and Technical State University', label : 'North Carolina Agricultural and Technical State University'} ,
{value : 'North Carolina Central University', label : 'North Carolina Central University'} ,
{value : 'North Carolina State University', label : 'North Carolina State University'} ,
{value : 'North Carolina Wesleyan CollegeRocky ', label : 'North Carolina Wesleyan CollegeRocky '} ,
{value : 'North Central College', label : 'North Central College'} ,
{value : 'North Central University', label : 'North Central University'} ,
{value : 'North Dakota State University', label : 'North Dakota State University'} ,
{value : 'North Greenville University', label : 'North Greenville University'} ,
{value : 'North Park University', label : 'North Park University'} ,
{value : 'Northeast Ohio Medical University', label : 'Northeast Ohio Medical University'} ,
{value : 'Northeastern Illinois University', label : 'Northeastern Illinois University'} ,
{value : 'Northeastern State University', label : 'Northeastern State University'} ,
{value : 'Northeastern University', label : 'Northeastern University'} ,
{value : 'Northern Arizona University', label : 'Northern Arizona University'} ,
{value : 'Northern Illinois University', label : 'Northern Illinois University'} ,
{value : 'Northern Kentucky University', label : 'Northern Kentucky University'} ,
{value : 'Northern Michigan University', label : 'Northern Michigan University'} ,
{value : 'Northern New Mexico College', label : 'Northern New Mexico College'} ,
{value : 'Northern State University', label : 'Northern State University'} ,
{value : 'Northern Vermont University', label : 'Northern Vermont University'} ,
{value : 'Northland College', label : 'Northland College'} ,
{value : 'Northpoint Bible College', label : 'Northpoint Bible College'} ,
{value : 'Northwest Christian University', label : 'Northwest Christian University'} ,
{value : 'Northwest Florida State College', label : 'Northwest Florida State College'} ,
{value : 'Northwest Missouri State University', label : 'Northwest Missouri State University'} ,
{value : 'Northwest Nazarene University', label : 'Northwest Nazarene University'} ,
{value : 'Northwest University', label : 'Northwest University'} ,
{value : 'Northwestern College', label : 'Northwestern College'} ,
{value : 'Northwestern Health Sciences University', label : 'Northwestern Health Sciences University'} ,
{value : 'Northwestern Oklahoma State University', label : 'Northwestern Oklahoma State University'} ,
{value : 'Northwestern State University of Louisiana', label : 'Northwestern State University of Louisiana'} ,
{value : 'Northwestern University', label : 'Northwestern University'} ,
{value : 'Northwood University', label : 'Northwood University'} ,
{value : 'Norwich University', label : 'Norwich University'} ,
{value : 'Notre Dame College', label : 'Notre Dame College'} ,
{value : 'Notre Dame de Namur University', label : 'Notre Dame de Namur University'} ,
{value : 'Notre Dame of Maryland University', label : 'Notre Dame of Maryland University'} ,
{value : 'Nova Southeastern University', label : 'Nova Southeastern University'} ,
{value : 'Nyack College', label : 'Nyack College'} ,
{value : 'Oak Hills Christian College', label : 'Oak Hills Christian College'} ,
{value : 'Oakland City University', label : 'Oakland City University'} ,
{value : 'Oakland University', label : 'Oakland University'} ,
{value : 'Oakwood University', label : 'Oakwood University'} ,
{value : 'Oberlin College', label : 'Oberlin College'} ,
{value : 'Occidental College', label : 'Occidental College'} ,
{value : 'Oglethorpe University', label : 'Oglethorpe University'} ,
{value : 'Ohio Christian University', label : 'Ohio Christian University'} ,
{value : 'Ohio Dominican University', label : 'Ohio Dominican University'} ,
{value : 'Ohio Northern University', label : 'Ohio Northern University'} ,
{value : 'Ohio University', label : 'Ohio University'} ,
{value : 'Ohio University Southern', label : 'Ohio University Southern'} ,
{value : 'Ohio University-Chillicothe', label : 'Ohio University-Chillicothe'} ,
{value : 'Ohio Valley UniversityVienna', label : 'Ohio Valley UniversityVienna'} ,
{value : 'Ohio Wesleyan University', label : 'Ohio Wesleyan University'} ,
{value : 'Oklahoma Baptist University', label : 'Oklahoma Baptist University'} ,
{value : 'Oklahoma Christian University', label : 'Oklahoma Christian University'} ,
{value : 'Oklahoma Panhandle State University', label : 'Oklahoma Panhandle State University'} ,
{value : 'Oklahoma State University', label : 'Oklahoma State University'} ,
{value : 'Oklahoma Wesleyan University', label : 'Oklahoma Wesleyan University'} ,
{value : 'Old Dominion University', label : 'Old Dominion University'} ,
{value : 'Olivet College', label : 'Olivet College'} ,
{value : 'Olivet Nazarene University', label : 'Olivet Nazarene University'} ,
{value : 'Oral Roberts University', label : 'Oral Roberts University'} ,
{value : 'Oregon Health & Science University', label : 'Oregon Health & Science University'} ,
{value : 'Oregon Institute of Technology', label : 'Oregon Institute of Technology'} ,
{value : 'Oregon State University', label : 'Oregon State University'} ,
{value : 'Otis College of Art ', label : 'Otis College of Art '} ,
{value : 'Ottawa University', label : 'Ottawa University'} ,
{value : 'Otterbein University', label : 'Otterbein University'} ,
{value : 'Ouachita Baptist University', label : 'Ouachita Baptist University'} ,
{value : 'Our Lady of the Lake University', label : 'Our Lady of the Lake University'} ,
{value : 'Ozark Christian College', label : 'Ozark Christian College'} ,
{value : 'Pace University', label : 'Pace University'} ,
{value : 'Pacific Lutheran University', label : 'Pacific Lutheran University'} ,
{value : 'Pacific Northwest College of Art', label : 'Pacific Northwest College of Art'} ,
{value : 'Pacific Oaks College', label : 'Pacific Oaks College'} ,
{value : 'Pacific Union College', label : 'Pacific Union College'} ,
{value : 'Pacific University', label : 'Pacific University'} ,
{value : 'Pacifica Graduate Institute', label : 'Pacifica Graduate Institute'} ,
{value : 'Paine College', label : 'Paine College'} ,
{value : 'Palm Beach Atlantic University', label : 'Palm Beach Atlantic University'} ,
{value : 'Palm Beach State College', label : 'Palm Beach State College'} ,
{value : 'Palo Alto University', label : 'Palo Alto University'} ,
{value : 'Pardee RAND Graduate School', label : 'Pardee RAND Graduate School'} ,
{value : 'Parker University', label : 'Parker University'} ,
{value : 'Paul Quinn College', label : 'Paul Quinn College'} ,
{value : 'Paul Smiths College', label : 'Paul Smiths College'} ,
{value : 'Peirce College', label : 'Peirce College'} ,
{value : 'Penn State College of Medicine', label : 'Penn State College of Medicine'} ,
{value : 'Penn State UniversityUniversity ', label : 'Penn State UniversityUniversity '} ,
{value : 'Pennsylvania Academy of the Fine Arts', label : 'Pennsylvania Academy of the Fine Arts'} ,
{value : 'Pennsylvania College of Art and Design', label : 'Pennsylvania College of Art and Design'} ,
{value : 'Pennsylvania College of Health Sciences', label : 'Pennsylvania College of Health Sciences'} ,
{value : 'Pennsylvania College of Technology', label : 'Pennsylvania College of Technology'} ,
{value : 'Pensacola State College', label : 'Pensacola State College'} ,
{value : 'Pepperdine University', label : 'Pepperdine University'} ,
{value : 'Peru State College', label : 'Peru State College'} ,
{value : 'Pfeiffer University', label : 'Pfeiffer University'} ,
{value : 'Philander Smith College', label : 'Philander Smith College'} ,
{value : 'Phillips Graduate University', label : 'Phillips Graduate University'} ,
{value : 'Piedmont College', label : 'Piedmont College'} ,
{value : 'Piedmont International University', label : 'Piedmont International University'} ,
{value : 'Pillar College', label : 'Pillar College'} ,
{value : 'Pine Manor College', label : 'Pine Manor College'} ,
{value : 'Pittsburg State University', label : 'Pittsburg State University'} ,
{value : 'Pitzer College', label : 'Pitzer College'} ,
{value : 'Plaza College', label : 'Plaza College'} ,
{value : 'Plymouth State University', label : 'Plymouth State University'} ,
{value : 'Point Loma Nazarene University', label : 'Point Loma Nazarene University'} ,
{value : 'Point Park University', label : 'Point Park University'} ,
{value : 'Point University', label : 'Point University'} ,
{value : 'Polk State College', label : 'Polk State College'} ,
{value : 'Pomona College', label : 'Pomona College'} ,
{value : 'Pontifical Faculty of the Immaculate Conception', label : 'Pontifical Faculty of the Immaculate Conception'} ,
{value : 'Portland State University', label : 'Portland State University'} ,
{value : 'Post University', label : 'Post University'} ,
{value : 'Prairie View A&M University', label : 'Prairie View A&M University'} ,
{value : 'Pratt Institute', label : 'Pratt Institute'} ,
{value : 'Presbyterian College', label : 'Presbyterian College'} ,
{value : 'Prescott College', label : 'Prescott College'} ,
{value : 'Presentation College', label : 'Presentation College'} ,
{value : 'Princeton University', label : 'Princeton University'} ,
{value : 'Principia College', label : 'Principia College'} ,
{value : 'Providence Christian College', label : 'Providence Christian College'} ,
{value : 'Providence College', label : 'Providence College'} ,
{value : 'Purchase College, State University of New York', label : 'Purchase College, State University of New York'} ,
{value : 'Purdue University', label : 'Purdue University'} ,
{value : 'Purdue University Fort Wayne', label : 'Purdue University Fort Wayne'} ,
{value : 'Purdue University Global', label : 'Purdue University Global'} ,
{value : 'Purdue University Northwest', label : 'Purdue University Northwest'} ,
{value : 'Queens College, City University of New York', label : 'Queens College, City University of New York'} ,
{value : 'Queens University of Charlotte', label : 'Queens University of Charlotte'} ,
{value : 'Quincy University', label : 'Quincy University'} ,
{value : 'Quinnipiac University', label : 'Quinnipiac University'} ,
{value : 'Radford University', label : 'Radford University'} ,
{value : 'Ramapo College of New Jersey', label : 'Ramapo College of New Jersey'} ,
{value : 'Randolph College', label : 'Randolph College'} ,
{value : 'Randolph-Macon College', label : 'Randolph-Macon College'} ,
{value : 'Rasmussen College', label : 'Rasmussen College'} ,
{value : 'Reed College', label : 'Reed College'} ,
{value : 'Regent University', label : 'Regent University'} ,
{value : 'Regis College', label : 'Regis College'} ,
{value : 'Regis University', label : 'Regis University'} ,
{value : 'Reinhardt University', label : 'Reinhardt University'} ,
{value : 'Relay Graduate School of Education', label : 'Relay Graduate School of Education'} ,
{value : 'Rensselaer Polytechnic Institute', label : 'Rensselaer Polytechnic Institute'} ,
{value : 'Research College of Nursing', label : 'Research College of Nursing'} ,
{value : 'Resurrection University', label : 'Resurrection University'} ,
{value : 'Rhode Island College', label : 'Rhode Island College'} ,
{value : 'Rhode Island School of Design', label : 'Rhode Island School of Design'} ,
{value : 'Rhodes College', label : 'Rhodes College'} ,
{value : 'Rice University', label : 'Rice University'} ,
{value : 'Richmont Graduate University', label : 'Richmont Graduate University'} ,
{value : 'Rider University', label : 'Rider University'} ,
{value : 'Ringling College of Art and Design', label : 'Ringling College of Art and Design'} ,
{value : 'Ripon College', label : 'Ripon College'} ,
{value : 'Rivier University', label : 'Rivier University'} ,
{value : 'Roanoke CollegeSalem', label : 'Roanoke CollegeSalem'} ,
{value : 'Robert Morris University', label : 'Robert Morris University'} ,
{value : 'Robert Morris University Illinois', label : 'Robert Morris University Illinois'} ,
{value : 'Roberts Wesleyan College', label : 'Roberts Wesleyan College'} ,
{value : 'Rochester Institute of Technology', label : 'Rochester Institute of Technology'} ,
{value : 'Rochester University', label : 'Rochester University'} ,
{value : 'Rockford University', label : 'Rockford University'} ,
{value : 'Rockhurst University', label : 'Rockhurst University'} ,
{value : 'Rocky Mountain College', label : 'Rocky Mountain College'} ,
{value : 'Rocky Mountain College of Art and Design', label : 'Rocky Mountain College of Art and Design'} ,
{value : 'Rocky Mountain University of Health Professions', label : 'Rocky Mountain University of Health Professions'} ,
{value : 'Rocky Vista University', label : 'Rocky Vista University'} ,
{value : 'Roger Williams University', label : 'Roger Williams University'} ,
{value : 'Rogers State University', label : 'Rogers State University'} ,
{value : 'Rollins College', label : 'Rollins College'} ,
{value : 'Roosevelt University', label : 'Roosevelt University'} ,
{value : 'Rosalind Franklin University of Medicine and Science', label : 'Rosalind Franklin University of Medicine and Science'} ,
{value : 'Rose-Hulman Institute of Technology', label : 'Rose-Hulman Institute of Technology'} ,
{value : 'Roseman University of Health Sciences', label : 'Roseman University of Health Sciences'} ,
{value : 'Rosemont College', label : 'Rosemont College'} ,
{value : 'Rowan University', label : 'Rowan University'} ,
{value : 'Rush University', label : 'Rush University'} ,
{value : 'Rust College', label : 'Rust College'} ,
{value : 'Rutgers, The State University of New Jersey', label : 'Rutgers, The State University of New Jersey'} ,
{value : 'Sacred Heart University', label : 'Sacred Heart University'} ,
{value : 'Saginaw Valley State UniversityUniversity ', label : 'Saginaw Valley State UniversityUniversity '} ,
{value : 'Saint Ambrose University', label : 'Saint Ambrose University'} ,
{value : 'Saint Anselm College', label : 'Saint Anselm College'} ,
{value : 'Saint Anthony Cllege of Nursing', label : 'Saint Anthony Cllege of Nursing'} ,
{value : 'Saint Augustine College', label : 'Saint Augustine College'} ,
{value : 'Saint Francis Medical Center College of Nursing', label : 'Saint Francis Medical Center College of Nursing'} ,
{value : 'Saint Francis University', label : 'Saint Francis University'} ,
{value : 'Saint Johns River State College', label : 'Saint Johns River State College'} ,
{value : 'Saint Josephs College of Maine', label : 'Saint Josephs College of Maine'} ,
{value : 'Saint Josephs University', label : 'Saint Josephs University'} ,
{value : 'Saint Leo University', label : 'Saint Leo University'} ,
{value : 'Saint Louis University', label : 'Saint Louis University'} ,
{value : 'Saint Lukes College of Health Sciences', label : 'Saint Lukes College of Health Sciences'} ,
{value : 'Saint Martins University', label : 'Saint Martins University'} ,
{value : 'Saint Mary-of-the-Woods CollegeSaint Mary of the Woods', label : 'Saint Mary-of-the-Woods CollegeSaint Mary of the Woods'} ,
{value : 'Saint Marys College', label : 'Saint Marys College'} ,
{value : 'Saint Marys College of California', label : 'Saint Marys College of California'} ,
{value : 'Saint Marys University of Minnesota', label : 'Saint Marys University of Minnesota'} ,
{value : 'Saint Michaels College', label : 'Saint Michaels College'} ,
{value : 'Saint Peters Uiversity', label : 'Saint Peters Uiversity'} ,
{value : 'Saint Vincent College', label : 'Saint Vincent College'} ,
{value : 'Saint Xavier University', label : 'Saint Xavier University'} ,
{value : 'Salem CollegeWinston ', label : 'Salem CollegeWinston '} ,
{value : 'Salem International University', label : 'Salem International University'} ,
{value : 'Salem State University', label : 'Salem State University'} ,
{value : 'Salisbury University', label : 'Salisbury University'} ,
{value : 'Salus University', label : 'Salus University'} ,
{value : 'Salve Regina University', label : 'Salve Regina University'} ,
{value : 'Sam Houston State University', label : 'Sam Houston State University'} ,
{value : 'Samford University', label : 'Samford University'} ,
{value : 'Samuel Merritt University', label : 'Samuel Merritt University'} ,
{value : 'San Diego Christian College', label : 'San Diego Christian College'} ,
{value : 'San Diego State University', label : 'San Diego State University'} ,
{value : 'San Francisco Art Institute', label : 'San Francisco Art Institute'} ,
{value : 'San Francisco Conservatory of Music', label : 'San Francisco Conservatory of Music'} ,
{value : 'San Francisco State University', label : 'San Francisco State University'} ,
{value : 'San Joaquin College of Law', label : 'San Joaquin College of Law'} ,
{value : 'San José State University', label : 'San José State University'} ,
{value : 'Santa Clara University', label : 'Santa Clara University'} ,
{value : 'Santa Fe College', label : 'Santa Fe College'} ,
{value : 'Sarah Lawrence College', label : 'Sarah Lawrence College'} ,
{value : 'Savannah College of Art ', label : 'Savannah College of Art '} ,
{value : 'Savannah State University', label : 'Savannah State University'} ,
{value : 'Saybrook University', label : 'Saybrook University'} ,
{value : 'Schiller International University', label : 'Schiller International University'} ,
{value : 'School of the Art Institute of Chicago', label : 'School of the Art Institute of Chicago'} ,
{value : 'School of Visual Arts', label : 'School of Visual Arts'} ,
{value : 'Schreiner University', label : 'Schreiner University'} ,
{value : 'Scripps College', label : 'Scripps College'} ,
{value : 'Seattle Central College', label : 'Seattle Central College'} ,
{value : 'Seattle Pacific University', label : 'Seattle Pacific University'} ,
{value : 'Seattle University', label : 'Seattle University'} ,
{value : 'Seminole State College of Florida', label : 'Seminole State College of Florida'} ,
{value : 'Seton Hall University', label : 'Seton Hall University'} ,
{value : 'Seton Hill University', label : 'Seton Hill University'} ,
{value : 'Sewanee: The University of the South', label : 'Sewanee: The University of the South'} ,
{value : 'Shaw University', label : 'Shaw University'} ,
{value : 'Shawnee State University', label : 'Shawnee State University'} ,
{value : 'Shenandoah University', label : 'Shenandoah University'} ,
{value : 'Shepherd University', label : 'Shepherd University'} ,
{value : 'Shippensburg University of Pennsylvania', label : 'Shippensburg University of Pennsylvania'} ,
{value : 'Shorter University', label : 'Shorter University'} ,
{value : 'Siena College', label : 'Siena College'} ,
{value : 'Siena Heights University', label : 'Siena Heights University'} ,
{value : 'Sierra Nevada College', label : 'Sierra Nevada College'} ,
{value : 'Simmons University', label : 'Simmons University'} ,
{value : 'Simpson College', label : 'Simpson College'} ,
{value : 'Simpson University', label : 'Simpson University'} ,
{value : 'SIT Graduate Institute', label : 'SIT Graduate Institute'} ,
{value : 'Skidmore College', label : 'Skidmore College'} ,
{value : 'Slippery Rock University of Pennsylvania', label : 'Slippery Rock University of Pennsylvania'} ,
{value : 'Smith College', label : 'Smith College'} ,
{value : 'Snow College', label : 'Snow College'} ,
{value : 'Sofia University', label : 'Sofia University'} ,
{value : 'Soka University of America', label : 'Soka University of America'} ,
{value : 'Sonoma State University', label : 'Sonoma State University'} ,
{value : 'South Carolina State University', label : 'South Carolina State University'} ,
{value : 'South College', label : 'South College'} ,
{value : 'South Dakota School of Mines and Technology', label : 'South Dakota School of Mines and Technology'} ,
{value : 'South Dakota State University', label : 'South Dakota State University'} ,
{value : 'South Georgia State College', label : 'South Georgia State College'} ,
{value : 'South Texas College', label : 'South Texas College'} ,
{value : 'South Texas College of Law', label : 'South Texas College of Law'} ,
{value : 'South University', label : 'South University'} ,
{value : 'Southeast Missouri State University', label : 'Southeast Missouri State University'} ,
{value : 'Southeastern Baptist College', label : 'Southeastern Baptist College'} ,
{value : 'Southeastern Louisiana University', label : 'Southeastern Louisiana University'} ,
{value : 'Southeastern Oklahoma State University', label : 'Southeastern Oklahoma State University'} ,
{value : 'Southeastern University', label : 'Southeastern University'} ,
{value : 'Southern Adventist University', label : 'Southern Adventist University'} ,
{value : 'Southern Arkansas University', label : 'Southern Arkansas University'} ,
{value : 'Southern California Institute of Architecture', label : 'Southern California Institute of Architecture'} ,
{value : 'Southern College of Optometry', label : 'Southern College of Optometry'} ,
{value : 'Southern Connecticut State University', label : 'Southern Connecticut State University'} ,
{value : 'Southern Illinois University ', label : 'Southern Illinois University '} ,
{value : 'Southern Illinois University ', label : 'Southern Illinois University '} ,
{value : 'Southern Methodist University', label : 'Southern Methodist University'} ,
{value : 'Southern New Hampshire University', label : 'Southern New Hampshire University'} ,
{value : 'Southern Oregon University', label : 'Southern Oregon University'} ,
{value : 'Southern University and A&M College', label : 'Southern University and A&M College'} ,
{value : 'Southern University at New Orleans', label : 'Southern University at New Orleans'} ,
{value : 'Southern University Law ', label : 'Southern University Law '} ,
{value : 'Southern Utah University', label : 'Southern Utah University'} ,
{value : 'Southern Vermont College', label : 'Southern Vermont College'} ,
{value : 'Southern Virginia University', label : 'Southern Virginia University'} ,
{value : 'Southern Wesleyan University', label : 'Southern Wesleyan University'} ,
{value : 'Southwest Baptist University', label : 'Southwest Baptist University'} ,
{value : 'Southwest Minnesota State University', label : 'Southwest Minnesota State University'} ,
{value : 'Southwest University of Visual Arts', label : 'Southwest University of Visual Arts'} ,
{value : 'Southwestern Adventist University', label : 'Southwestern Adventist University'} ,
{value : 'Southwestern Assemblies of God University', label : 'Southwestern Assemblies of God University'} ,
{value : 'Southwestern Christian University', label : 'Southwestern Christian University'} ,
{value : 'Southwestern College', label : 'Southwestern College'} ,
{value : 'Southwestern College, ', label : 'Southwestern College, '} ,
{value : 'Southwestern Law School', label : 'Southwestern Law School'} ,
{value : 'Southwestern Oklahoma State University', label : 'Southwestern Oklahoma State University'} ,
{value : 'Southwestern University', label : 'Southwestern University'} ,
{value : 'Spalding University', label : 'Spalding University'} ,
{value : 'Spelman College', label : 'Spelman College'} ,
{value : 'Spring Arbor University', label : 'Spring Arbor University'} ,
{value : 'Spring Hill College', label : 'Spring Hill College'} ,
{value : 'Springfield College', label : 'Springfield College'} ,
{value : 'St Andrews University', label : 'St Andrews University'} ,
{value : 'St Johns College', label : 'St Johns College'} ,
{value : 'St Petersburg College', label : 'St Petersburg College'} ,
{value : 'St Thomas University', label : 'St Thomas University'} ,
{value : 'St. Augustines University', label : 'St Augustines University'} ,
{value : 'St. Bonaventure University', label : 'St. Bonaventure University'} ,
{value : 'St. Catherine University', label : 'St. Catherine University'} ,
{value : 'St. Cloud State University', label : 'St. Cloud State University'} ,
{value : 'St. Edwards University', label : 'St. Edwards University'} ,
{value : 'St. Francis College', label : 'St. Francis College'} ,
{value : 'St. John Fisher College', label : 'St. John Fisher College'} ,
{value : 'St. Johns University', label : 'St. Johns University'} ,
{value : 'St. Josephs College', label : 'St. Josephs College'} ,
{value : 'St. Lawrence University', label : 'St. Lawrence University'} ,
{value : 'St. Louis Christian College', label : 'St. Louis Christian College'} ,
{value : 'St. Louis College of Pharmacy', label : 'St. Louis College of Pharmacy'} ,
{value : 'St. Marys College of Maryland', label : 'St. Marys College of Maryland'} ,
{value : 'St. Marys University', label : 'St. Marys University'} ,
{value : 'St. Norbert College', label : 'St. Norbert College'} ,
{value : 'St. Olaf College', label : 'St. Olaf College'} ,
{value : 'St. Thomas Aquinas College', label : 'St. Thomas Aquinas College'} ,
{value : 'Stanford University', label : 'Stanford University'} ,
{value : 'State College of Florida-Manatee-Sarasota', label : 'State College of Florida-Manatee-Sarasota'} ,
{value : 'State University of New York at Fredonia', label : 'State University of New York at Fredonia'} ,
{value : 'State University of New York at New Paltz', label : 'State University of New York at New Paltz'} ,
{value : 'State University of New York at OswegoOswego', label : 'State University of New York at OswegoOswego'} ,
{value : 'State University of New York College at Plattsburgh', label : 'State University of New York College at Plattsburgh'} ,
{value : 'Stephen F. Austin State University', label : 'Stephen F. Austin State University'} ,
{value : 'Stephens College', label : 'Stephens College'} ,
{value : 'Sterling College', label : 'Sterling College'} ,
{value : 'Sterling College, ', label : 'Sterling College, '} ,
{value : 'Stetson University', label : 'Stetson University'} ,
{value : 'Stevens Institute of Technology', label : 'Stevens Institute of Technology'} ,
{value : 'Stevenson University', label : 'Stevenson University'} ,
{value : 'Stillman College', label : 'Stillman College'} ,
{value : 'Stockton University', label : 'Stockton University'} ,
{value : 'Stonehill College', label : 'Stonehill College'} ,
{value : 'Stony Brook University', label : 'Stony Brook University'} ,
{value : 'Strayer University', label : 'Strayer University'} ,
{value : 'Suffolk University', label : 'Suffolk University'} ,
{value : 'Sul Ross State University', label : 'Sul Ross State University'} ,
{value : 'Sullivan University', label : 'Sullivan University'} ,
{value : 'SUNY Canton', label : 'SUNY Canton'} ,
{value : 'SUNY Cobleskill', label : 'SUNY Cobleskill'} ,
{value : 'SUNY College at Old Westbury', label : 'SUNY College at Old Westbury'} ,
{value : 'SUNY College at Oneonta', label : 'SUNY College at Oneonta'} ,
{value : 'SUNY College of Environmental Science and Forestry', label : 'SUNY College of Environmental Science and Forestry'} ,
{value : 'SUNY College of Optometry', label : 'SUNY College of Optometry'} ,
{value : 'SUNY Cortland', label : 'SUNY Cortland'} ,
{value : 'SUNY Delhi', label : 'SUNY Delhi'} ,
{value : 'SUNY Downstate Medical Center', label : 'SUNY Downstate Medical Center'} ,
{value : 'SUNY Empire State College', label : 'SUNY Empire State College'} ,
{value : 'SUNY Geneseo', label : 'SUNY Geneseo'} ,
{value : 'SUNY Maritime College', label : 'SUNY Maritime College'} ,
{value : 'SUNY Polytechnic Institute', label : 'SUNY Polytechnic Institute'} ,
{value : 'SUNY Upstate Medical University', label : 'SUNY Upstate Medical University'} ,
{value : 'Susquehanna College', label : 'Susquehanna College'} ,
{value : 'Swarthmore College', label : 'Swarthmore College'} ,
{value : 'Sweet Briar College', label : 'Sweet Briar College'} ,
{value : 'Syracuse University', label : 'Syracuse University'} ,
{value : 'Tabor College', label : 'Tabor College'} ,
{value : 'Talladega College', label : 'Talladega College'} ,
{value : 'Tarleton State University', label : 'Tarleton State University'} ,
{value : 'Taylor University', label : 'Taylor University'} ,
{value : 'Temple University', label : 'Temple University'} ,
{value : 'Tennessee State University', label : 'Tennessee State University'} ,
{value : 'Tennessee Tech University', label : 'Tennessee Tech University'} ,
{value : 'Tennessee Wesleyan University', label : 'Tennessee Wesleyan University'} ,
{value : 'Texas A&M International University', label : 'Texas A&M International University'} ,
{value : 'Texas A&M UniversityCollege ', label : 'Texas A&M UniversityCollege '} ,
{value : 'Texas A&M University at Galveston', label : 'Texas A&M University at Galveston'} ,
{value : 'Texas A&M Univesity-Commerce', label : 'Texas A&M Univesity-Commerce'} ,
{value : 'Texas A&M University-Corpus Christi', label : 'Texas A&M University-Corpus Christi'} ,
{value : 'Texas A&M University-Kingsville', label : 'Texas A&M University-Kingsville'} ,
{value : 'Texas A&M University-Texarkana', label : 'Texas A&M University-Texarkana'} ,
{value : 'Texas Christian University', label : 'Texas Christian University'} ,
{value : 'Texas College', label : 'Texas College'} ,
{value : 'Texas Lutheran University', label : 'Texas Lutheran University'} ,
{value : 'Texas Southern University', label : 'Texas Southern University'} ,
{value : 'Texas State University', label : 'Texas State University'} ,
{value : 'Texas Tech University', label : 'Texas Tech University'} ,
{value : 'Texas Tech University Health Sciences Center', label : 'Texas Tech University Health Sciences Center'} ,
{value : 'Texas Wesleyan University', label : 'Texas Wesleyan University'} ,
{value : 'Texas Womans University', label : 'Texas Womans University'} ,
{value : 'The American College of Financial Services', label : 'The American College of Financial Services'} ,
{value : 'The Baptist College of Florida', label : 'The Baptist College of Florida'} ,
{value : 'The Catholic University of America', label : 'The Catholic University of America'} ,
{value : 'The Chicago School of Professional Psychology', label : 'The Chicago School of Professional Psychology'} ,
{value : 'The Citadel, The Military College of South Carolina', label : 'The Citadel, The Military College of South Carolina'} ,
{value : 'The City College of New York', label : 'The City College of New York'} ,
{value : 'The College at Brockport', label : 'The College at Brockport'} ,
{value : 'The College of Idaho', label : 'The College of Idaho'} ,
{value : 'The College of New Jersey', label : 'The College of New Jersey'} ,
{value : 'The College of Saint Rose', label : 'The College of Saint Rose'} ,
{value : 'The College of St. Scholastica', label : 'The College of St. Scholastica'} ,
{value : 'The College of Westchester', label : 'The College of Westchester'} ,
{value : 'The College of Wooster', label : 'The College of Wooster'} ,
{value : 'The Cooper Union for the Advancement of Science and Art', label : 'The Cooper Union for the Advancement of Science and Art'} ,
{value : 'The Culinary Institute of America', label : 'The Culinary Institute of America'} ,
{value : 'The Evergreen State College', label : 'The Evergreen State College'} ,
{value : 'The Graduate Center, CUNY', label : 'The Graduate Center, CUNY'} ,
{value : 'The Institute of World Politics', label : 'The Institute of World Politics'} ,
{value : 'The Juilliard School', label : 'The Juilliard School'} ,
{value : 'The Kings College', label : 'The Kings College'} ,
{value : 'The Kings University', label : 'The Kings University'} ,
{value : 'The Masters University', label : 'The Masters University'} ,
{value : 'The New School', label : 'The New School'} ,
{value : 'The Ohio State ', label : 'The Ohio State '} ,
{value : 'The Rockefeller University', label : 'The Rockefeller University'} ,
{value : 'The Sage Colleges', label : 'The Sage Colleges'} ,
{value : 'The School of Architecture at Taliesin', label : 'The School of Architecture at Taliesin'} ,
{value : 'The State University of New York at Potsdam', label : 'The State University of New York at Potsdam'} ,
{value : 'The University of Alabama', label : 'The University of Alabama'} ,
{value : 'The University of Alabama in Huntsville', label : 'The University of Alabama in Huntsville'} ,
{value : 'The University of Arizona', label : 'The University of Arizona'} ,
{value : 'The University of Findlay', label : 'The University of Findlay'} ,
{value : 'The University of Maine', label : 'The University of Maine'} ,
{value : 'The University of Memphis', label : 'The University of Memphis'} ,
{value : 'The University of Montana', label : 'The University of Montana'} ,
{value : 'The University of Montana Western', label : 'The University of Montana Western'} ,
{value : 'The University of Oklahoma', label : 'The University of Oklahoma'} ,
{value : 'The University of Oklahoma Health Sciences Center', label : 'The University of Oklahoma Health Sciences Center'} ,
{value : 'The University of Tampa', label : 'The University of Tampa'} ,
{value : 'The University of Tennessee at Chattanooga', label : 'The University of Tennessee at Chattanooga'} ,
{value : 'The University of Tennessee at Martin', label : 'The University of Tennessee at Martin'} ,
{value : 'The University of Tennessee Health Science Center', label : 'The University of Tennessee Health Science Center'} ,
{value : 'The University of Tennessee, Knoxville', label : 'The University of Tennessee, Knoxville'} ,
{value : 'The University of Texas at Arlington', label : 'The University of Texas at Arlington'} ,
{value : 'The University of Texas at Austin', label : 'The University of Texas at Austin'} ,
{value : 'The University of Texas at Dallas', label : 'The University of Texas at Dallas'} ,
{value : 'The University of Texas at El Paso', label : 'The University of Texas at El Paso'} ,
{value : 'The University of Texas at San Antonio', label : 'The University of Texas at San Antonio'} ,
{value : 'The University of Texas at Tyler', label : 'The University of Texas at Tyler'} ,
{value : 'The University of Texas Health Science Center at Houston', label : 'The University of Texas Health Science Center at Houston'} ,
{value : 'The University of Texas Health Science Center at San Antonio', label : 'The University of Texas Health Science Center at San Antonio'} ,
{value : 'The University of Texas Health Science Center at Tyler', label : 'The University of Texas Health Science Center at Tyler'} ,
{value : 'The University of Texas MD Anderson Cancer Center', label : 'The University of Texas MD Anderson Cancer Center'} ,
{value : 'The University of Texas Medial Branch at Galveston', label : 'The University of Texas Medial Branch at Galveston'} ,
{value : 'The University of Texas of the Permian Basin', label : 'The University of Texas of the Permian Basin'} ,
{value : 'The Univesity of the Arts', label : 'The Univesity of the Arts'} ,
{value : 'The University of Tulsa', label : 'The University of Tulsa'} ,
{value : 'The University of UtahSalt Lake ', label : 'The University of UtahSalt Lake '} ,
{value : 'The University of Virginias College at Wise', label : 'The University of Virginias College at Wise'} ,
{value : 'The University of West Alabama', label : 'The University of West Alabama'} ,
{value : 'The University of West Los Angeles', label : 'The University of West Los Angeles'} ,
{value : 'The Wright Institute', label : 'The Wright Institute'} ,
{value : 'Thiel College', label : 'Thiel College'} ,
{value : 'Thomas Aquinas College', label : 'Thomas Aquinas College'} ,
{value : 'Thomas College', label : 'Thomas College'} ,
{value : 'Thomas Edison State University', label : 'Thomas Edison State University'} ,
{value : 'Thomas Jefferson School of Law', label : 'Thomas Jefferson School of Law'} ,
{value : 'Thomas Jefferson University', label : 'Thomas Jefferson University'} ,
{value : 'Thomas More College of Liberal Arts', label : 'Thomas More College of Liberal Arts'} ,
{value : 'Thomas More University', label : 'Thomas More University'} ,
{value : 'Thomas University', label : 'Thomas University'} ,
{value : 'Tiffin University', label : 'Tiffin University'} ,
{value : 'Toccoa Falls College', label : 'Toccoa Falls College'} ,
{value : 'Tougaloo College', label : 'Tougaloo College'} ,
{value : 'Touro College', label : 'Touro College'} ,
{value : 'Touro University California', label : 'Touro University California'} ,
{value : 'Towson University', label : 'Towson University'} ,
{value : 'Transylvania University', label : 'Transylvania University'} ,
{value : 'Trevecca Nazarene University', label : 'Trevecca Nazarene University'} ,
{value : 'Trine University', label : 'Trine University'} ,
{value : 'Trinity Bible College', label : 'Trinity Bible College'} ,
{value : 'Trinity Christian College', label : 'Trinity Christian College'} ,
{value : 'Trinity CollegeHartford', label : 'Trinity CollegeHartford'} ,
{value : 'Trinity College of Florida', label : 'Trinity College of Florida'} ,
{value : 'Trinity College of Nursing & Health Sciences', label : 'Trinity College of Nursing & Health Sciences'} ,
{value : 'Trinity International University', label : 'Trinity International University'} ,
{value : 'Trinity University', label : 'Trinity University'} ,
{value : 'Trinity Washington University', label : 'Trinity Washington University'} ,
{value : 'Tri-State Bible College', label : 'Tri-State Bible College'} ,
{value : 'Trocaire College', label : 'Trocaire College'} ,
{value : 'Troy University', label : 'Troy University'} ,
{value : 'Truett McConnell University', label : 'Truett McConnell University'} ,
{value : 'Truman State University', label : 'Truman State University'} ,
{value : 'Tufts University', label : 'Tufts University'} ,
{value : 'Tulane University', label : 'Tulane University'} ,
{value : 'Tusculum College', label : 'Tusculum College'} ,
{value : 'Tuskegee University', label : 'Tuskegee University'} ,
{value : 'Uniformd Services University of the Health Sciences', label : 'Uniformd Services University of the Health Sciences'} ,
{value : 'Union College', label : 'Union College'} ,
{value : 'Union College, Kentucky', label : 'Union College, Kentucky'} ,
{value : 'Union College, Nebraska', label : 'Union College, Nebraska'} ,
{value : 'Union Institute & University', label : 'Union Institute & University'} ,
{value : 'Union University', label : 'Union University'} ,
{value : 'United States Air Force Academy', label : 'United States Air Force Academy'} ,
{value : 'United States Coast Guard Academy', label : 'United States Coast Guard Academy'} ,
{value : 'United States Merchant Marine Academy', label : 'United States Merchant Marine Academy'} ,
{value : 'United States Military Academy', label : 'United States Military Academy'} ,
{value : 'United States Naval Academy', label : 'United States Naval Academy'} ,
{value : 'United States Sports Academy', label : 'United States Sports Academy'} ,
{value : 'United States University', label : 'United States University'} ,
{value : 'Unity CollegeUnity', label : 'Unity CollegeUnity'} ,
{value : 'University at Albany, State University of New York', label : 'University at Albany, State University of New York'} ,
{value : 'University at Buffalo, State University of New York', label : 'University at Buffalo, State University of New York'} ,
{value : 'University of Advancing Technology', label : 'University of Advancing Technology'} ,
{value : 'University of Akron', label : 'University of Akron'} ,
{value : 'University of Alabma at Birmingham', label : 'University of Alabma at Birmingham'} ,
{value : 'University of Alaska Anchorage', label : 'University of Alaska Anchorage'} ,
{value : 'University of Alaska Fairbanks', label : 'University of Alaska Fairbanks'} ,
{value : 'University of Alaska Southeast', label : 'University of Alaska Southeast'} ,
{value : 'University of Arkansas', label : 'University of Arkansas'} ,
{value : 'University of Arkansas - Fort Smith', label : 'University of Arkansas - Fort Smith'} ,
{value : 'University of Arkansas at Little Rock', label : 'University of Arkansas at Little Rock'} ,
{value : 'University of Arkansas at Monticello', label : 'University of Arkansas at Monticello'} ,
{value : 'University of Arkansas at Pine Bluff', label : 'University of Arkansas at Pine Bluff'} ,
{value : 'University of Arkansas for Medical Sciences', label : 'University of Arkansas for Medical Sciences'} ,
{value : 'University of Baltimore', label : 'University of Baltimore'} ,
{value : 'University of Bridgeport', label : 'University of Bridgeport'} ,
{value : 'University of California, Berkeley', label : 'University of California, Berkeley'} ,
{value : 'University of California, Davis', label : 'University of California, Davis'} ,
{value : 'University of California, Hastings College of the Law', label : 'University of California, Hastings College of the Law'} ,
{value : 'University of California, Irvine', label : 'University of California, Irvine'} ,
{value : 'University of California, Los Angeles', label : 'University of California, Los Angeles'} ,
{value : 'University of California, Merced', label : 'University of California, Merced'} ,
{value : 'University of California, Riverside', label : 'University of California, Riverside'} ,
{value : 'University of California, San Diego', label : 'University of California, San Diego'} ,
{value : 'University of California, San Francisco', label : 'University of California, San Francisco'} ,
{value : 'University of California, Santa Barbara', label : 'University of California, Santa Barbara'} ,
{value : 'University of California, Santa Cruz', label : 'University of California, Santa Cruz'} ,
{value : 'University of Central Arkansas', label : 'University of Central Arkansas'} ,
{value : 'University of Central Florida', label : 'University of Central Florida'} ,
{value : 'University of Central Missouri', label : 'University of Central Missouri'} ,
{value : 'University of Central Oklahoma', label : 'University of Central Oklahoma'} ,
{value : 'University of Chicago', label : 'University of Chicago'} ,
{value : 'University of Cincinnati', label : 'University of Cincinnati'} ,
{value : 'University of Colorado Boulder', label : 'University of Colorado Boulder'} ,
{value : 'University of Colorado Colorado Springs', label : 'University of Colorado Colorado Springs'} ,
{value : 'University of Colorado Denver', label : 'University of Colorado Denver'} ,
{value : 'University of Connecticut', label : 'University of Connecticut'} ,
{value : 'University of Dallas', label : 'University of Dallas'} ,
{value : 'University of Dayton', label : 'University of Dayton'} ,
{value : 'University of Delaware', label : 'University of Delaware'} ,
{value : 'University of Denver', label : 'University of Denver'} ,
{value : 'University of Detroit Mercy', label : 'University of Detroit Mercy'} ,
{value : 'University of Dubuque', label : 'University of Dubuque'} ,
{value : 'University of Evansville', label : 'University of Evansville'} ,
{value : 'University of Florida', label : 'University of Florida'} ,
{value : 'University of HartfordWest ', label : 'University of HartfordWest '} ,
{value : 'University of Hawaii at Hilo', label : 'University of Hawaii at Hilo'} ,
{value : 'University of Hawaii at Manoa', label : 'University of Hawaii at Manoa'} ,
{value : 'University of Hawaii-West Oahu', label : 'University of Hawaii-West Oahu'} ,
{value : 'University of Holy Cross', label : 'University of Holy Cross'} ,
{value : 'University of Houston', label : 'University of Houston'} ,
{value : 'University of Houston-Clear Lake', label : 'University of Houston-Clear Lake'} ,
{value : 'University of Houston-Downtown', label : 'University of Houston-Downtown'} ,
{value : 'University of Houston-Victoria', label : 'University of Houston-Victoria'} ,
{value : 'University of Idaho', label : 'University of Idaho'} ,
{value : 'University of Illinois at Chicago', label : 'University of Illinois at Chicago'} ,
{value : 'University of Illinois at Springfield', label : 'University of Illinois at Springfield'} ,
{value : 'University of Illinois at Urbana-Champaign', label : 'University of Illinois at Urbana-Champaign'} ,
{value : 'University of Indianapolis', label : 'University of Indianapolis'} ,
{value : 'University of Iowa', label : 'University of Iowa'} ,
{value : 'University of Jamestown', label : 'University of Jamestown'} ,
{value : 'University of Kansas', label : 'University of Kansas'} ,
{value : 'University of Kentucky', label : 'University of Kentucky'} ,
{value : 'University of La Verne', label : 'University of La Verne'} ,
{value : 'University of Louisiana at Lafayette', label : 'University of Louisiana at Lafayette'} ,
{value : 'University of Louisiana at Monroe', label : 'University of Louisiana at Monroe'} ,
{value : 'University of Louisville', label : 'University of Louisville'} ,
{value : 'University of Lynchburg', label : 'University of Lynchburg'} ,
{value : 'University of Maine at Augusta', label : 'University of Maine at Augusta'} ,
{value : 'University of Maine at Farmington', label : 'University of Maine at Farmington'} ,
{value : 'University of Maine at Fort Kent', label : 'University of Maine at Fort Kent'} ,
{value : 'University of Maine at Machias', label : 'University of Maine at Machias'} ,
{value : 'University of Maine at Presque Isle', label : 'University of Maine at Presque Isle'} ,
{value : 'University of Mary', label : 'University of Mary'} ,
{value : 'University of Mary Hardin-Baylor', label : 'University of Mary Hardin-Baylor'} ,
{value : 'University of Mary Washington', label : 'University of Mary Washington'} ,
{value : 'University of MarylandCollege Park', label : 'University of MarylandCollege Park'} ,
{value : 'University of Maryland Eastern Shore', label : 'University of Maryland Eastern Shore'} ,
{value : 'University of Maryland, Baltimore', label : 'University of Maryland, Baltimore'} ,
{value : 'University of Maryland, Baltimore County', label : 'University of Maryland, Baltimore County'} ,
{value : 'University of Massachusetts Amherst', label : 'University of Massachusetts Amherst'} ,
{value : 'University of Massachusetts Boston', label : 'University of Massachusetts Boston'} ,
{value : 'University of Massachusetts Dartmouth', label : 'University of Massachusetts Dartmouth'} ,
{value : 'University of Massachusetts Lowell', label : 'University of Massachusetts Lowell'} ,
{value : 'University of Massachusetts Medical School', label : 'University of Massachusetts Medical School'} ,
{value : 'University of Miami', label : 'University of Miami'} ,
{value : 'University of Michigan', label : 'University of Michigan'} ,
{value : 'University of Michigan-Dearborn', label : 'University of Michigan-Dearborn'} ,
{value : 'University of Michigan-Flint', label : 'University of Michigan-Flint'} ,
{value : 'University of Minnesota', label : 'University of Minnesota'} ,
{value : 'University of MississippiUniversity', label : 'University of MississippiUniversity'} ,
{value : 'University of Mississippi Medical Center', label : 'University of Mississippi Medical Center'} ,
{value : 'University of MissouriColumbia', label : 'University of MissouriColumbia'} ,
{value : 'University of Missouri-Kansas City', label : 'University of Missouri-Kansas City'} ,
{value : 'University of Missouri-St. Louis', label : 'University of Missouri-St. Louis'} ,
{value : 'University of Mobile', label : 'University of Mobile'} ,
{value : 'University of Montevallo', label : 'University of Montevallo'} ,
{value : 'University of Mount Olive', label : 'University of Mount Olive'} ,
{value : 'University of Mount Union', label : 'University of Mount Union'} ,
{value : 'University of Nebraska at Kearney', label : 'University of Nebraska at Kearney'} ,
{value : 'University of Nebraska at Omaha', label : 'University of Nebraska at Omaha'} ,
{value : 'University of Nebraska Medical Center', label : 'University of Nebraska Medical Center'} ,
{value : 'University of Nebraska-Lincoln', label : 'University of Nebraska-Lincoln'} ,
{value : 'University of Nevada, Las Vegas', label : 'University of Nevada, Las Vegas'} ,
{value : 'University of Nevada, ', label : 'University of Nevada, '} ,
{value : 'University of New England', label : 'University of New England'} ,
{value : 'University of New Hampshire', label : 'University of New Hampshire'} ,
{value : 'University of New Haven', label : 'University of New Haven'} ,
{value : 'University of New Mexico', label : 'University of New Mexico'} ,
{value : 'University of New OrleansNew', label : 'University of New OrleansNew'} ,
{value : 'University of North AlabamaFlorence', label : 'University of North AlabamaFlorence'} ,
{value : 'University of North Carolina at Asheville', label : 'University of North Carolina at Asheville'} ,
{value : 'University of North Carolina at Chapel Hill', label : 'University of North Carolina at Chapel Hill'} ,
{value : 'University of North Carolina at Charlotte', label : 'University of North Carolina at Charlotte'} ,
{value : 'University of North Carolina at Greensboro', label : 'University of North Carolina at Greensboro'} ,
{value : 'University of North Carolina at Pembroke', label : 'University of North Carolina at Pembroke'} ,
{value : 'University of North Carolina School of the Arts', label : 'University of North Carolina School of the Arts'} ,
{value : 'University of North Carolina ', label : 'University of North Carolina '} ,
{value : 'University of North Dakota', label : 'University of North Dakota'} ,
{value : 'University of North Florida', label : 'University of North Florida'} ,
{value : 'University of North Georgia', label : 'University of North Georgia'} ,
{value : 'University of North Texas', label : 'University of North Texas'} ,
{value : 'University of North Texas Health Science ', label : 'University of North Texas Health Science '} ,
{value : 'University of Northern ', label : 'University of Northern '} ,
{value : 'University of Northern Iowa', label : 'University of Northern Iowa'} ,
{value : 'University of Northwestern ', label : 'University of Northwestern '} ,
{value : 'University of Northwestern ', label : 'University of Northwestern '} ,
{value : 'University of Notre Dame', label : 'University of Notre Dame'} ,
{value : 'University of Oregon', label : 'University of Oregon'} ,
{value : 'University of Pennsylvania', label : 'University of Pennsylvania'} ,
{value : 'University of Pikeville', label : 'University of Pikeville'} ,
{value : 'University of Pittsburgh', label : 'University of Pittsburgh'} ,
{value : 'University of Portland ', label : 'University of Portland '} ,
{value : 'University of Puget Sound', label : 'University of Puget Sound'} ,
{value : 'University of Redlands', label : 'University of Redlands'} ,
{value : 'University of Rhode Island', label : 'University of Rhode Island'} ,
{value : 'University of Richmond', label : 'University of Richmond'} ,
{value : 'University of Rio Grande', label : 'University of Rio Grande'} ,
{value : 'University of Rochester', label : 'University of Rochester'} ,
{value : 'University of Saint Francis', label : 'University of Saint Francis'} ,
{value : 'University of Saint Joseph', label : 'University of Saint Joseph'} ,
{value : 'University of Saint Mary', label : 'University of Saint Mary'} ,
{value : 'University of San Diego', label : 'University of San Diego'} ,
{value : 'University of San Francisco', label : 'University of San Francisco'} ,
{value : 'University of Science and Arts of Oklahoma', label : 'University of Science and Arts of Oklahoma'} ,
{value : 'University of Scranton', label : 'University of Scranton'} ,
{value : 'University of Sioux Falls', label : 'University of Sioux Falls'} ,
{value : 'University of South Alabama', label : 'University of South Alabama'} ,
{value : 'University of South Carolina', label : 'University of South Carolina'} ,
{value : 'University of South Carolina-Aiken', label : 'University of South Carolina-Aiken'} ,
{value : 'University of South Carolina-Beaufort', label : 'University of South Carolina-Beaufort'} ,
{value : 'University of South Carolina-Upstate', label : 'University of South Carolina-Upstate'} ,
{value : 'University of South Dakota', label : 'University of South Dakota'} ,
{value : 'University of South Florida', label : 'University of South Florida'} ,
{value : 'University of Southern California', label : 'University of Southern California'} ,
{value : 'University of Southern Indiana', label : 'University of Southern Indiana'} ,
{value : 'University of Southern Maine', label : 'University of Southern Maine'} ,
{value : 'University of Southern Mississippi', label : 'University of Southern Mississippi'} ,
{value : 'University of St ThomasHouston', label : 'University of St ThomasHouston'} ,
{value : 'University of St. Augustine for Health Sciences', label : 'University of St. Augustine for Health Sciences'} ,
{value : 'University of St. Francis', label : 'University of St. Francis'} ,
{value : 'University of St. Thomas', label : 'University of St. Thomas'} ,
{value : 'University of Texas Rio Grande Valley', label : 'University of Texas Rio Grande Valley'} ,
{value : 'University of Texas Southwestern Medical Center', label : 'University of Texas Southwestern Medical Center'} ,
{value : 'University of the Cumberlands', label : 'University of the Cumberlands'} ,
{value : 'University of the District of Columbia', label : 'University of the District of Columbia'} ,
{value : 'University of the Incarnate Word', label : 'University of the Incarnate Word'} ,
{value : 'University of the Ozarks', label : 'University of the Ozarks'} ,
{value : 'University of the Pacific', label : 'University of the Pacific'} ,
{value : 'University of the Potomac', label : 'University of the Potomac'} ,
{value : 'University of the Sciences in Philadelphia', label : 'University of the Sciences in Philadelphia'} ,
{value : 'University of the Southwest', label : 'University of the Southwest'} ,
{value : 'University of the West', label : 'University of the West'} ,
{value : 'University of Toledo', label : 'University of Toledo'} ,
{value : 'University of Valley Forge', label : 'University of Valley Forge'} ,
{value : 'University of Vermont', label : 'University of Vermont'} ,
{value : 'University of Virginia', label : 'University of Virginia'} ,
{value : 'University of Washington', label : 'University of Washington'} ,
{value : 'University of West Florida', label : 'University of West Florida'} ,
{value : 'University of West Georgia', label : 'University of West Georgia'} ,
{value : 'University of Western States', label : 'University of Western States'} ,
{value : 'University of Wisconsin-Eau Claire', label : 'University of Wisconsin-Eau Claire'} ,
{value : 'University of Wisconsin-Green Bay', label : 'University of Wisconsin-Green Bay'} ,
{value : 'University of Wisconsin-La Crosse', label : 'University of Wisconsin-La Crosse'} ,
{value : 'University of Wisconsin-Madison', label : 'University of Wisconsin-Madison'} ,
{value : 'University of Wisconsin-Milwaukee', label : 'University of Wisconsin-Milwaukee'} ,
{value : 'University of Wisconsin-Oshkosh', label : 'University of Wisconsin-Oshkosh'} ,
{value : 'University of Wisconsin-Parkside', label : 'University of Wisconsin-Parkside'} ,
{value : 'University of Wisconsin-Platteville', label : 'University of Wisconsin-Platteville'} ,
{value : 'University of Wisconsin-River Falls', label : 'University of Wisconsin-River Falls'} ,
{value : 'University of Wisconsin-Stevens Point', label : 'University of Wisconsin-Stevens Point'} ,
{value : 'University of Wisconsin-Stout', label : 'University of Wisconsin-Stout'} ,
{value : 'University of Wisconsin-Superior', label : 'University of Wisconsin-Superior'} ,
{value : 'University of Wisconsin-Whitewater', label : 'University of Wisconsin-Whitewater'} ,
{value : 'University of Wyoming', label : 'University of Wyoming'} ,
{value : 'Upper Iowa University', label : 'Upper Iowa University'} ,
{value : 'Urbana University', label : 'Urbana University'} ,
{value : 'Ursinus CollegeCollegeville', label : 'Ursinus CollegeCollegeville'} ,
{value : 'Ursuline College', label : 'Ursuline College'} ,
{value : 'Utah State University', label : 'Utah State University'} ,
{value : 'Utah Valley University', label : 'Utah Valley University'} ,
{value : 'Utica College', label : 'Utica College'} ,
{value : 'Valdosta State University', label : 'Valdosta State University'} ,
{value : 'Valencia College', label : 'Valencia College'} ,
{value : 'Valley City State University', label : 'Valley City State University'} ,
{value : 'Valparaiso University', label : 'Valparaiso University'} ,
{value : 'Vanderbilt University', label : 'Vanderbilt University'} ,
{value : 'VanderCook College of Music', label : 'VanderCook College of Music'} ,
{value : 'Vanguard University of Southern California', label : 'Vanguard University of Southern California'} ,
{value : 'Vassar College', label : 'Vassar College'} ,
{value : 'Vaughn College of Aeronautics and Technology', label : 'Vaughn College of Aeronautics and Technology'} ,
{value : 'Vermont College of Fine Arts', label : 'Vermont College of Fine Arts'} ,
{value : 'Vermont Law School', label : 'Vermont Law School'} ,
{value : 'Vermont Technical College', label : 'Vermont Technical College'} ,
{value : 'Villa Maria College', label : 'Villa Maria College'} ,
{value : 'Villanova University', label : 'Villanova University'} ,
{value : 'Vincennes University', label : 'Vincennes University'} ,
{value : 'Virginia Commonwealth University', label : 'Virginia Commonwealth University'} ,
{value : 'Virginia Military Institute', label : 'Virginia Military Institute'} ,
{value : 'Virginia Polytechnic Institute and State University', label : 'Virginia Polytechnic Institute and State University'} ,
{value : 'Virginia State University', label : 'Virginia State University'} ,
{value : 'Virginia Union University', label : 'Virginia Union University'} ,
{value : 'Virginia Wesleyan University', label : 'Virginia Wesleyan University'} ,
{value : 'Viterbo University', label : 'Viterbo University'} ,
{value : 'Voorhees College', label : 'Voorhees College'} ,
{value : 'Wabash College', label : 'Wabash College'} ,
{value : 'Wade College', label : 'Wade College'} ,
{value : 'Wagner College', label : 'Wagner College'} ,
{value : 'Wake Forest University', label : 'Wake Forest University'} ,
{value : 'Waldorf University', label : 'Waldorf University'} ,
{value : 'Walla Walla University', label : 'Walla Walla University'} ,
{value : 'Walsh College', label : 'Walsh College'} ,
{value : 'Walsh University', label : 'Walsh University'} ,
{value : 'Warner Pacific College', label : 'Warner Pacific College'} ,
{value : 'Warner University', label : 'Warner University'} ,
{value : 'Warren Wilson College', label : 'Warren Wilson College'} ,
{value : 'Wartburg College', label : 'Wartburg College'} ,
{value : 'Washburn University', label : 'Washburn University'} ,
{value : 'Washington & Jefferson College', label : 'Washington & Jefferson College'} ,
{value : 'Washington Adventist University', label : 'Washington Adventist University'} ,
{value : 'Washington and Lee University', label : 'Washington and Lee University'} ,
{value : 'Washington College', label : 'Washington College'} ,
{value : 'Washington State University', label : 'Washington State University'} ,
{value : 'Washington University in St. Louis', label : 'Washington University in St. Louis'} ,
{value : 'Watkins College of Art, Design & Film', label : 'Watkins College of Art, Design & Film'} ,
{value : 'Wayne State College', label : 'Wayne State College'} ,
{value : 'Wayne State University', label : 'Wayne State University'} ,
{value : 'Waynesburg University', label : 'Waynesburg University'} ,
{value : 'Webb InstituteGlen ', label : 'Webb InstituteGlen '} ,
{value : 'Webber International University', label : 'Webber International University'} ,
{value : 'Weber State University', label : 'Weber State University'} ,
{value : 'Webster University', label : 'Webster University'} ,
{value : 'Welch College', label : 'Welch College'} ,
{value : 'Wellesley College', label : 'Wellesley College'} ,
{value : 'Wells College', label : 'Wells College'} ,
{value : 'Wentworth Institute of Technology', label : 'Wentworth Institute of Technology'} ,
{value : 'Wesley College', label : 'Wesley College'} ,
{value : 'Wesleyan College', label : 'Wesleyan College'} ,
{value : 'Wesleyan University', label : 'Wesleyan University'} ,
{value : 'West Chester University of Pennsylvania', label : 'West Chester University of Pennsylvania'} ,
{value : 'West Coast University-Los Angeles', label : 'West Coast University-Los Angeles'} ,
{value : 'West Liberty University', label : 'West Liberty University'} ,
{value : 'West Texas A&M University', label : 'West Texas A&M University'} ,
{value : 'West Virginia School of Osteopathic Medicine', label : 'West Virginia School of Osteopathic Medicine'} ,
{value : 'West Virginia State University', label : 'West Virginia State University'} ,
{value : 'West Virginia University', label : 'West Virginia University'} ,
{value : 'West Virginia University at Parkersburg', label : 'West Virginia University at Parkersburg'} ,
{value : 'West Virginia University Institute of Technology', label : 'West Virginia University Institute of Technology'} ,
{value : 'West Virginia Wesleyan College', label : 'West Virginia Wesleyan College'} ,
{value : 'Western Carolina University', label : 'Western Carolina University'} ,
{value : 'Western Colorado University', label : 'Western Colorado University'} ,
{value : 'Western Connecticut State University', label : 'Western Connecticut State University'} ,
{value : 'Western Illinois University', label : 'Western Illinois University'} ,
{value : 'Western Kentucky University', label : 'Western Kentucky University'} ,
{value : 'Western Michigan Univerity', label : 'Western Michigan Univerity'} ,
{value : 'Western Nevada College', label : 'Western Nevada College'} ,
{value : 'Western New England University', label : 'Western New England University'} ,
{value : 'Western New Mexico University', label : 'Western New Mexico University'} ,
{value : 'Western Oregon University', label : 'Western Oregon University'} ,
{value : 'Western State College of Law', label : 'Western State College of Law'} ,
{value : 'Western University of Health Sciences', label : 'Western University of Health Sciences'} ,
{value : 'Western Washington University', label : 'Western Washington University'} ,
{value : 'Westfield State University', label : 'Westfield State University'} ,
{value : 'Westminster College', label : 'Westminster College'} ,
{value : 'Westminster College, ', label : 'Westminster College, '} ,
{value : 'Westminster College, Pennsylvania', label : 'Westminster College, Pennsylvania'} ,
{value : 'Westmont College', label : 'Westmont College'} ,
{value : 'Wheaton College', label : 'Wheaton College'} ,
{value : 'Wheaton College, Massachusetts', label : 'Wheaton College, Massachusetts'} ,
{value : 'Wheeling University', label : 'Wheeling University'} ,
{value : 'Whitman CollegeWalla ', label : 'Whitman CollegeWalla '} ,
{value : 'Whittier College', label : 'Whittier College'} ,
{value : 'Whitworth University', label : 'Whitworth University'} ,
{value : 'Wichita State University', label : 'Wichita State University'} ,
{value : 'Widener University', label : 'Widener University'} ,
{value : 'Wilberforce University', label : 'Wilberforce University'} ,
{value : 'Wiley College', label : 'Wiley College'} ,
{value : 'Wilkes University', label : 'Wilkes University'} ,
{value : 'Willamette University', label : 'Willamette University'} ,
{value : 'William James College', label : 'William James College'} ,
{value : 'William Jessup University', label : 'William Jessup University'} ,
{value : 'William Jewell College', label : 'William Jewell College'} ,
{value : 'William Paterson University', label : 'William Paterson University'} ,
{value : 'William Peace University', label : 'William Peace University'} ,
{value : 'William Penn University', label : 'William Penn University'} ,
{value : 'William Woods University', label : 'William Woods University'} ,
{value : 'Williams Baptist University', label : 'Williams Baptist University'} ,
{value : 'Williams College', label : 'Williams College'} ,
{value : 'Wilmington College', label : 'Wilmington College'} ,
{value : 'Wilmington University', label : 'Wilmington University'} ,
{value : 'Wilson College', label : 'Wilson College'} ,
{value : 'Wingate University', label : 'Wingate University'} ,
{value : 'Winona State University', label : 'Winona State University'} ,
{value : 'Winston-Salem State University', label : 'Winston-Salem State University'} ,
{value : 'Winthrop University', label : 'Winthrop University'} ,
{value : 'Wisconsin Lutheran College', label : 'Wisconsin Lutheran College'} ,
{value : 'Wisconsin School of Professional Psychology', label : 'Wisconsin School of Professional Psychology'} ,
{value : 'Wittenberg University', label : 'Wittenberg University'} ,
{value : 'Wofford College', label : 'Wofford College'} ,
{value : 'WON Institute of Graduate Studies', label : 'WON Institute of Graduate Studies'} ,
{value : 'Woodbury University', label : 'Woodbury University'} ,
{value : 'Worcester Polytechnic Institute', label : 'Worcester Polytechnic Institute'} ,
{value : 'Worcester State University', label : 'Worcester State University'} ,
{value : 'Wright State University', label : 'Wright State University'} ,
{value : 'Xavier University', label : 'Xavier University'} ,
{value : 'Xavier University of Louisiana', label : 'Xavier University of Louisiana'} ,
{value : 'Yale University', label : 'Yale University'} ,
{value : 'Yeshiva University', label : 'Yeshiva University'} ,
{value : 'York College', label : 'York College'} ,
{value : 'York College of Pennsylvania', label : 'York College of Pennsylvania'} ,
{value : 'York College, City University of New York', label : 'York College, City University of New York'} ,
{value : 'Young Harris College', label : 'Young Harris College'} ,
{value : 'Youngstown State University', label : 'Youngstown State University'},
]
export default OPTIONS_SCHOOLS;