import * as firebase from 'firebase/app';
import React from "react";
import firebaseConfig from '../HeroSection/firebaseConfig';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/firebase-database'
import 'firebase/firebase-storage'
import RiseLoader from "react-spinners/RiseLoader";
import { connect } from "react-redux";
import Modal from 'react-responsive-modal';
import Select from 'react-select';
import OPTIONS_SCHOOLS from './options'

class PromoSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      updateModal:false,
      loading:false,
      uploadOpen:false,
      deleteOpen:false,
      schoolName:'',
      courseName:'',
      term:'Fall',
      year:'2020',
      type:'Free',
      document:'',
      userDocuments:{schoolName:'',courseName:'',year:''},
      documentName:'',
      display:'',
      status:false,
      docTitle:'',
      toedit:'',
      selectedOption: null,
    };

    this.uploadDone = this.uploadDone.bind(this);
  }

  

  handleUploadFile = (event) => {
    let selectedFile = event.target.files;
   
    //Check File is not Empty
    if (selectedFile.length > 0) {
        // Select the very first file from list
        let fileToLoad = selectedFile[0];
        this.setState({
          document: fileToLoad,
         
        })
    }

 
}
  loadData = () =>{
    var that = this;
    var user = firebase.auth().currentUser;
    firebase.database().ref('Users/' + user.uid).once('value').then(function(snapshot) {
      console.log(snapshot.val().Documents)
      that.setState({userDocuments:snapshot.val().Documents})
      console.log(that.state.userDocuments)
      if(snapshot.val().Documents)
      {
        console.log("i am in")
        that.setState({status:true})
      }
     
      
    });
  }
  componentDidMount() {

  
   this.loadData()
    
  }
  onOpenModal = () => {
    this.setState({ open: true });
  };
 
  onCloseModal = () => {
    this.setState({ open: false });
  };

  onOpenDeleteModal = () => {
    this.setState({ deleteOpen: true });
  };
 
  onCloseDeleteModal = () => {
    this.setState({ deleteOpen: false });
  };
  onCloseUpdateModal = () => {
    this.setState({ updateModal: false });
  };
  onCloseUploadModal = () => {
    this.setState({ uploadOpen: false });
    this.loadData()
  };
  handleFormValueChange(inputName, event) {
    let stateValue = {};
    stateValue[inputName] =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
        this.setState(stateValue)
  }
 uploadDone = () =>{
  
 }

 updateFinalDocument = () =>{
  var user = firebase.auth().currentUser;
  var x = firebase.database().ref(`Users/${user.uid}/Documents`).child(this.state.toedit);
  x.update({
    schoolName:this.state.schoolName,
    courseName:this.state.courseName,
    docTitle:this.state.docTitle,
    term:this.state.term,
    year:this.state.year
  }).then(() => {

    this.setState({updateModal:false,schoolName:'',courseName:'',docTitle:'',toedit:''})
   this.loadData()
  })
    console.log("Updated")
 }
onOpenUpdateModal = (id) =>{
  this.setState({updateModal:true})

  console.log(id)
  this.setState({courseName:id.courseName,schoolName:id.schoolName,term:id.term,year:id.year,docTitle:id.docTitle,toedit:id.documentName})
  console.log(this.state)
}
 deleteDocument = (id) => {

  

  if(window.confirm("Are you sure you want to delete this document  ? Click ok to Delete")){
    console.log("Deleting Document" + id)
    var user = firebase.auth().currentUser;
    firebase.database().ref(`Users/${user.uid}/Documents`).child(id).remove();
    firebase.storage().ref(`Documents/${user.uid}`).child(id).delete().then(() =>  window.location.reload())
  }

  
  
 }
  uploadDocument = () => {
  this.setState({loading:true})
    console.log("Uploading Document")
    var user = firebase.auth().currentUser;
    console.log(user.uid)
    console.log(this.state.term)
    console.log(this.state.year)
    var x = firebase.database().ref('Users/' + user.uid).child("Documents").push();
    console.log(x.key)
    x.set({ 
      category:'',
      schoolName: this.state.schoolName,
      term: this.state.term,
      year: this.state.year,
      courseName:this.state.courseName,
      documentName:x.key,
      downloadUrl:'',
      docTitle:this.state.docTitle,
      type:this.state.type
      
  }
      );

      const uploadTask = firebase.storage().ref("Documents/" + user.uid).child(x.key).put(this.state.document)

      uploadTask.then(uploadTaskSnapshot => {
        console.log("download")
        var urld = uploadTaskSnapshot.ref.getDownloadURL().then( url =>{
          console.log(url)
         x.update({downloadUrl:url})
          
        })
      

      })
      
      var progress;
      uploadTask.on('state_changed', (snapshot) => {
        
        progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            console.log('Upload is paused');
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            console.log('Upload is running');
            break;
        }

        if(progress === 100)
        {
          this.setState({open:false,loading:false})
          this.setState({uploadOpen:true,courseName:'',schoolName:'',docTitle:''})
          
        }
      })
     
     
    
  }
  handleChange = selectedOption => {
    this.setState({ selectedOption });
    this.setState({schoolName:selectedOption.value})
    console.log(`Option selected:`, selectedOption.value);
  };
  render() {
   
    const { selectedOption } = this.state;
    const IndicatorsContainer = ''
    var show = '';
    if(this.state.status){
       show = Object.keys(this.state.userDocuments).map((current,index) =>(
        
        <tr>
          <td>{this.state.userDocuments[current].docTitle}</td>
        <td>{this.state.userDocuments[current].schoolName}</td>
        <td>{this.state.userDocuments[current].courseName}</td>
        <td>{this.state.userDocuments[current].year}</td>
        <td><a target="_blank" href={this.state.userDocuments[current].downloadUrl}  class="btn btn-info btn-sm "><b class="ti-eye "></b></a> <a href="#" onClick={() => this.onOpenUpdateModal(this.state.userDocuments[current])} class="btn btn-primary btn-sm "><b class="ti-pencil "></b></a> <a onClick={() => this.deleteDocument(this.state.userDocuments[current].documentName)} href="#" class="btn btn-danger btn-sm "><b class="ti-trash "></b></a></td>
        </tr>
    ))
    }
    else{
       show = ''
    }
    const { open,deleteOpen } = this.state;
    return (
      <React.Fragment>
        <section className={"promo-section ptb-100 " + (this.props.removeTopMargin ? '' : 'mt-1')}>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-10">
                <div className="section-heading mb-3">
                  <span className="badge badge-primary badge-pill">
                    Welcome To Dashboard
                  </span>
                  
                </div>
              </div>

              <div className="col-lg-6 col-md-10">
                <div className="section-heading mb-3">
                  <span className="" style={{float:'right'}}>
                  <button onClick={this.onOpenModal} class="btn solid-btn btn-block btn-not-rounded "> <b class="ti-plus" style={{fontWeight:'bolder',marginTop:'-20px'}}></b> Add New Document </button>
                     
                  </span>
                  
                </div>
              </div>
            </div>

            <br/>
            <div className="row">
              <div className="col-lg-12 col-sm-12 mb-lg-0">
                <div className="single-promo single-promo-hover single-promo-1 rounded  white-bg h-100">
                  <div className="card-body">
                    <div className="pb-2">
       
                    </div>
                    <div className="pt-2 pb-3">
                      <h5 className="color-secondary"> <b class="ti-list"></b> Your Documents</h5>
                      <p className="text-muted mb-0">
                        List of Your Documents | Manage Your Documents</p><hr/>
                    </div>

                    <div>

                    <table class="table table-bordered">
    <thead>
      <tr className="color-secondary" style={{fontWeight:'bold'}}>
      <th>Document Title</th>
        <th>School</th>
        <th>Course</th>
        <th>Year</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody>

    

   {show}
     
      
    </tbody>
  </table>
                    </div>
                  </div>
                </div>
              </div>

             {/*  <div className="col-lg-4 col-sm-4 mb-lg-0">
              <div className="single-promo single-promo-hover single-promo-1 rounded  white-bg h-100">
                   <div className="card-body">
                    
                    <div className="pt-2 pb-3">
                    <h5 className="color-secondary"> <b class="ti-list"></b> Upload Documents </h5><p className="text-muted mb-0">
                        Upload Documents to dhare and earn $$$ <br/>Study,Share and Earn
                        <hr/>

                        <button onClick={this.onOpenModal} class="btn solid-btn btn-block btn-not-rounded mt-3"> <b class="ti-plus" style={{fontWeight:'bolder'}}></b> Add New Document </button>
                      </p>
                    </div>
                  </div>
                </div>
              </div> 
              */}
              
              
              
            </div>
          </div>
          <Modal styles={{backgroundColor:"blue"}} open={open} onClose={this.onCloseModal} center>
          <h5 style={{color:'white'}} >Add New Document </h5>
          
          <div className="col-lg-12 col-sm-12 mb-lg-0">
              <div className="single-promo single-promo-hover  rounded  white-bg h-100">
                   <div className="card-body">
                    
                    <div className="pt-2 pb-3">
                    <h5 className="color-secondary"> <b class="ti-list"></b> Upload Documents </h5><p className="text-muted mb-0">
                    <div className="sweet-loading" style={{marginLeft:'auto',marginRight:'auto'}}>
                        <RiseLoader
                          
                          size={15}
                          color={"#8d2ad7"}
                          margin={2}
                          loading={this.state.loading}
                        />
                      </div>
                        <b style={{color:'white'}}>WeBoro add New Docuent , WeBoro Add New Document . </b>
                       
                        <hr/>

                        <label className="pb-1">Document Title</label>
                        
                        <input type="text"  value={this.state.docTitle}
                          onChange={e => this.handleFormValueChange("docTitle", e)} placeholder="e.g Study Guide for 6th Standard"  className="form-control" />
                       

                        <label className="pb-1">School</label>
                        
                        <Select
                            value={this.state.selectedOption}
                            onChange={this.handleChange}
                            options={OPTIONS_SCHOOLS}
                            
                          />
                       
                        <label className="pb-1">Course</label>
                        <input type="text"  value={this.state.courseName}
                          onChange={e => this.handleFormValueChange("courseName", e)} placeholder="Course"  className="form-control" />
                        
                        <div class="row">
                        <div class="col-lg-6"><label className="pb-1">Term</label>
                        
                        <select
                         value={this.state.term}
                         onChange={e => this.handleFormValueChange("term", e)}
                        type="text" placeholder="Select Year"  className="form-control">
                          <option value="Fall" >Fall</option>
                          <option value="Winter">Winter</option>
                          <option value="Spring">Spring</option>
                          <option value="Summer">Summer</option>
                          =
                        </select></div>
                        <div class="col-lg-6">
                        <label className="pb-1">Year</label>
                          <select  value={this.state.year}
                          onChange={e => this.handleFormValueChange("year", e)} type="text" placeholder="Select Year"  className="form-control">
                          <option value="2020">2020</option>
                          <option value="2019">2019</option>
                          <option value="2018">2018</option>
                          <option value="2017">2017</option>
                          =
                        </select></div>
                        </div>
                        <label className="pb-1">Document Type</label>
                          <select  value={this.state.type}
                          onChange={e => this.handleFormValueChange("type", e)} type="text" placeholder="Select Year"  className="form-control">
                          <option value="Free">Free</option>
                          <option value="Premium">Premium</option>
                          
                          =
                        </select>
                        <label className="pb-1">Choose Document</label>
                        <input accept="application/pdf" type="file" onChange={(e) => this.handleUploadFile(e)} placeholder="Course"  className="form-control" />
<hr/>
                        <button onClick={() => this.uploadDocument()}  class="btn solid-btn btn-block btn-not-rounded mt-3"> <b class="ti-plus" style={{fontWeight:'bolder'}}></b> Upload & Save </button>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
        </Modal>



        <Modal styles={{backgroundColor:"blue"}} open={this.state.updateModal} onClose={this.onCloseUpdateModal} center>
          <h5 style={{color:'white'}} >Edit Document </h5>
          
          <div className="col-lg-12 col-sm-12 mb-lg-0">
              <div className="single-promo single-promo-hover single-promo-1 rounded  white-bg h-100">
                   <div className="card-body">
                    
                    <div className="pt-2 pb-3">
                    <h5 className="color-secondary"> <b class="ti-list"></b> Update Documents </h5><p className="text-muted mb-0">
                    
                        <b style={{color:'white'}}>WeBoro add New Docuent , WeBoro Add New Document . </b>
                       
                        <hr/>

                        <label className="pb-1">Document Title</label>
                        
                        <input type="text"  value={this.state.docTitle}
                          onChange={e => this.handleFormValueChange("docTitle", e)} placeholder="e.g Study Guide for 6th Standard"  className="form-control" />
                       

                        <label className="pb-1">School</label>
                        
                        <input type="text"  value={this.state.schoolName}
                          onChange={e => this.handleFormValueChange("schoolName", e)} placeholder="Enter School Name"  className="form-control" />
                       
                       
                        <label className="pb-1">Course</label>
                        <input type="text"  value={this.state.courseName}
                          onChange={e => this.handleFormValueChange("courseName", e)} placeholder="Course"  className="form-control" />
                        
                        <div class="row">
                        <div class="col-lg-6"><label className="pb-1">Term</label>
                        
                        <select
                         value={this.state.term}
                         onChange={e => this.handleFormValueChange("term", e)}
                        type="text" placeholder="Select Year"  className="form-control">
                          <option value="Fall" >Fall</option>
                          <option value="Winter">Winter</option>
                          <option value="Spring">Spring</option>
                          <option value="Summer">Summer</option>
                          =
                        </select></div>
                        <div class="col-lg-6">
                        <label className="pb-1">Year</label>
                          <select  value={this.state.year}
                          onChange={e => this.handleFormValueChange("year", e)} type="text" placeholder="Select Year"  className="form-control">
                          <option value="2020">2020</option>
                          <option value="2019">2019</option>
                          <option value="2018">2018</option>
                          <option value="2017">2017</option>
                          
                        </select></div>
                        </div>
                       
<hr/>
                        <button onClick={() => this.updateFinalDocument()}  class="btn solid-btn btn-block btn-not-rounded mt-3"> <b class="ti-plus" style={{fontWeight:'bolder'}}></b> Update & Save </button>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
        </Modal>












        <Modal styles={{backgroundColor:"blue"}} open={deleteOpen} onClose={this.onCloseDeleteModal} center>
         
         <div className="col-lg-12 col-sm-12 mb-lg-0">
              <div className="single-promo single-promo-hover single-promo-1 rounded  white-bg h-100">
                   <div className="card-body">

                   <h5 className="color-secondary"> <b class="ti-list"></b> Delete Document </h5>
                   <hr/>
                   <b >Are you Sure you want to delete this document ? Click Yes to Delete</b>
                        
                   </div>
                   <hr/>
                  <div class="row">
                   <div class="col-lg-6"><button onClick={this.onCloseDeleteModal} style={{backgroundColor:'white',color:'#6730E3'}}  class="color-secondary btn solid-btn btn-block btn-not-rounded mt-3"> <b class="ti-trash" style={{fontWeight:'bolder'}}></b> No </button>
                     </div>
                     <div class="col-lg-6"><button onClick={this.onCloseDeleteModal} class="btn solid-btn btn-block btn-not-rounded mt-3"> <b class="ti-trash" style={{fontWeight:'bolder'}}></b> Yes </button>
                     </div></div>
                    
                   </div>
                   </div>
         </Modal>
         <Modal styles={{backgroundColor:"blue"}} open={this.state.uploadOpen} onClose={this.onCloseUploadModal} center>
         
         <div className="col-lg-12 col-sm-12 mb-lg-0">
              <div className="single-promo single-promo-hover single-promo-1 rounded  white-bg h-100">
                   <div className="card-body">

                   <h5 className="color-secondary"> <b class="ti-list"></b> Document Uploading </h5>
                   <hr/>
                   <b >Document Has Been Uploaded and Saved Successfully </b>
                        
                   </div>
                   <hr/>
                  <div class="row">
                   <div class="col-lg-12"><button onClick={this.onCloseUploadModal} style={{backgroundColor:'white',color:'#6730E3'}}  class="color-secondary btn solid-btn btn-block btn-not-rounded mt-3"> <b class="ti-check-box" style={{fontWeight:'bolder'}}></b> Ok </button>
                     </div>
                     </div>
                    
                   </div>
                   </div>
         </Modal>
         

                 
                
         
        </section>
      </React.Fragment>
    );
  }
}

export default connect(state => ({
  state
}))(PromoSection);
